import { RadioItem } from 'components/RadioItem';
import { transparentize } from 'polished';
import { colors } from 'styles/colors';
import { LoaderIconStyled } from 'styles/loader';

import * as S from './style';

export type PaymentMethodsProps = {
  options: {
    title: string;
    selected: boolean;
  }[];
  isLoading: boolean;
  onClickInOption: (position: number) => void;
};

export function PaymentMethods({
  options = [],
  onClickInOption,
  isLoading,
}: PaymentMethodsProps) {
  return (
    <S.ContainerPaymentMethods>
      <S.PaymentMethodsTitle>método de pagamento</S.PaymentMethodsTitle>
      <S.RadioGroup>
        {options.map((method, key) => (
          <S.WrapperLoading>
            <RadioItem
              key={method.title}
              content={method.title}
              selected={method.selected}
              onClick={() => onClickInOption(key)}
            />

            {isLoading && method.selected && (
              <LoaderIconStyled
                width={20}
                height={20}
                color={transparentize(0.35, colors.noverde)}
              />
            )}
          </S.WrapperLoading>
        ))}
      </S.RadioGroup>
    </S.ContainerPaymentMethods>
  );
}
