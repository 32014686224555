import styled from 'styled-components';

import { colors } from './colors';
import { device } from './medias';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 14px;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  padding: 0 10px;

  @media ${device.sm} {
    padding: 0 20px;
  }

  > button {
    max-width: 330px;
  }
`;

export const AccordionHolder = styled.div`
  margin: 25px 0;
  max-width: 480px;
`;

export const ButtonsContainer = styled.div`
  color: ${colors.galaxy};
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin: 20px 0;
  align-self: flex-start;
  @media ${device.sm} {
    grid-column-gap: 40px;
  }
`;

export const ContentContainer = styled.div`
  display: inline-block;
  flex: 0 0 auto;
  transition: all 850ms ease-in-out;
  width: 100%;
  margin-right: 18%;
`;

export const ContentDescription = styled.div`
  padding: 0 10px 70px 0;

  @media ${device.lg} {
    padding: 10px 0 70px;
  }

  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
`;

export const ContentDescriptionLimited = styled.div`
  width: 90vw;

  @media${device.sm} {
    width: 470px;
  }
  > div {
    > div {
      > * {
        > * {
          padding: revert;
        }
      }
    }
  }

  > button {
    max-width: 484px;
    margin: 0 auto;
  }
`;

export const SkeletonContainer = styled.div`
  width: 100%;
`;

export const ContentDescriptionOriginalMargin = styled.div`
  > * {
    margin: revert;
    padding: revert;
  }
`;

export const FadingContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 10px);
  height: 80px;
  text-align: center;
  margin: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(255, 255, 255, 0)),
    color-stop(0.9, rgba(255, 255, 255, 100))
  );
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
`;

export const ButtonSkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 330px;
  margin: 0 auto;
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 14px;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  padding: 0 10px;

  @media ${device.sm} {
    padding: 0 20px;
  }

  > button {
    max-width: 330px;
  }
`;

export const ContentButton = styled.div`
  margin: 0 auto;

  @media${device.sm} {
    width: 500px;
  }
  > div {
    > div {
      > * {
        > * {
          padding: revert;
        }
      }
    }
  }

  > button {
    max-width: 330px;
    margin: 0 auto;
  }
`;
