import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';

import { BorrowerResponseData } from './types';

class BorrowersRemoteService extends HttpClient {
  public constructor() {
    super(Services.Loans.remote.v0);
  }

  public requestBorrower() {
    return this.instance.get<BorrowerResponseData>(`/loans/borrowers`);
  }
}

export default new BorrowersRemoteService();
