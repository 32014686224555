import { areAllDigitsTheSame } from '../helpers/are-all-digits-the-same';
import { leaveOnlyNumbers } from '../helpers/leave-only-numbers';

const getValidationDigit = (value: number): number => {
  const remainder = (value * 10) % 11;

  return remainder === 10 ? 0 : remainder;
};

const sumDigits = (values: number[]): number => {
  let counter = 1;

  return values.reduceRight((acc, item) => {
    counter += 1;

    return acc + item * counter;
  }, 0);
};

export const isCpfValid = (rawCpf: string): boolean => {
  const cpf = leaveOnlyNumbers(rawCpf);

  if (cpf.length !== 11 || areAllDigitsTheSame(cpf)) {
    return false;
  }

  const cpfAsNumbers = cpf.split('').map(Number);
  const firstNineDigits = cpfAsNumbers.slice(0, 9);
  const [firstValidationDigit, secondValidationDigit] = cpfAsNumbers.slice(9);

  const firstNineDigitsSum = sumDigits(firstNineDigits);
  const validateFirstDigit = getValidationDigit(firstNineDigitsSum);

  const firstTenDigitsSum = sumDigits([
    ...firstNineDigits,
    firstValidationDigit,
  ]);
  const validateSecondDigit = getValidationDigit(firstTenDigitsSum);

  return (
    firstValidationDigit === validateFirstDigit &&
    secondValidationDigit === validateSecondDigit
  );
};
