import { useDBTracking } from 'contexts/DBTrackingContext';
import InfoContractService from 'services/InfoContract/InfoContractService';
import { InfoContractTypes } from 'types/InfoContract/InfoContractTypes';
import { formatDate } from 'utils/formatters/format-date';
import { formatInCamelCase } from 'utils/formatters/to-formated-camalCase';
import { useStore } from 'zstore';

import {
  ModalContainer,
  ModalContent,
  ModalDataWrapper,
  ModalItem,
  Overlay,
} from './style';

type Props = {
  open: boolean;
  toggleModal: () => void;
  handleSelectedDate: (date: string) => void;
  resetSelectedInstallments: () => void;
  setIsLoading: (boolean) => void;
  dueDates: string[];
};

export const ModalInstallmentDateSelectorDashboard = ({
  open,
  toggleModal,
  handleSelectedDate,
  resetSelectedInstallments,
  setIsLoading,
  dueDates,
}: Props) => {
  const { setContractInfo } = useStore();
  const { setTrackingEvent } = useDBTracking();

  const handleSelectDate = async (date: string) => {
    resetSelectedInstallments();
    handleSelectedDate('');
    toggleModal();

    try {
      setIsLoading(true);

      const { data } = await InfoContractService.getInfoContract({
        paymentDate: date,
      });

      const newInstallments: InfoContractTypes = formatInCamelCase(data);

      // eslint-disable-next-line prefer-destructuring
      newInstallments.availableForPayment.paymentDate = dueDates[0];

      setContractInfo(newInstallments);

      sendTrackingEventDateSelected(date);

      handleSelectedDate(date);
    } catch (err) {
      console.warn({
        title: 'Erro ao carregar installments',
        err,
      });
    } finally {
      setIsLoading(false);
    }
  };

  function sendTrackingEventDateSelected(date: string) {
    setTrackingEvent({
      category: 'dashboard-installments',
      action: 'due_date_selected',
      details: {
        due_date: date,
      },
      loanUuid: true,
      deviceId: true,
    });
  }

  return (
    <ModalDataWrapper open={open}>
      <ModalContainer>
        <Overlay onClick={toggleModal} />
        <ModalContent open={open}>
          {dueDates?.map((date) => (
            <ModalItem key={date} onClick={() => handleSelectDate(date)}>
              {formatDate(date, {
                format: 'DD MMM YYYY - EEEE',
              })}
            </ModalItem>
          ))}
        </ModalContent>
        <Overlay onClick={toggleModal} />
      </ModalContainer>
    </ModalDataWrapper>
  );
};
