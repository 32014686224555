import styled, { css } from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';
import { device } from 'styles/medias';

import { ContainerProps } from '.';

export const Container = styled.footer<ContainerProps>`
  ${({ background, position, width, borderRadius, borderTop }) => css`
    min-height: 87px;
    width: ${width || '100%'};

    background: ${colorsV2[background || 'neutral-ultra-dark']};

    @media ${device.sm} {
      border-top: ${borderTop && borderTop};
      border-radius: ${borderRadius && borderRadius};
    }

    position: ${position || 'absolute'};

    bottom: 0;
  `}
`;

interface ContainerButtonProps {
  hasOneChild?: boolean;
}

export const ContainerButton = styled.div<ContainerButtonProps>`
  ${({ hasOneChild }) => css`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 24px;

    margin: 0 auto;
    padding: 16px;

    max-width: ${hasOneChild ? '384px' : '768px'};
    width: 100%;

    @media ${device.sm} {
      flex-direction: row;
    }
  `}
`;
