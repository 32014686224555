import { useMemo } from 'react';

import { useRouter } from './useRouter';

type StateRoutesProps = {
  targetRoute: string;
  origin?: string;
};

export function useLocationState() {
  const { location, history } = useRouter();

  const cleanAndReturn = ({ targetRoute, origin }: StateRoutesProps) => {
    window.history.replaceState({}, '');
    history.push(targetRoute, { origin });
  };

  const verifyLocationState = (route: string) => {
    if (!location.state) {
      history.push(route);
    }
  };

  return useMemo(() => {
    return {
      cleanAndReturn,
      verifyLocationState,
    };
  }, [location.state]);
}
