import { areAllDigitsTheSame } from '../helpers/are-all-digits-the-same';
import { leaveOnlyNumbers } from '../helpers/leave-only-numbers';

// source: http://www.ddi-ddd.com.br/Codigos-Telefone-Brasil/
const isDDDValid = (ddd: string): boolean =>
  ![
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '20',
    '23',
    '25',
    '26',
    '29',
    '30',
    '36',
    '39',
    '40',
    '50',
    '52',
    '56',
    '57',
    '58',
    '59',
    '60',
    '70',
    '72',
    '76',
    '78',
    '80',
    '90',
  ].includes(ddd);

const splitPhoneNumber = (phone: string): [string, string] => {
  const ddd = phone.slice(0, 2);
  const phoneWithoutDDD = phone.slice(2);

  return [ddd, phoneWithoutDDD];
};

const isPhoneNumberValid = (
  rawPhoneNumber: string,
  length: number,
): boolean => {
  const phoneNumber = leaveOnlyNumbers(rawPhoneNumber);
  if (phoneNumber.length !== length) {
    return false;
  }

  const [ddd, phoneWithoutDDD] = splitPhoneNumber(phoneNumber);
  if (!isDDDValid(ddd) || areAllDigitsTheSame(phoneWithoutDDD)) {
    return false;
  }

  return true;
};

export const isMobilePhoneNumberValid = (rawPhoneNumber: string): boolean => {
  return isPhoneNumberValid(rawPhoneNumber, 11);
};
