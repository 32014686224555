import { WhiteLogoNoverdePorDotz } from 'assets/img/WhiteLogoNoverdePorDotz';

import {
  LogoContainer,
  SideBannerContainer,
  TextContainer,
  Title,
} from './style';

interface SideBannerProps {
  title: React.ReactNode;
  banner: string;
  // dotzTheme?: boolean;
}

export function SideBanner(props: SideBannerProps) {
  const { title, banner } = props;

  return (
    <SideBannerContainer bgImage={banner}>
      <TextContainer>
        <Title>{title}</Title>
      </TextContainer>
      <LogoContainer>
        <WhiteLogoNoverdePorDotz />
      </LogoContainer>
    </SideBannerContainer>
  );
}
