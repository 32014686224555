import { allowAge } from './allow-age';
import { createFormValidations } from './create-form-validations';
import { isCpfValid } from './is-cpf-valid';
import { isEmailValid } from './is-email-valid';
import { isInvalidDate } from './is-invalid-date';
import { isMobilePhoneNumberValid } from './is-phone-number-valid';

export const required = 'faltou preencher esse aqui';

export const formValidations = createFormValidations<{
  cpf: string;
  cellphone: string;
  email: string;
  name: string;
  password: string;
  employmentStatus: string;
  birthDate: string;
  income: string;
  createPassword: string;
  confirmPassword: string;
  requestedPeriod: string;
  requestedPayday: string;
  zipCode: string;
  streetName: string;
  streetNumber: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  bank: string;
  agencyDigit: string;
  accountType: string;
  agency: string;
  account: string;
  accountDigit: string;
  pin: string;
  numeroCartao: string;
  titularCartao: string;
  vencimentoCartao: string;
  ccvCartao: number;
  cep: string;
  endereco: string;
  numero: string;
  complemento?: string;
  bairro: string;
  cidade: string;
  estado: string;
  uuid: string;
  documentNumber: string;
  issuer: string;
  issueDate: string;
}>(() => ({
  documentNumber: {
    required,
  },
  issuer: {
    required,
  },
  issueDate: {
    required,
    validate: (value: string) => {
      if (value.length < 10) {
        return 'preencha todos os dígitos';
      }

      if (isInvalidDate(value)) {
        return 'preencha com uma data válida';
      }

      return true;
    },
  },
  uuid: {
    required: 'Faltou preencher a chave aleatória',
    validate: (val: string) => {
      const regexExp =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      return regexExp.test(val) || 'A chave aleatória está incorreta';
    },
  },
  cpf: {
    required: 'O CPF precisa ter 11 números.',
    validate: (cpf: string) => isCpfValid(cpf) || 'CPF inválido.',
  },
  cellphone: {
    required: 'Seu celular parece estar faltando algum número.',
    validate: (cellphone: string) =>
      isMobilePhoneNumberValid(cellphone) ||
      'telefone inválido, verifique o número e tente novamente',
  },
  email: {
    required: 'Este email não parece estar certo.',
    validate: (email: string) =>
      isEmailValid(email) || 'email inválido, verifique  e tente novamente',
  },
  name: {
    required: 'Precisa ter nome e sobrenome.',
    minLength: {
      message: 'Deve ter ao menos 3 characters',
      value: 3,
    },
    validate: (value = '') => {
      const matched = value.trim().match(/ /g) ?? [];

      return matched.length || 'Deve ter ao menos dois nomes';
    },
  },
  password: {
    required: 'Sua senha deve ter pelo menos 8 caracteres.',
    minLength: {
      message: 'Sua senha deve ter 3 characters',
      value: 3,
    },
  },
  employmentStatus: { required: true },
  birthDate: {
    required: 'Digite a data no formato dd/mm/aaaa.',
    validate: (value: string) => {
      if (value.length < 10) {
        return 'formato inválido, preencha todos os dígitos';
      }

      if (!allowAge({ minAge: 18, maxAge: 75 })(value)) {
        return 'precisamos saber sua data de nascimento';
      }

      return true;
    },
  },
  income: {
    required,
    validate: (value: string) => {
      if (value === 'R$ 0,00') {
        return required;
      }

      return true;
    },
  },
  confirmPassword: {
    required: 'Preencha com a mesma senha de cima.',
  },
  createPassword: {
    required: 'Sua senha deve ter pelo menos 8 caracteres.',
    minLength: {
      value: 8,
      message: 'Pelo menos 8 caracteres contendo letras e números',
    },
    validate: (text: string) => {
      if (!text.match(/\d/)) {
        return 'Sua senha deve ter pelo menos 1 número.';
      }

      if (!text.match(/[A-Za-z]/)) {
        return 'Sua senha deve ter pelo menos 1 letra.';
      }

      return true;
    },
  },
  requestedPeriod: {
    required: true,
  },
  requestedPayday: {
    required: true,
  },
  zipCode: {
    required,
    minLength: {
      value: 9,
      message: 'Esse CEP parece estar errado.',
    },
    validate: (value: string) => {
      if (value.length < 9) {
        return 'Esse CEP parece estar errado.';
      }

      return true;
    },
  },
  streetName: {
    required,
    maxLength: {
      value: 100,
      message: 'Esse endereço parece estar errado.',
    },
  },
  streetNumber: {
    required,
    maxLength: {
      value: 10,
      message: 'Número inválido',
    },
  },
  complement: {
    maxLength: {
      value: 50,
      message: 'o complemento deve conter no máximo 50 caracteres',
    },
  },
  neighborhood: {
    required,
    maxLength: {
      value: 50,
      message: 'Esse bairro parece estar errado',
    },
  },
  city: {
    required,
    maxLength: {
      value: 50,
      message: 'Cidade inválida',
    },
  },
  state: { required: true },
  bank: { required: true },
  accountType: { required: true },
  agency: {
    required: 'Agência inválida.',
    maxLength: {
      value: 4,
      message: 'Agência inválida.',
    },
  },
  account: {
    required: 'Conta inválida.',
    maxLength: {
      value: 11,
      message: 'Conta inválida.',
    },
  },
  agencyDigit: {
    maxLength: {
      value: 1,
      message: 'limite de 1 digito',
    },
  },
  agencyWithDigit: {
    maxLength: {
      value: 5,
      message: 'limite de 5 digito',
    },
  },
  accountDigit: {
    required: 'Campo obrigatório.',
    maxLength: {
      value: 1,
      message: 'limite de 1 digito',
    },
  },
  pin: {
    required: true,
    min: 4,
  },
  numeroCartao: {
    required,
    validate: (value: string) => {
      const message = 'Número inválido';
      if (!value || value.length < 15) {
        return message;
      }
      let s1 = 0;
      let s2 = 0;
      const reverse = String(value).replace(/\s/g, '').split('').reverse();
      reverse.forEach((d, i) => {
        const digit = parseInt(d, 10);
        if (i % 2 === 0) {
          s1 += digit;
        } else {
          s2 += 2 * digit;
          if (digit >= 5) {
            s2 -= 9;
          }
        }
      });
      if ((s1 + s2) % 10 === 0) {
        return true;
      }
      return message;
    },
  },
  vencimentoCartao: {
    required,
    validate: (value: string) => {
      const message = 'Data inválida';
      const splitedValue = value.split('/');
      const month = Number(splitedValue[0]);
      const year = Number(splitedValue[1]);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      if (month > 12 || month < 1) return message;
      if (year < currentYear) return message;
      if (year === currentYear) {
        if (month < currentMonth) {
          return message;
        }
      }
      return true;
    },
  },
  titularCartao: {
    required: 'informe o nome que consta em seu cartão',
    validate: (text: string) => {
      if (!text.match(/[A-Za-z]/)) {
        return 'Seu nome não pode ter números';
      }
      return true;
    },
  },
  ccvCartao: {
    required,
    maxLength: {
      message: 'o CVV deve conter 3 números',
      value: 3,
    },
    validate: (value: string) => {
      if (value.length < 3) {
        return 'o CCV deve conter 3 dígitos';
      }
      return true;
    },
  },
  bairro: { required },
  cep: { required },
  cidade: { required },
  endereco: { required },
  estado: { required },
  numero: { required },
}));
