import { useModal } from 'hooks/useModal';

import {
  CardName,
  CardNumber,
  Container,
  IconEdit,
  IconLeft,
  TextContainer,
  MenuModal,
} from './style';

interface WalletCardDashboardProps {
  title: string;
  description: string;
  handleDetails: () => void;
  handleDelete: () => void;
}

export const WalletCardDashboard = ({
  description,
  title,
  handleDetails,
  handleDelete,
}: WalletCardDashboardProps) => {
  const { openModal, closeModal } = useModal();

  return (
    <Container
      onClick={() =>
        openModal({
          title: 'Selecione a ação desejada',
          content: (
            <MenuModal>
              <span className="detail" onClick={handleDetails}>
                Ver detalhes
              </span>
              <span className="delete" onClick={handleDelete}>
                Excluir cartão
              </span>
              <span onClick={closeModal}>Cancelar</span>
            </MenuModal>
          ),
          size: 320,
        })
      }
    >
      <IconLeft />
      <TextContainer>
        <CardName>{title}</CardName>
        <CardNumber>{description}</CardNumber>
      </TextContainer>
      <IconEdit />
    </Container>
  );
};
