import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import { InstallmentsListDashboard } from './components/InstallmentsListDashboard';

export interface PayInstallmentsProps {
  modalSelection: boolean;
  title: string;
  paymentType: string;
  showAdvanceInstallments?: boolean;
}

export const PayInstallments = () => {
  return (
    <DashBoardTemplate headerTitle="Selecionar parcelas">
      <InstallmentsListDashboard />
    </DashBoardTemplate>
  );
};
