import Banner from 'assets/img/SideBanner/man-phone.jpg';
import { SideBanner } from 'components/SideBanner';
import useWindowDimensions from 'hooks/useWindowDimensions';

export function UpdateDataBanner() {
  const { hidelg } = useWindowDimensions();

  return hidelg ? (
    <SideBanner
      title={
        <>
          Mantenha <b>seus dados atualizados</b>
        </>
      }
      banner={Banner}
    />
  ) : null;
}
