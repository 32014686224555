import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import PaymentMethodsDashboard from './components/PaymentMethodsDashboard';

export function PagePaymentMethodsDashboard() {
  return (
    <DashBoardTemplate headerTitle="Meio de pagamento">
      <PaymentMethodsDashboard />
    </DashBoardTemplate>
  );
}
