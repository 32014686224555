import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PayIcon } from 'components/Icons/PayIcon';
import { useLocationState } from 'hooks/useLocationState';
import { useStore } from 'zstore';

import { ButtonStyled, CreditCardPaymentContainer, TextStyled } from './style';

export function CreditCardPayment() {
  const { setContractInfo, setLoansData } = useStore();
  const history = useHistory();
  const { cleanAndReturn } = useLocationState();

  useEffect(() => {
    setLoansData({});
    setContractInfo({});
  }, []);

  return (
    <CreditCardPaymentContainer>
      <PayIcon />
      <TextStyled>
        OBA!
        <br />
        <br />
        Pagamento efetuado com sucesso!
      </TextStyled>
      <ButtonStyled
        onClick={() =>
          cleanAndReturn({
            targetRoute: '/dashboard',
            origin: history.location.pathname,
          })
        }
      >
        entendi
      </ButtonStyled>
    </CreditCardPaymentContainer>
  );
}
