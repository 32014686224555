export const storageKeys = {
  accessToken: '@noverde-app-web/access_token',
  borrowerId: '@noverde-app-web/borrower_id',
  loanUid: '@noverde-app-web/loan_uid',
  loanFgtsUid: '@noverde-app-web/loan_fgts_uid',
  refreshToken: '@noverde-app-web/refresh_token',
  expiresAt: '@noverde-app-web/expires_at',
  userUid: '@noverde-app-web/user_uid',
  borrowerData: '@noverde-app-web/borrowerData',
  loansData: '@noverde-app-web/loansData',
  dueDateData: '@noverde-app-web/dueDateData',
  installmentsWithProcessing: '@noverde-app-web/installmentsWithProcessing',
  lifeProofTip: '@noverde-app-web/lifeProofTip',
  lifeProofPending: '@noverde-app-web/lifeProofPending',
  loanId: '@noverde-app-web/loanId',
  utmDetails: '@noverde-app-web/utmDetails',
  welcomeModal: 'persist@noverde-app-web/welcomeModal',
  isDotzTheme: '@noverde-app-web/is_dotz_theme',
  hasFgts: '@noverde-app-web/has_fgts',
  pendingBankAccount: '@noverde-app-web/pendingBankAccount',
  hideDownloadButton: '@noverde-app-web/hideDownloadButton',
  multitheme: '@noverde-app-web/multitheme',
  requestedSuperSimOffer: '@noverde-app-web/multitheme/requestedSuperSimOffer',
  partnerOfferUid: '@noverde-app-web/partnerOfferUid',
};
