import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { AcceptedOfferTypes } from 'types/Renegotiation/Renegotiation';

class RenegotiationService extends HttpClient {
  public constructor() {
    super(Services.CollectionOffers);
  }

  public AcceptOffer = (body: AcceptedOfferTypes) => {
    const requestBody = {
      type: body.type,
      period: body.period,
      debt_amount: body.debt_amount,
    };

    return this.instance.post(
      `collection-offers/${body.uuidOffer}/accepted-offers`,
      requestBody,
    );
  };
}

export default new RenegotiationService();
