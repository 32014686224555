import Skeleton from 'react-loading-skeleton';

import { Field } from 'components/Field';

import { FieldWithLabelStyled, LabelStyled } from './style';

type FieldWithLabelProps = {
  name: string;
  label: string;
  helperText?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  pattern?: string;
  validation?: any;
  type?: string;
  marginLeft?: string | number;
  isLoading?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function FieldWithLabel(props: FieldWithLabelProps) {
  const {
    name,
    label,
    helperText,
    error,
    disabled,
    pattern,
    validation,
    type,
    isLoading = false,
    ...rest
  } = props;

  if (isLoading) return <Skeleton height={56} />;

  return (
    <FieldWithLabelStyled color={rest.color}>
      <Field
        id={name}
        name={name}
        className={error ? 'error' : ''}
        disabled={disabled}
        placeholder=" "
        pattern={pattern}
        type={type}
        validation={validation}
        helperText={helperText}
        {...rest}
      />
      <LabelStyled htmlFor={name}>{label}</LabelStyled>
    </FieldWithLabelStyled>
  );
}

const defaultProps = {
  helperText: null,
  error: false,
  disabled: false,
  pattern: null,
  type: null,
  validation: null,
  marginLeft: 0,
};

FieldWithLabel.defaultProps = defaultProps;
