/* eslint-disable array-callback-return */
import { ChangeEvent } from 'react';

import imgUserInstallmentLate from 'assets/img/user-installment-late.svg';
import Checkbox from 'components/Checkbox';
import { useModal } from 'hooks/useModal';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { formatDate } from 'utils/formatters/format-date';
import { toBrl } from 'utils/formatters/to-brl';
import { isEmpty } from 'utils/helpers/is-empty';

import { AllInstallmentsTypes } from '../InstallmentsListDashboard';

import {
  Box,
  BoxDescription,
  BoxGrid,
  ModalButton,
  ModalContainer,
  ModalDescription,
  ModalImage,
  ModalTitle,
  QuantityBox,
  Title,
  Valor,
  Vencimento,
} from './style';

const colors = getThemeColors();

const boxColor = {
  atrasada: colors.royalOrange,
  proxima: colors.galaxy,
  adiantar: colors.greyborders,
};

interface InstallmentDashboardProps {
  toggleCheckbox: (
    e: ChangeEvent<HTMLInputElement>,
    item: AllInstallmentsTypes,
  ) => void;
  currentInstallments: AllInstallmentsTypes[];
  pendingInstallments: AllInstallmentsTypes[];
}

export const InstallmentDashboard = ({
  toggleCheckbox,
  currentInstallments,
  pendingInstallments,
}: InstallmentDashboardProps) => {
  const { closeModal, openModal } = useModal();

  function openModalSelectInstallmentLate() {
    const hasPending = pendingInstallments.filter(
      (_installment) => !_installment.checked,
    );

    if (!isEmpty(pendingInstallments) && !isEmpty(hasPending)) {
      modalInstallmentInformative();
    }
  }

  function modalInstallmentInformative() {
    openModal({
      title: '',
      size: 295,
      content: (
        <ModalContainer>
          <ModalImage src={imgUserInstallmentLate} />
          <ModalTitle>
            Pague suas parcelas em atraso antes de adiantar uma parcela à vencer
          </ModalTitle>
          <ModalDescription>
            Uma opção para pagar antecipado suas parcelas à vencer é
            seleciona-las junto com as parcelas em atraso para realizar o
            pagamento.
          </ModalDescription>
          <ModalButton onClick={closeModal}>Entendi</ModalButton>
        </ModalContainer>
      ),
    });
  }
  return (
    <>
      {!isEmpty(pendingInstallments) && (
        // PARCELAS ATRASADAS
        <Box key="parcelas-pendentes">
          <Title>parcelas em atraso</Title>
          {pendingInstallments.map((item) => (
            <BoxGrid
              key={item.uuid}
              htmlFor={String(item.uuid)}
              disable={item.disabled}
            >
              <BoxDescription>
                <QuantityBox color={boxColor.atrasada}>
                  {item.number}
                </QuantityBox>
                <div>
                  <Vencimento>
                    venceu em{' '}
                    {formatDate(item?.dueDate, { format: 'DD/MM/YYYY' })}
                  </Vencimento>
                  <Valor>{toBrl(item?.presentValue)}</Valor>
                </div>
              </BoxDescription>
              <Checkbox
                color={colors.noverde}
                id={String(item.uuid)}
                onChange={(e) => {
                  toggleCheckbox(e, item);
                }}
                disabled={item.disabled}
                checked={item.checked}
              />
            </BoxGrid>
          ))}
        </Box>
      )}

      {!isEmpty(currentInstallments) && (
        <Box key="parcelas correntes">
          <Title>próxima parcela</Title>
          {currentInstallments.map((item) => (
            <BoxGrid
              htmlFor={item.uuid}
              disable={item.disabled}
              key={item.uuid}
            >
              <BoxDescription>
                <QuantityBox color={boxColor.proxima}>
                  {item.number}
                </QuantityBox>
                <div>
                  <Vencimento>
                    vence em&nbsp;
                    {formatDate(item?.dueDate, {
                      format: 'DD/MM/YYYY',
                    })}
                  </Vencimento>
                  <Valor>{toBrl(item?.presentValue)}</Valor>
                </div>
              </BoxDescription>
              <Checkbox
                color={colors.noverde}
                id={String(item?.uuid)}
                onChange={(e) => {
                  if (item.disabled) {
                    openModalSelectInstallmentLate();
                  } else {
                    toggleCheckbox(e, item);
                  }
                }}
                checked={item.checked}
              />
            </BoxGrid>
          ))}
        </Box>
      )}
    </>
  );
};
