import { ProfileDashboard } from 'pages/Dashboard/components/ProfileDashboard';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

export function PageProfileDashboard() {
  return (
    <DashBoardTemplate headerTitle="Meu perfil">
      <ProfileDashboard />
    </DashBoardTemplate>
  );
}
