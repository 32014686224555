import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: center;

  cursor: pointer;

  :hover {
    color: ${colors.noverde};
  }
  > svg {
    width: 20px;
    height: 20px;
  }
`;

export const RadioLabel = styled.p`
  color: ${colors.galaxy};
  margin: 0 10px;
`;
