import Banner from 'assets/img/SideBanner/woman-account.jpg';
import { SideBanner } from 'components/SideBanner';
import useWindowDimensions from 'hooks/useWindowDimensions';

export function ResetPasswordBanner() {
  const { hidelg } = useWindowDimensions();

  return hidelg ? (
    <SideBanner
      title={
        <>
          Recupere
          <br /> <b>sua conta</b>
        </>
      }
      banner={Banner}
    />
  ) : null;
}
