export const colorsV2 = {
  'brand-primary': '#07D15B',
  'brand-secondary': '#0C0F25',
  'brand-tertiary': '#9DDE99',

  'neutral-ultra-dark': '#0C0F25',
  'neutral-dark': '#515151',
  'neutral-medium': '#878787',
  'neutral-medium-2': '#E2E2E2',
  'neutral-light': '#f5f5f5',
  'neutral-ultra-light': '#ffffff',

  'action-conversion-static': '#DC456D',
  'action-conversion-hover': '#9D3551',
  'action-conversion-disabled': '#D6D6D6',

  'action-navigation-static': '#07D15B',
  'action-navigation-hover': '#0D9242',
  'action-navigation-disabled': '#D6D6D6',

  'action-contrast-static': '#ffffff',
  'action-contrast-hover': '#00FFB2',
  'action-contrast-disabled': '#878787',

  'status-success': '#23B218',
  'status-alert': '#FF9700',
  'status-error': '#DE1616',

  'support-cerulean': '#7791A3',
  'support-light-purple': '#B4A0E3',
  'support-red': '#C63B3B',
  'support-yellow': '#E7C800',
  'support-soft-blue': '#9CCAD4',
  'support-coral': '#FF7754',
  'support-deep-blue': '#3E296D',
  'support-baker-pink': '#FFA4AA',
  'support-gold': '#D1A600',

  'bg-success': '#F4FBF3',
  'bg-error': '#FDF3F3',
  'bg-alert': '#FFFAF2',
  'bg-informative': '#FAFCFD',
};
