import styled from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';
import { device } from 'styles/medias';

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 56px;
  padding: 16px;
  background-color: ${colorsV2['neutral-ultra-light']};

  position: sticky;
  position: top;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    width: 32px;
    height: 32px;
  }

  svg {
    cursor: pointer;
  }

  img {
    width: 100%;
    max-width: 100px;

    @media ${device.sm} {
      max-width: 160px;
    }
  }

  @media ${device.sm} {
    max-height: 84px;
    padding: 26px 16px;
  }
`;
