import { FiChevronLeft, FiX } from 'react-icons/fi';

import { BlackLogoNoverdePorDotz } from 'assets/img/BlackLogoNoverdePorDotz';
import { useRouter } from 'hooks/useRouter';
import useWindowDimensions from 'hooks/useWindowDimensions';

import * as S from './styles';

type HeaderNavigationProps = {
  goBack?: React.MouseEventHandler<SVGElement>;
};

export const HeaderNavigation = ({ goBack }: HeaderNavigationProps) => {
  const { hidesm } = useWindowDimensions();
  const { history } = useRouter();

  function onClickClose() {
    history.push('/');
  }

  return (
    <S.MainContainer>
      {goBack ? <FiChevronLeft size={32} onClick={goBack} /> : <span />}
      <BlackLogoNoverdePorDotz />
      <FiX size={hidesm ? 32 : 24} onClick={onClickClose} />
    </S.MainContainer>
  );
};
