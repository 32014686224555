import { Button as BaseButton } from 'components/Button';
import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

const colors = getThemeColors();

type InstallmentNumberProps = {
  color: string;
  textColor?: string;
};

export const Box = styled.div`
  border-radius: 8px;
  height: fit-content;
  width: 100%;
  padding: 2rem;

  @media ${device.sm} {
    box-shadow: 0 5px 0.5rem #e8e8e8;
    width: 35rem;
  }
`;

export const LineItem = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.greyborders};
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  width: 100%;
`;

export const LineItemTitle = styled.p`
  color: ${colors.text};
  font-size: 14px;
  font-weight: 400;
`;

export const LineItemValue = styled.span`
  color: ${colors.galaxy};
  font-size: 14px;
  font-weight: 600;
`;

export const LineItemPaymentType = styled.span`
  color: ${colors.noverde};
  font-size: 14px;
  font-weight: 600;
`;

export const InstallmentBoxTitle = styled.p`
  color: ${colors.galaxy};
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 600;
`;

export const InstallmentList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;
  border-bottom: 1px solid ${colors.greyborders};
`;

export const InstallmentNumber = styled.div<Partial<InstallmentNumberProps>>`
  ${({ color, textColor }) => css`
    background-color: ${color ?? colors.greyborders};
    border-radius: 4px;
    color: ${textColor ?? colors.white};
    display: grid;
    height: 45px;
    place-items: center;
    width: 45px;
  `}
`;

export const InstallmentBox = styled.div`
  align-items: center;
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
`;

export const Vencimento = styled.p`
  color: ${colors.text};
  font-size: 14px;
  font-weight: 400;
`;

export const Valor = styled.p`
  color: ${colors.galaxy};
  font-size: 14px;
  font-weight: 600;
`;

export const InformativeMessage = styled.p`
  font-size: 14px;
  margin: 1rem 0;
  text-align: center;
  width: 100%;
`;

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 10rem;
`;

export const ModalButton = styled(BaseButton)`
  margin-top: 1rem;
`;

export const ModalDescriptionContainer = styled.div`
  display: grid;
  gap: 1rem;
`;

export const ModalDescription = styled.p`
  color: ${colors.text};
  font-size: 0.875rem;
  font-weight: 400;
  text-align: justify;
`;
