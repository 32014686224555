export const colors = {
  noverde: '#0CD15B',
  safearea: '#27AE60',
  safeareaError: '#EE7171',
  acqua: '#00FFB2',
  galaxy: '#0C0F25',
  text: '#646E7D',
  errors: '#FB0000',
  sunset: '#FF9900',
  auxtext: '#666666',
  selfie: '#2e2e2e',
  placeholders: '#AAABB6',
  greyborders: '#E2E2E2',
  disable: '#bababa',
  backgrounds: '#F9FAFD',
  white: '#FFFFFF',
  black: '#000000',
  blue1: '#2F80ED',
  info: '#2196f3',
  lpsocial: '#1F256D',
  royalOrange: '#f2994a',
  neutralLight: '#F5F5F5',
  exchangeEarn: '#B4A0E3',
  exchangeEarnTitle: '#A191C8',
  bakerMillerPink: '#FFA4AA',
  noverdeLight: '#9dde99',
  customBrandLight: '#FF9200',
  greyCustom: '#616161',
  gradientOne: '#07d15b -17.46%, #009262 46.34%, #21755f 76.17%',
};

export const colorsDotz = {
  light: '#FF9200',
  regular: '#ED6B1C',
};
