import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

const colors = getThemeColors();

export const BoxShadow = styled.div`
  align-items: center;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem;
  position: relative;
  @media ${device.sm} {
    box-shadow: 0 5px 0.5rem #e8e8e8;
    border-radius: 8px;
    grid-template-columns: repeat(1, 1fr);
    padding: 2rem;
  }
`;

export const Title = styled.p`
  color: ${colors.galaxy};
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 12px;
  text-align: center;
  @media ${device.sm} {
    margin-top: 0;
  }
`;

export const ScrollList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  margin-bottom: 12px;
  width: 100%;
  padding: 0 1rem;
  position: relative;
  @media ${device.sm} {
    min-width: 40rem;
    width: fit-content;
  }
`;

export const ValorTotal = styled.p`
  color: ${colors.galaxy};
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
  & > span {
    color: ${colors.noverde};
  }
`;
