import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingPage } from 'components/LoadingPage';
import { SideBannerIdentity } from 'components/SideBannerIdentity';
import { useDBTracking } from 'contexts/DBTrackingContext';
import { useNavigation } from 'contexts/NavigationContext';
import { SideBySideLayout } from 'styles/grids';
import { Liveness as LivenessSDK } from 'utils/liveness';
import { useStore } from 'zstore';

export const Liveness = () => {
  const { livenessStatus, setToast, setLivenessStatus } = useStore();
  const { goNextScreen } = useNavigation();
  const history = useHistory();
  const { setTrackingEvent } = useDBTracking();

  const [isLoading, setIsLoading] = useState(false);
  const attempts = useRef<number>(0);

  const maxAttempts = 3;
  const isSDKInitialized = !!LivenessSDK.getSDKStatus();

  const analysisResponseTracking = (status) => {
    setTrackingEvent({
      category: 'liveness',
      action: 'analysis_response',
      details: {
        status,
      },
      loanUuid: true,
      deviceId: true,
    });
  };

  const sendToDisclaimer = () => {
    history.push('/liveness-disclaimer');
  };

  const allowRetryTreatment = () => {
    attempts.current += 1;

    if (attempts.current < maxAttempts) {
      LivenessSDK.onLivenessCheckPressed();
    } else {
      setToast({
        toastType: 'alert',
        toastMessage:
          'Não foi possível validar sua foto. Por favor, tente de novo mais tarde.',
      });

      history.push('/dashboard');
    }
  };

  const contextSwitchTreatment = () => {
    setToast({
      toastType: 'info',
      toastMessage: 'Por segurança. Pedimos que inicie a jornada novamente.',
    });

    sendToDisclaimer();
  };

  const lockedOutTreatment = () => {
    setToast({
      toastType: 'info',
      toastMessage:
        'Muitas tentativas, aguarde para tentar novamente ou use outro dispositivo.',
    });

    sendToDisclaimer();
  };

  const internalErrorTreatment = () => {
    setToast({
      toastType: 'error',
      toastMessage:
        'Tivemos um probleminha. Tente de novo em alguns minutos ou fale com a gente.',
    });

    sendToDisclaimer();
  };

  useEffect(() => {
    if (isSDKInitialized) {
      LivenessSDK.onLivenessCheckPressed();
    } else {
      sendToDisclaimer();
    }
  }, []);

  useEffect(() => {
    if (!livenessStatus) return;

    switch (livenessStatus) {
      case 'sdk_completed':
        setIsLoading(true);
        break;
      case 'succeeded':
        analysisResponseTracking('succeeded');
        goNextScreen();
        break;
      case 'allow_retry':
        analysisResponseTracking('allow_retry');
        allowRetryTreatment();
        break;
      case 'certiface_error':
        internalErrorTreatment();
        break;
      case 'failed':
        analysisResponseTracking('failed');
        internalErrorTreatment();
        break;
      case 'CameraNotRunning':
        sendToDisclaimer();
        break;
      case 'CameraNotEnabled':
        sendToDisclaimer();
        break;
      case 'UserCancelled':
        sendToDisclaimer();
        break;
      case 'ContextSwitch':
        contextSwitchTreatment();
        break;
      case 'LockedOut':
        lockedOutTreatment();
        break;
      default:
        internalErrorTreatment();
        break;
    }

    setLivenessStatus(null);
  }, [livenessStatus]);

  if (isLoading)
    return (
      <SideBySideLayout>
        <SideBannerIdentity />

        <LoadingPage
          title="Aguarde mais alguns estantes"
          text="Estamos validando seu rosto :)"
        />
      </SideBySideLayout>
    );

  return null;
};
