import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import { ContractsDashboard } from './components/ContractsDashboard';

export function Contracts() {
  return (
    <DashBoardTemplate headerTitle="Contratos">
      <ContractsDashboard />
    </DashBoardTemplate>
  );
}
