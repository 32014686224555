import React, { createContext, useContext, useState } from 'react';

import { useRouter } from 'hooks/useRouter';
import AuthenticationService from 'services/Authorizer/AuthenticationService';
import { cleanLocalStorage } from 'store/clean';
import { storageKeys } from 'store/storage-keys';
import { SessionDataType } from 'types/Authorizer/SessionType';
import { storage } from 'utils/storage';

export interface CredentialsTypes {
  username: string;
  password: string;
  deviceId: string;
}

interface AuthContextData {
  sessionData: Pick<SessionDataType, 'borrowerId'>;
  requestLogin(credentials: CredentialsTypes): Promise<void>;
  requestLogout: () => void;
}

interface AuthProviderTypes {
  children?: React.ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

export const AuthProvider = ({ children }: AuthProviderTypes) => {
  const { history } = useRouter();

  const [sessionData, setSessionData] = useState<
    Pick<SessionDataType, 'borrowerId'>
  >({
    borrowerId: '',
  });

  async function requestLogin({
    username,
    password,
    deviceId,
  }: CredentialsTypes) {
    const { data } = await AuthenticationService.requestLogin(
      username,
      password,
      deviceId,
    );
    setSessionData({
      borrowerId: data.borrower_id,
    });
    storage.setItem(storageKeys.borrowerId, data.borrower_id);
    storage.setItem(storageKeys.accessToken, data.access_token);
    storage.setItem(storageKeys.refreshToken, data.refresh_token);
    storage.setItem(storageKeys.expiresAt, data.expires_at);

    setTimeout(() => {
      history.push('/dashboard');
    });
  }

  const requestLogout = async () => {
    cleanLocalStorage();

    setSessionData({
      borrowerId: '',
    });

    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ sessionData, requestLogout, requestLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.defaultProps = {
  children: React,
};

export const useAuth = () => {
  const ctx = useContext(AuthContext);
  return ctx;
};
