import { useHistory } from 'react-router-dom';

import WarningIcon from 'assets/img/warning.svg';
import { ButtonLoader } from 'components/ButtonLoader';
import { useModal } from 'hooks/useModal';
import { useRouter } from 'hooks/useRouter';
import { useTracking } from 'hooks/useTracking';
import { storageKeys } from 'store/storage-keys';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { CheckoutTypes } from 'types/Checkout/CheckoutTypes';
import { storage } from 'utils/storage';

import {
  AlertContainer,
  Img,
  Box,
  Stack,
  Text,
  TextWithStrokes,
} from './style';

export function AlertCard() {
  const colors = getThemeColors();

  const { history } = useRouter();
  const { closeModal } = useModal();
  const { location } = useHistory<CheckoutTypes>();

  const { trackEvent } = useTracking();
  const borrowerId = storage.getItem(storageKeys.borrowerId);
  const loanId = storage.getItem(storageKeys.loanUid);

  function handlePayInstallments(paymentType) {
    trackEvent('select_item', {
      item_name:
        paymentType === 'payment-slip'
          ? 'continuar_com_boleto'
          : 'pague_com_pix',
      borrower_id: borrowerId,
      loan_id: loanId,
    });

    history.push({
      pathname: '/dashboard/checkout-summary',
      state: {
        ...location.state,
        paymentType,
      },
    });

    closeModal();
  }

  return (
    <Stack margin="24px 0 0 0">
      <Box>
        <Stack gap="0" position="absolute" top="-25px">
          <Img src={WarningIcon} />
          <Text fontWeight="700" fontSize="16px">
            Importante
          </Text>
        </Stack>
        <AlertContainer>
          <Text margin="40px 0 0 0">
            Com boleto, a identificação do pagamento pode levar até três dias
            úteis
          </Text>
        </AlertContainer>
      </Box>

      <Stack>
        <ButtonLoader
          isLoading={false}
          onClick={() => handlePayInstallments('payment-slip')}
        >
          continuar com boleto
        </ButtonLoader>

        <TextWithStrokes fontSize="14px">
          Quer confirmação imediata?
        </TextWithStrokes>

        <ButtonLoader
          isLoading={false}
          className="tertiary"
          color={colors.noverde}
          onClick={() => handlePayInstallments('pix')}
        >
          Pague com pix
        </ButtonLoader>
      </Stack>
    </Stack>
  );
}
