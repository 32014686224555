import { useState } from 'react';

import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): [T, (s: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = storage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      storage.setItem(key, JSON.stringify(valueToStore));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return [storedValue, setValue];
};

type StorageValueType = keyof {
  [M in keyof typeof storageKeys as `${typeof storageKeys[M]}`]: M;
};

export const getStorageValue = <T>(key: StorageValueType): T | null => {
  const item = storage.getItem(key as string);
  return item ? JSON.parse(item) : null;
};
