export type ToBrlOptions = {
  withoutPrefix?: boolean;
  withoutCents?: boolean;
};

export const toBrl = (
  value: string | number,
  { withoutCents, withoutPrefix }: ToBrlOptions = {},
) =>
  Number(Number.isNaN(Number(value)) ? 0 : value)
    .toLocaleString('pt-BR', {
      ...(!withoutPrefix && {
        style: 'currency',
        currency: 'BRL',
      }),
      maximumFractionDigits: withoutCents ? 0 : 2,
      minimumFractionDigits: withoutCents ? 0 : 2,
    })
    .replace(' ', ' ');
