/* eslint-disable no-await-in-loop */
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import PaymtService from 'services/Paymt/PaymtService';
import { LoaderIconStyled } from 'styles/loader';
import { later } from 'utils/helpers/later';
import { useStore } from 'zstore';

import { ButtonStyled, TextStyled } from './style';

const DeniedMessages = {
  pix: 'Não foi possível gerar o PIX.',
  'payment-slip': 'Não foi possível gerar o boleto.',
  creditcard:
    'Não identificamos seu cartão.  Por favor verifique os dados do seu cartão de crédito e tente novamente.',
};

export function PaymentPolling() {
  const { id }: any = useParams();
  const [error, setError] = useState<any>(null);
  const history = useHistory();
  const { setContractInfo } = useStore();

  const requestPoll = async () => {
    let count = 0;

    while (count < 11) {
      await later(5000);

      try {
        const { data } = await PaymtService.getCheckout(id);

        const { state, method } = data;

        if (['failed'].includes(state)) {
          setError('Falha ao realizar seu pagamento.');
          break;
        } else if (['paid', 'waiting_payment'].includes(state)) {
          switch (method) {
            case 'creditcard':
              setContractInfo({});

              if (state !== 'paid')
                setError('Não foi possível completar seu pagamento.');
              else history.push('/dashboard/creditcard-payment');
              break;
            case 'pix':
              history.push({
                pathname: '/dashboard/pix-payment',
                state: {
                  total: data.total_value || 0,
                  pix: {
                    dueDate: data.info?.due_date || '',
                    emvqrcps: data.info?.emvqrcps || '',
                    id: data.info?.id || 0,
                    transactionId: data.info?.transaction_id || '',
                  },
                  receiver: {
                    bank: data.info?.bank || '',
                    cnpj: data.info?.cnpj || '',
                    key: data.info?.key || '',
                    name: data.info?.name || '',
                  },
                },
              });
              break;
            case 'payment-slip':
              history.push({
                pathname: '/dashboard/boleto-payment',
                state: {
                  dueDate: data.info?.due_date || '',
                  paymentId: data.id || '',
                  digitableLine: data.info?.digitable_line || '',
                  total: data.total_value || 0,
                },
              });
              break;

            default:
              break;
          }
          break;
        } else if (['denied'].includes(state)) {
          setError(DeniedMessages[method]);
          break;
        } else {
          count += 1;
        }
      } catch {
        setError('Tivemos um problema ao realizar seu pagamento.');

        break;
      }
    }

    if (count >= 11) {
      setError('Não foi possível completar seu pagamento.');
    }
  };

  useEffect(() => {
    requestPoll();
  }, []);

  if (error) {
    return (
      <>
        <TextStyled>
          Ops!
          <br />
          <br />
          {error}
          <br />
          <br />
          :(
        </TextStyled>
        <ButtonStyled>
          <Link to="/dashboard">entendi</Link>
        </ButtonStyled>
      </>
    );
  }

  return (
    <>
      <LoaderIconStyled width={85} height={85} color="noverde" />
      <TextStyled>
        Quase lá!
        <br />
        <br />
        Seu pagamento está sendo processado,
        <br /> aguarde alguns instantes.
        <br />
        <br />
        :)
      </TextStyled>
    </>
  );
}
