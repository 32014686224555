import { storageKeys } from 'store/storage-keys';
import { colors } from 'styles/colors';
import { colorsV2 } from 'styles/colors-v2';
import { dotzTheme } from 'styles/themes/dotz';
import { ExpiredStorage } from 'utils/expiredStorage';

export type ThemeType = 'dotz';

export type ColorsTypes = typeof colors & typeof colorsV2;

type RecordTheme = Partial<Record<ThemeType, Partial<ColorsTypes>>>;

export function getThemeColors(props?: RecordTheme): ColorsTypes {
  const oldTheme = { ...colors, ...colorsV2 };
  const theme: RecordTheme = {
    dotz: {
      ...oldTheme,
      noverde: dotzTheme.colorBrandPrimaryPure,
      ...props?.dotz,
    },
  };

  const storagedTheme = ExpiredStorage.getItemWithExpiry('multitheme');
  if (storagedTheme !== null) return theme[storagedTheme];
  localStorage.removeItem(storageKeys.multitheme);
  return oldTheme;
}
