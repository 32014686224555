import fakerBr from 'faker-br';
import { mask } from 'remask';
import { formatDate } from 'utils/formatters/format-date';
import { toBrl } from 'utils/formatters/to-brl';

import { masks } from '../masks';

export const valuesMock = () => {
  const phone = `119${fakerBr.random.number({ max: 99999999, min: 10000000 })}`;
  const password = '1234567a';

  return {
    uid: fakerBr.random.uuid(),
    access_token: fakerBr.random.uuid(),
    refresh_token: fakerBr.random.uuid(),
    expires_at: fakerBr.date.future(),
    password,
    passwordConfirmation: password,
    username: fakerBr.br.cpf(),
    name: fakerBr.name.findName(),
    mobile_number: phone,
    email: fakerBr.internet.email().toLocaleLowerCase(),
    document: mask(fakerBr.br.cpf(), masks('cpf')),
    status: 'success',
    pin: fakerBr.random.number({ max: 9999 }),
    borrower_id: fakerBr.random.number({ max: 99999999 }),

    // Frontend
    mobileNumber: mask(phone, masks('phone')),
    requestedAmount: toBrl(fakerBr.random.number({ max: 4000, min: 400 }), {
      withoutPrefix: true,
    }),
    requestedPeriod: fakerBr.random.number({ max: 12, min: 1 }),
    requestedPayday: fakerBr.random.number({ max: 27, min: 1 }),
    employmentStatus: fakerBr.random.arrayElement([
      'employee',
      'worker',
      'self_employed',
      'public_employee',
      'retired',
      'unemployed',
    ]),
    income: toBrl(fakerBr.random.number({ max: 12000, min: 1100 }), {
      withoutPrefix: true,
    }),
    zipCode: mask(fakerBr.address.zipCodeValid(), masks('zipCode')),
    streetName: fakerBr.address.streetName(),
    streetNumber: fakerBr.random.number({ max: 1000, min: 1 }),
    complement: fakerBr.address.secondaryAddress(),
    neighborhood: fakerBr.name.lastName(),
    city: fakerBr.address.city(),
    state: fakerBr.address.stateAbbr(),
    birthdate: formatDate(
      fakerBr.date
        .between(new Date('1946/01/01'), new Date('2003/01/01')) // 75 anos
        .toDateString(),
      {
        format: 'DD/MM/YYYY',
      },
    ),
    cpf: mask(fakerBr.br.cpf(), masks('cpf')),
    accountType: fakerBr.random.arrayElement(['checking', 'savings']),
    agency: fakerBr.random.number({ max: 9999 }),
    accountDigit: fakerBr.random.number({ max: 9 }),
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    account: fakerBr.random.number({ max: 99999999999999999999 }),
    terms: true,
    bankCode: fakerBr.random.arrayElement([
      '184',
      '204',
      '208',
      '212',
      '213',
      '214',
      '217',
      '218',
      '222',
      '224',
      '225',
      '229',
      '230',
      '233',
      '237',
      '241',
      '243',
      '246',
      '248',
      '249',
      '250',
      '252',
      '254',
      '263',
      '265',
      '266',
      '300',
      '318',
    ]),
  };
};

type Keys = keyof ReturnType<typeof valuesMock>;
export const pickMockValue = <T, K = keyof T>(keys: Array<Extract<Keys, K>>) =>
  keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: valuesMock()[key],
    }),
    {},
  ) as T;
