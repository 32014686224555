import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

const fadeContainerHeight = 72;

interface WithFixedContentContainerProps {
  fixedContentHeightTop: number;
  maxHeight: boolean;
  containerHeight: number;
}

export const WithFixedContentContainer = styled.div<WithFixedContentContainerProps>`
  ${({ fixedContentHeightTop, maxHeight, containerHeight }) => css`
    position: relative;

    width: 100%;

    overflow-y: auto;
    padding-top: ${fixedContentHeightTop}px;
    height: ${containerHeight}px;
    display: flex;

    @media ${device.lg} {
      display: block;
      padding-top: 0px;
      min-height: 100vh;
      height: 100vh;
      ${maxHeight &&
      css`
        max-height: 135vh;
        height: 100%;
      `}
      overflow:auto;
      margin-top: 0;
      margin-bottom: 0;
    }
  `}
`;

interface WithFixedHolder {
  maxWidth?: string;
  padding?: string;
}

export const WithFixedHolder = styled.div<WithFixedHolder>`
  ${({ maxWidth, padding }) => css`
    max-width: ${maxWidth ? '100%' : `calc(532px + 32px)`};
    width: 100%;
    padding: ${padding};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @media ${device.lg} {
      padding: 0px;
    }
  `}
`;

interface ScrollableContentProps {
  fixedContentHeight: number;
  paddingTop: boolean;
  paddingBottom: boolean;
}

export const ScrollableContent = styled.div<ScrollableContentProps>`
  ${({ fixedContentHeight, paddingTop, paddingBottom }) => css`
    padding-top: ${paddingTop ? 16 : 0}px;
    padding-bottom: ${paddingBottom ? 16 : 0}px;

    @media ${device.lg} {
      min-height: ${fixedContentHeight
        ? `calc(100vh - ${fixedContentHeight}px)`
        : `100vh`};
    }
  `}
`;

export const FixedContentTop = styled.div`
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 99998;

  @media ${device.lg} {
    position: sticky;
  }
`;

interface FixedContentBottomProps {
  baseColor?: string;
  maxWidth?: string;
}

export const FixedContentBottom = styled.div<FixedContentBottomProps>`
  ${({ baseColor, maxWidth }) => css`
    width: 100%;
    padding: 4px 0px;
    max-width: ${maxWidth ? '100%' : `calc(532px + 32px)`};
    margin: 0 auto;

    background-color: ${colors[baseColor ?? 'white']};
    position: sticky;
    bottom: 0;
    z-index: 99998;

    @media (min-height: 481px) {
      padding: 16px 0px;
    }

    @media ${device.lg} {
      position: sticky;
      padding: 16px 0;

      left: 0;
      transform: translateX(0);
    }

    ${FadeContent} {
      background: ${colors[baseColor ?? 'white']};
      background: linear-gradient(
        0deg,
        ${transparentize(0.8, colors.greyCustom)} 0%,
        ${transparentize(0.5, colors[baseColor ?? 'white'])} 50%,
        ${transparentize(1, colors[baseColor ?? 'white'])} 100%
      );

      @media (min-height: 561px) {
        background: linear-gradient(
          0deg,
          ${colors[baseColor ?? 'white']} 0%,
          ${transparentize(0.5, colors[baseColor ?? 'white'])} 50%,
          ${transparentize(1, colors[baseColor ?? 'white'])} 100%
        );
      }
    }
  `}
`;

export const FadeContent = styled(motion.div)`
  display: block;
  width: 100%;
  height: ${fadeContainerHeight / 4}px;

  position: absolute;
  bottom: 100%;

  @media (min-height: 561px) {
    height: ${fadeContainerHeight}px;
  }
`;
