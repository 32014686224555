import { useContext } from 'react';

import { ModalContext } from 'contexts/ModalContext';

export const useModal = () => {
  const ctx = useContext(ModalContext);

  if (ctx === undefined) {
    throw new Error('useModal must be used inside a ModalContext');
  }

  return {
    isModalOpen: ctx.isModalOpen,
    openModal: ctx.openModal,
    closeModal: ctx.closeModal,
  };
};
