import { useHistory } from 'react-router-dom';

import { MessageWithButton } from 'components/MessageWithButton';

export function Unavailable() {
  const history = useHistory();

  return (
    <MessageWithButton
      title={
        <>
          Estamos passando por alterações no <b>sistema</b>
        </>
      }
      text={
        <>
          Se em alguns instantes você ainda não conseguir prosseguir, entre em
          contato com a gente <b>meajuda@noverde.com.br</b>
        </>
      }
      primaryButton={{
        action: () => history.goBack(),
        label: 'Tentar novamente',
      }}
      secondaryButton={{
        action: () => history.push('/'),
        label: 'Voltar para tela inicial',
      }}
    />
  );
}
