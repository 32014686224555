import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

import { TypographyProps } from './types';

const colors = getThemeColors();

const renderH2Mobile = css`
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.5px;
`;

const renderH2BMobile = css`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0.5px;
`;

const renderH1Desktop = css`
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 0.16px;
`;

const renderH1Mobile = css`
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 0.01em;
`;

const renderH2BDesktop = css`
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0.16px;
`;

const renderH2Desktop = css`
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 1%;
`;

const renderBody = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.16px;
`;

const renderBodyDesktop = css`
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.16px;
`;

const renderBodyBMobile = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.16px;
`;

const renderBodyBDesktop = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.16px;
`;

const renderSmallB = css`
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.32px;
`;

const renderH3BDesktop = css`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0px;
`;

const renderH3BMobile = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0px;
`;

const renderSmall = css`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.32px;
`;

const renderH3Desktop = css`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;
`;

const renderH3Mobile = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0px;
`;

const renderH1BMobile = css`
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: 0.01em;
`;

const renderH1BDesktop = css`
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: 1%;
`;

const renderPadding = (padding?: string) =>
  padding
    ? css`
        padding: ${padding};
      `
    : '';

const renders = {
  h1: renderH1Mobile,
  h1_b_mobile: renderH1BMobile,
  h1_b_desktop: renderH1BDesktop,
  'h1-desktop': renderH1Desktop,
  'h2-mobile': renderH2Mobile,
  'h2-desktop': renderH2Desktop,
  h2_b_desktop: renderH2BDesktop,
  h2_b_mobile: renderH2BMobile,
  h3_b_desktop: renderH3BDesktop,
  h3_b_mobile: renderH3BMobile,
  'h3-mobile': renderH3Mobile,
  'h3-desktop': renderH3Desktop,
  body: renderBody,
  'body-desktop': renderBodyDesktop,
  body_b_mobile: renderBodyBMobile,
  body_b_desktop: renderBodyBDesktop,
  small_b: renderSmallB,
  small: renderSmall,
};

type TypographyStyleTypes = Pick<TypographyProps, '_style'>;

export const Typography = styled.span<TypographyStyleTypes>`
  ${({ _style }) => css`
    color: ${_style?.color ? colors[_style?.color] : colors.galaxy};
    ${_style?.cursor && `cursor: ${_style.cursor};`}
    ${renderPadding(_style?.padding)}

    ${_style?.default ? renders[_style.default] : renders['h1-desktop']}
    
    @media${device.xs} {
      ${_style?.xs && renders[_style.xs]}
    }
  `}
`;
