import styled from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

export const Box = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1.5rem;
  width: 100%;

  @media ${device.sm} {
    width: 35rem;
  }
`;

export const Title = styled.p`
  color: ${colors.text};
  font-size: 14px;
  margin-bottom: 2rem;
  text-align: center;
`;

export const CodigoBarras = styled.p`
  display: inline-block;
  background-color: ${colors.greyborders};
  border-radius: 4px;
  color: ${colors.galaxy};
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem 1.5rem;
  width: 100%;
`;

export const LineGroup = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2.5rem;
  width: 100%;

  div {
    align-items: center;
    border-bottom: 1px solid ${colors.greyborders};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding: 0.75rem 0;
    width: 100%;

    :first-child {
      margin-bottom: 12px;
    }

    @media ${device.xs} {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: grid;
  gap: 1rem;
  width: 100%;
`;

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 252px;

  p {
    color: ${colors.text};
    font-size: 14px;
    text-align: center;

    strong {
      font-weight: 600;
    }
  }
`;
