import { CSSProperties, FC } from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

export const WhiteLogoNoverdePorDotz: FC<Props> = ({
  width = 167,
  height = 56,
  color = 'white',
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 167 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M62.5626 9.45146C62.5626 3.70407 66.7073 0 71.7921 0C77.6888 0 81.0224 4.21534 81.0224 9.96273C81.0224 19.3722 75.211 32.9541 66.579 32.9541C62.4343 32.9541 59.6569 30.8249 58.0753 25.7587L50.0417 0.511268H57.2634L64.5704 24.3964C65.0405 25.9294 65.724 26.7383 67.0491 26.7383C70.9802 26.7383 74.6556 15.8388 74.6556 9.96361C74.6556 7.2812 73.545 5.83406 71.793 5.83406C70.0409 5.83406 68.7159 7.11136 68.7159 9.45321H62.5626V9.45146Z"
        fill={color}
      />
      <path
        d="M0 0.511719H7.2648L21.2812 22.6083V0.511719H27.6911V32.4432H20.4263L6.36769 10.1759V32.4432H0V0.511719Z"
        fill={color}
      />
      <path
        d="M53.458 21.7146C53.458 28.186 48.5437 32.9546 41.792 32.9546C35.1256 32.9546 30.2121 28.186 30.2121 21.7146C30.2121 15.2003 35.1265 10.4316 41.792 10.4316C48.5437 10.4316 53.458 15.2003 53.458 21.7146ZM36.7072 21.7146C36.7072 25.0352 38.6728 27.2072 41.792 27.2072C44.9543 27.2072 46.9199 25.0361 46.9199 21.7146C46.9199 18.3931 44.9543 16.2219 41.792 16.2219C38.6728 16.2219 36.7072 18.3931 36.7072 21.7146Z"
        fill={color}
      />
      <path
        d="M81.8063 21.8414C81.8063 15.2422 86.4641 10.4307 93.1727 10.4307C99.4973 10.4307 103.941 14.646 103.941 20.9467C103.941 21.7127 103.855 22.7352 103.642 23.6291H88.0879C88.6862 26.3535 90.6088 27.5888 93.3862 27.5888C95.9932 27.5888 97.4457 26.5242 98.0871 24.9913L103.557 26.5663C102.061 30.7387 98.3006 32.9527 93.301 32.9527C86.0793 32.9536 81.8063 28.185 81.8063 21.8414ZM88.1731 19.2019H97.617C97.2752 16.9029 95.6514 15.4978 93.1305 15.4978C90.7371 15.4978 88.8998 16.7322 88.1731 19.2019Z"
        fill={color}
      />
      <path
        d="M106.078 10.942H111.761L112.188 13.1131C113.513 11.4953 115.35 10.4736 117.956 10.4736C118.768 10.4736 119.281 10.5586 120.179 10.7713L119.452 16.945C118.683 16.7323 117.871 16.6894 117.23 16.6894C114.452 16.6894 112.358 18.52 112.358 21.7986V32.4424H106.077V10.942H106.078Z"
        fill={color}
      />
      <path
        d="M137.101 32.4431L136.587 30.1862C134.964 31.9319 132.742 32.9535 130.178 32.9535C124.238 32.9535 119.623 28.1849 119.623 21.7135C119.623 15.1992 124.238 10.4306 130.178 10.4306C132.613 10.4306 134.707 11.3244 136.289 12.9002V0.510742H142.57V32.4423H137.101V32.4431ZM126.161 21.7135C126.161 25.0341 128.085 27.2061 131.204 27.2061C134.323 27.2061 136.289 25.035 136.289 21.7135C136.289 18.392 134.323 16.2209 131.204 16.2209C128.085 16.2217 126.161 18.3929 126.161 21.7135Z"
        fill={color}
      />
      <path
        d="M144.622 21.8414C144.622 15.2422 149.279 10.4307 155.988 10.4307C162.313 10.4307 166.756 14.646 166.756 20.9467C166.756 21.7127 166.671 22.7352 166.457 23.6291H150.903C151.502 26.3535 153.424 27.5888 156.202 27.5888C158.809 27.5888 160.261 26.5242 160.902 24.9913L166.372 26.5663C164.877 30.7387 161.116 32.9527 156.116 32.9527C148.895 32.9536 144.622 28.185 144.622 21.8414ZM150.988 19.2019H160.432C160.091 16.9029 158.467 15.4978 155.946 15.4978C153.552 15.4978 151.715 16.7322 150.988 19.2019Z"
        fill={color}
      />
      <path
        d="M166.578 55.6724C166.688 52.5698 164.627 50.122 161.315 50.122C160.972 50.122 160.618 50.1666 160.269 50.2411L166.25 42.5615H154.626V45.468H160.342L152.542 55.6733H156.293C157.521 54.0125 158.99 52.8762 160.767 52.8762C162.5 52.8762 163.442 53.9688 163.376 55.6733H166.578V55.6724Z"
        fill={color}
      />
      <path
        d="M152.018 45.4675V42.561H147.763V41.7092C147.763 39.3489 146.206 37.8633 143.705 37.8633C141.293 37.8633 139.538 39.4365 139.538 41.6873C139.538 44.0904 141.205 45.4675 144.078 45.4675H144.604V51.7831C144.604 54.4708 146.008 56.0011 148.838 56.0011C149.825 56.0011 150.614 55.8478 151.053 55.6299V52.8984C150.658 53.0516 150.22 53.1602 149.606 53.1602C148.29 53.1602 147.763 52.4607 147.763 51.2377V45.4684H152.018V45.4675ZM143.793 42.561C142.982 42.561 142.498 42.1679 142.498 41.4903C142.498 40.8784 142.915 40.4634 143.507 40.4634C144.187 40.4634 144.604 40.944 144.604 41.7311V42.5619H143.793V42.561Z"
        fill={color}
      />
      <path
        d="M133.991 42.2823C129.343 41.7693 125.738 45.3604 126.252 49.9916C126.577 52.9244 129.291 55.6278 132.234 55.9517C136.881 56.4638 140.486 52.8736 139.972 48.2424C139.646 45.3105 136.934 42.6071 133.991 42.2823Z"
        fill={color}
      />
      <path
        d="M124.305 38.1904H121.147V41.4682C121.147 42.9879 121.1 43.6664 120.956 44.9507C119.741 43.0641 117.97 42.2333 115.905 42.2333C112.133 42.2333 110.048 45.4454 110.048 49.0295C110.048 52.7222 112.132 55.9999 115.905 55.9999C117.972 55.9999 119.744 55.1673 120.959 53.2772C121.119 54.5125 121.146 55.1411 121.146 55.6725H124.304V55.0168C124.304 52.8903 123.933 50.7647 123.443 49.0505C123.985 47.3486 124.304 45.3377 124.304 41.687V38.1904H124.305ZM116.211 52.9848C114.15 52.9848 113.25 51.0833 113.25 49.0295C113.25 47.0842 114.149 45.2493 116.211 45.2493C117.79 45.2493 119.259 46.713 119.962 49.0295C119.259 51.4554 117.79 52.9848 116.211 52.9848Z"
        fill={color}
      />
      <path
        d="M82.2151 48.5439H84.8071C86.2367 48.5439 87.1514 49.4457 87.1514 50.6897C87.1514 51.9145 86.2367 52.8547 84.8071 52.8547H83.0252V55.6649H82.2151V48.5439ZM83.0252 49.2846V52.1141H84.8071C85.7693 52.1141 86.3316 51.5258 86.3316 50.6897C86.3316 49.8449 85.7693 49.2846 84.8071 49.2846H83.0252Z"
        fill={color}
      />
      <path
        d="M92.4787 48.4678C94.5462 48.4678 95.9564 49.9963 95.9564 52.1044C95.9564 54.2029 94.5462 55.7411 92.4787 55.7411C90.42 55.7411 89.0001 54.2029 89.0001 52.1044C89.0001 49.9963 90.42 48.4678 92.4787 48.4678ZM92.4787 49.218C90.8778 49.218 89.8392 50.3955 89.8392 52.1044C89.8392 53.8133 90.8778 54.9908 92.4787 54.9908C94.07 54.9908 95.1182 53.8133 95.1182 52.1044C95.1182 50.3955 94.07 49.218 92.4787 49.218Z"
        fill={color}
      />
      <path
        d="M98.5583 48.5439H101.16C102.427 48.5439 103.304 49.3511 103.304 50.5374C103.304 51.5153 102.665 52.2086 101.779 52.4082L103.943 55.6649H102.98L100.893 52.5317H99.3684V55.6649H98.5583V48.5439ZM99.3684 49.2846V51.791H101.113C101.932 51.791 102.475 51.278 102.475 50.5374C102.475 49.7871 101.952 49.2837 101.151 49.2837H99.3684V49.2846Z"
        fill={color}
      />
    </svg>
  );
};
