import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin: 0 auto;
  max-width: 532px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
`;

export const ContentSlider = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > p {
    color: ${colors.galaxy};
  }
`;
