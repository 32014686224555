import styled from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  padding: 1rem;
  box-shadow: none;

  @media ${device.sm} {
    grid-template-columns: repeat(1, 1fr);
    padding: 6rem 3.5rem;
  }
`;

export const Card = styled.div`
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 5px 0.5rem #e8e8e8;
  cursor: pointer;
  justify-items: center;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 1rem 1.5rem;
  margin-bottom: 12px;
  width: 100%;

  &:hover {
    box-shadow: 0 5px 0.75rem #c1c1c1;
  }

  svg {
    color: ${colors.placeholders};
    height: 1.5rem;
    width: 1.5rem;
    &:hover {
      cursor: pointer;
    }
  }

  @media ${device.sm} {
    grid-template-columns: auto 15rem auto;
  }
`;

export const ContainerText = styled.div`
  display: grid;
`;

export const Title = styled.span`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 700;
`;

export const Description = styled.p`
  color: ${colors.text};
  font-size: 14px;
  font-weight: 40;
  max-width: 232px;
`;

export const Img = styled.img`
  margin-right: 1rem;
`;

export const ContainerLoading = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
`;
