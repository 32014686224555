import styled from 'styled-components';
import { colors } from 'styles/colors';

export const TextContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    text-align: left;
  }
  > span {
    display: flex;
    > svg {
      width: 16px;
      height: 16px;
      margin: 0 5px;
      color: ${colors.noverde};
    }
  }

  .react-tooltip {
    z-index: 99999999;
  }
`;
