import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

const colors = getThemeColors();

export const Box = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  position: relative;
  margin-top: 1.5rem;

  @media ${device.sm} {
    width: 35rem;
  }
`;

export const BoxTitle = styled.p`
  color: ${colors.text};
  font-size: 14px;
  text-align: center;
  margin-bottom: 1rem;
`;

export const BoxImagePix = styled.div`
  border: 2px solid ${colors.noverde};
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

export const DetailsContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  justify-content: center;

  img {
    color: ${colors.noverde};
  }

  span {
    color: ${colors.noverde};
    font-size: 14px;
  }
`;

export const ContainerDetails = styled.div`
  background: ${colors.white};
  height: fit-content;
  padding: 1.5rem 2rem;
  width: 100%;
  height: fit-content;

  div {
    display: grid;
    margin-bottom: 1rem;

    strong {
      color: ${colors.galaxy};
      font-weight: 600;
    }
  }
`;

export const ValueTitle = styled.p`
  color: ${colors.galaxy};
  font-size: 14px;
`;

export const ValueDetail = styled.span`
  color: ${colors.galaxy};
  font-size: 20px;
  font-weight: 800;
`;

export const TitleDetail = styled.p`
  color: ${colors.galaxy};
  font-size: 14px;
  font-weight: 700;
`;

export const DescriptionDetail = styled.p`
  color: ${colors.galaxy};
  font-size: 14px;
`;

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  height: 252px;

  p {
    color: ${colors.text};
    font-size: 14px;
    text-align: center;

    strong {
      font-weight: 600;
    }
  }
`;
