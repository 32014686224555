import { CSSProperties, FC } from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: CSSProperties;
}

export const BlackLogoNoverdePorDotz: FC<Props> = ({
  width = 167,
  height = 56,
  color = '#121212',
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 167 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M62.562 9.45146C62.562 3.70407 66.7066 0 71.7915 0C77.6882 0 81.0218 4.21534 81.0218 9.96273C81.0218 19.3722 75.2103 32.9541 66.5783 32.9541C62.4337 32.9541 59.6562 30.8249 58.0747 25.7587L50.041 0.511268H57.2627L64.5697 24.3964C65.0398 25.9294 65.7234 26.7383 67.0484 26.7383C70.9796 26.7383 74.655 15.8388 74.655 9.96361C74.655 7.2812 73.5444 5.83406 71.7923 5.83406C70.0403 5.83406 68.7152 7.11136 68.7152 9.45321H62.562V9.45146Z"
        fill={color}
      />
      <path
        d="M0 0.511719H7.2648L21.2812 22.6083V0.511719H27.6911V32.4432H20.4263L6.36769 10.1759V32.4432H0V0.511719Z"
        fill={color}
      />
      <path
        d="M53.4588 21.7126C53.4588 28.184 48.5444 32.9526 41.7928 32.9526C35.1264 32.9526 30.2129 28.184 30.2129 21.7126C30.2129 15.1983 35.1273 10.4297 41.7928 10.4297C48.5444 10.4297 53.4588 15.1983 53.4588 21.7126ZM36.708 21.7126C36.708 25.0332 38.6735 27.2052 41.7928 27.2052C44.9551 27.2052 46.9207 25.0341 46.9207 21.7126C46.9207 18.3911 44.9551 16.22 41.7928 16.22C38.6735 16.22 36.708 18.3911 36.708 21.7126Z"
        fill={color}
      />
      <path
        d="M81.8066 21.8404C81.8066 15.2412 86.4644 10.4297 93.173 10.4297C99.4976 10.4297 103.941 14.645 103.941 20.9457C103.941 21.7117 103.856 22.7343 103.642 23.6281H88.0882C88.6866 26.3525 90.6091 27.5878 93.3865 27.5878C95.9935 27.5878 97.446 26.5233 98.0874 24.9903L103.557 26.5653C102.062 30.7377 98.3009 32.9518 93.3013 32.9518C86.0796 32.9526 81.8066 28.184 81.8066 21.8404ZM88.1734 19.2009H97.6173C97.2755 16.902 95.6517 15.4968 93.1308 15.4968C90.7374 15.4968 88.9001 16.7312 88.1734 19.2009Z"
        fill={color}
      />
      <path
        d="M106.079 10.941H111.762L112.189 13.1122C113.514 11.4943 115.352 10.4727 117.958 10.4727C118.77 10.4727 119.283 10.5576 120.18 10.7703L119.453 16.9441C118.684 16.7313 117.872 16.6884 117.231 16.6884C114.454 16.6884 112.36 18.519 112.36 21.7976V32.4414H106.078V10.941H106.079Z"
        fill={color}
      />
      <path
        d="M137.101 32.4441L136.587 30.1872C134.964 31.9328 132.741 32.9545 130.178 32.9545C124.238 32.9545 119.623 28.1859 119.623 21.7145C119.623 15.2002 124.238 10.4316 130.178 10.4316C132.613 10.4316 134.707 11.3254 136.289 12.9012V0.511719H142.57V32.4432H137.101V32.4441ZM126.161 21.7145C126.161 25.0351 128.085 27.2071 131.204 27.2071C134.323 27.2071 136.289 25.036 136.289 21.7145C136.289 18.393 134.323 16.2218 131.204 16.2218C128.085 16.2227 126.161 18.3939 126.161 21.7145Z"
        fill={color}
      />
      <path
        d="M144.621 21.8404C144.621 15.2412 149.279 10.4297 155.987 10.4297C162.312 10.4297 166.756 14.645 166.756 20.9457C166.756 21.7117 166.67 22.7343 166.457 23.6281H150.903C151.501 26.3525 153.424 27.5878 156.201 27.5878C158.808 27.5878 160.26 26.5233 160.902 24.9903L166.372 26.5653C164.876 30.7377 161.115 32.9518 156.116 32.9518C148.894 32.9526 144.621 28.184 144.621 21.8404ZM150.988 19.2009H160.432C160.09 16.902 158.466 15.4968 155.945 15.4968C153.552 15.4968 151.715 16.7312 150.988 19.2009Z"
        fill={color}
      />
      <path
        d="M166.578 55.6734C166.687 52.5708 164.626 50.123 161.314 50.123C160.972 50.123 160.618 50.1676 160.268 50.242L166.249 42.5625H154.625V45.469H160.341L152.541 55.6743H156.292C157.52 54.0135 158.99 52.8772 160.766 52.8772C162.499 52.8772 163.442 53.9697 163.376 55.6743H166.578V55.6734Z"
        fill={color}
      />
      <path
        d="M152.019 45.4675V42.561H147.764V41.7092C147.764 39.3489 146.207 37.8633 143.707 37.8633C141.294 37.8633 139.539 39.4365 139.539 41.6873C139.539 44.0904 141.206 45.4675 144.079 45.4675H144.605V51.7831C144.605 54.4708 146.01 56.0011 148.839 56.0011C149.826 56.0011 150.615 55.8478 151.054 55.6299V52.8984C150.659 53.0516 150.221 53.1602 149.607 53.1602C148.291 53.1602 147.764 52.4607 147.764 51.2377V45.4684H152.019V45.4675ZM143.794 42.561C142.983 42.561 142.499 42.1679 142.499 41.4903C142.499 40.8784 142.916 40.4634 143.508 40.4634C144.188 40.4634 144.605 40.944 144.605 41.7311V42.5619H143.794V42.561Z"
        fill={color}
      />
      <path
        d="M133.991 42.2832C129.343 41.7702 125.738 45.3614 126.252 49.9925C126.577 52.9253 129.29 55.6288 132.234 55.9527C136.881 56.4648 140.485 52.8746 139.971 48.2434C139.646 45.3115 136.934 42.608 133.991 42.2832Z"
        fill={color}
      />
      <path
        d="M124.305 38.1914H121.147V41.4691C121.147 42.9889 121.101 43.6674 120.957 44.9517C119.741 43.0651 117.971 42.2343 115.905 42.2343C112.133 42.2343 110.049 45.4463 110.049 49.0305C110.049 52.7232 112.132 56.0009 115.905 56.0009C117.973 56.0009 119.744 55.1683 120.959 53.2782C121.119 54.5135 121.146 55.1421 121.146 55.6735H124.304V55.0177C124.304 52.8912 123.933 50.7656 123.443 49.0515C123.985 47.3496 124.304 45.3387 124.304 41.688V38.1914H124.305ZM116.212 52.9858C114.15 52.9858 113.251 51.0843 113.251 49.0305C113.251 47.0852 114.15 45.2502 116.212 45.2502C117.791 45.2502 119.26 46.714 119.963 49.0305C119.26 51.4564 117.791 52.9858 116.212 52.9858Z"
        fill={color}
      />
      <path
        d="M82.2148 48.543H84.8069C86.2365 48.543 87.1511 49.4447 87.1511 50.6887C87.1511 51.9135 86.2365 52.8537 84.8069 52.8537H83.0249V55.664H82.2148V48.543ZM83.0249 49.2836V52.1131H84.8069C85.769 52.1131 86.3313 51.5248 86.3313 50.6887C86.3313 49.8439 85.769 49.2836 84.8069 49.2836H83.0249Z"
        fill={color}
      />
      <path
        d="M92.4787 48.4688C94.5462 48.4688 95.9564 49.9973 95.9564 52.1054C95.9564 54.2039 94.5462 55.7421 92.4787 55.7421C90.4199 55.7421 89 54.2039 89 52.1054C89 49.9973 90.4199 48.4688 92.4787 48.4688ZM92.4787 49.219C90.8777 49.219 89.8391 50.3965 89.8391 52.1054C89.8391 53.8143 90.8777 54.9918 92.4787 54.9918C94.0699 54.9918 95.1181 53.8143 95.1181 52.1054C95.1181 50.3965 94.0699 49.219 92.4787 49.219Z"
        fill={color}
      />
      <path
        d="M98.5586 48.5469H101.16C102.427 48.5469 103.304 49.3541 103.304 50.5403C103.304 51.5182 102.665 52.2116 101.78 52.4112L103.943 55.6679H102.98L100.893 52.5346H99.3687V55.6679H98.5586V48.5469ZM99.3687 49.2875V51.794H101.113C101.933 51.794 102.476 51.2809 102.476 50.5403C102.476 49.79 101.952 49.2866 101.152 49.2866H99.3687V49.2875Z"
        fill={color}
      />
    </svg>
  );
};
