import { useMemo } from 'react';

import { useGA4React } from 'ga-4-react';

export function useTracking() {
  const ga: any = useGA4React();

  const trackEvent = (name, body) => {
    ga?.gtag('event', name, body);
  };

  const trackProperty = (body) => {
    ga?.gtag('set', 'user_properties', body);
  };

  return useMemo(() => {
    return {
      trackEvent,
      trackProperty,
    };
  }, [ga]);
}
