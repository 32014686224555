import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const Root = styled.div`
  box-shadow: 0 0 0.75rem #e8e8e8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.2rem;
  height: fit-content;
  margin-bottom: 1rem;
  width: 100%;

  strong {
    display: block;
  }

  ol {
    margin: 1rem 0;
  }
  li {
    margin-left: 2rem;
    text-indent: 4px;

    a {
      color: ${colors};
    }
  }
`;

export const Head = styled.p`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  p {
    color: ${colors.galaxy};
    font-size: 0.9rem;
    font-weight: 600;
  }
  svg {
    height: 18px;
    width: 18px;
  }
`;

interface BodyProps {
  isOpen: boolean;
}
export const Body = styled.div<BodyProps>`
  ${({ isOpen }) => css`
    ${isOpen && `margin-top: 1rem`};
    overflow: ${isOpen ? 'visible' : 'hidden'};
    opacity: ${isOpen ? '1' : '0'};
    height: ${isOpen ? 'fit-content' : '0'};
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
  `}
`;
