import { FiChevronLeft, FiX } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';

import { colorsV2 } from 'styles/colors-v2';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { useStore } from 'zstore';

import * as S from './style';

const colors = getThemeColors();

const Variants = {
  primary: {
    color: colors.noverde,
    bgcolor: 'transparent',
  },
  secondary: {
    color: colors.white,
    bgcolor: colors.noverde,
  },
  tertiary: {
    color: colors.white,
    bgcolor: 'transparent',
  },
  quaternary: {
    color: colors.black,
    bgcolor: 'transparent',
  },
  fgts: {
    color: colorsV2['neutral-ultra-dark'],
    bgcolor: 'white',
  },
};

interface LocationState {
  targetRoute: string;
}

interface NavigationProps {
  variant?: keyof typeof Variants;
  customNavigation?: true;
  backTargetRoute?: string;
  title?: string;
  removeClose?: boolean;
}

export const Navigation = ({
  variant,
  customNavigation,
  backTargetRoute,
  title,
  removeClose,
}: NavigationProps) => {
  const history = useHistory();
  const { loanDetails } = useStore();
  const { state } = useLocation<LocationState>();
  const isSimulation = loanDetails.status === 'simulation';

  const handleGoBack = () => {
    if (state?.targetRoute || backTargetRoute) {
      history.push(state?.targetRoute || String(backTargetRoute));
    } else {
      history.goBack();
    }
  };

  const handleClose = () => {
    window.location.href = '/dashboard';
  };
  return (
    <S.NavigationContainer
      bgcolor={Variants[variant ?? ''].bgcolor}
      hasOneChild={![isSimulation, customNavigation].includes(true)}
    >
      {(isSimulation || customNavigation) && (
        <S.IconHolder
          type="button"
          onClick={handleGoBack}
          color={Variants[variant ?? ''].color}
        >
          <FiChevronLeft />
        </S.IconHolder>
      )}

      {title && <S.Title>{title}</S.Title>}

      {removeClose ? (
        <div />
      ) : (
        <S.IconHolder
          type="button"
          onClick={handleClose}
          color={Variants[variant ?? ''].color}
        >
          <FiX />
        </S.IconHolder>
      )}
    </S.NavigationContainer>
  );
};

Navigation.defaultProps = {
  variant: 'primary',
  customNavigation: false,
};
