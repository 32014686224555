/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */

import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useModal } from 'hooks/useModal';
import { useRouter } from 'hooks/useRouter';
import { useTracking } from 'hooks/useTracking';
import PaymtService from 'services/Paymt/PaymtService';
import { storageKeys } from 'store/storage-keys';
import { OfferPaymentTypes } from 'types/Renegotiation/Renegotiation';
import { isEmpty } from 'utils/helpers/is-empty';
import { storage } from 'utils/storage';

import { useDBTracking } from '../../../../contexts/DBTrackingContext';
import { ModalMoreOffers } from '../ModalMoreOffers';
import { OfferItemType } from './OfferItem';
import { OffersList } from './OffersList';
import { PanelButton } from './PanelButton';
import { PaymentMethods } from './PaymentMethods';

import * as S from './style';
import { ContainerOptions2 } from './style';

export type RenegotiationPanelItem = {
  title: string;
  description: string | ReactNode;
  titleTag?: string;
  offers: OfferItemType[];
};

export type RenegotiationPanelProps = {
  headerOptions: {
    containerValueType: 'current_debt' | 'late';
    description: string;
    value: number | string;
  };
  panelItems: RenegotiationPanelItem[];
  actionsWith?: {
    button?: boolean;
    buttonWithInfo?: {
      text: string | ReactNode;
      showText?: boolean;
    };
    terms?: boolean;
  };
  hasPaymentMethods?: boolean;
  confirmOrder?: () => void;
  confirmation?: boolean;
  moreOffers?: OfferItemType[];
  expires_at?: string;
  offerUuid?: string;
  selectedOffer?: number;
  hidePanel?: boolean;
  offer?: OfferPaymentTypes;
  isLoading?: boolean;
};

export function RenegotiationPanel({
  headerOptions,
  confirmation = false,
  panelItems,
  actionsWith,
  hasPaymentMethods,
  confirmOrder,
  moreOffers,
  expires_at = '',
  offerUuid = '',
  selectedOffer = 0,
  hidePanel = false,
  offer,
  isLoading,
}: RenegotiationPanelProps) {
  const { openModal } = useModal();
  const history = useHistory();
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [isLoadingCreditCard, setIsLoadingCreditCard] = useState(false);
  const { location } = useRouter();

  const [radioOptions, setRadioOptions] = useState([
    { title: 'PIX', value: 'pix', selected: false },
    { title: 'BOLETO BANCÁRIO', value: 'payment-slip', selected: false },
    { title: 'CARTÃO DE CRÉDITO', value: 'creditcard', selected: false },
  ]);

  const { trackEvent } = useTracking();
  const { setTrackingEvent } = useDBTracking();
  const borrowerId = storage.getItem(storageKeys.borrowerId);
  const [creditCards, setCreditCards] = useState<any>([]);

  const handleRadioItem = (position: number) => {
    if (position === 2) {
      isEmpty(creditCards) && getWallets();
    }

    const _ = [...radioOptions];
    _.map((__) => (__.selected = false));
    _[position].selected = !_[position].selected;
    setRadioOptions(_);
  };

  const verifyRadioSelection = radioOptions.some(
    (radio) => radio.selected === true,
  );

  const methodSelected = radioOptions.filter((item) => item.selected === true);

  const enableActions =
    actionsWith?.button || actionsWith?.buttonWithInfo || actionsWith?.terms;

  const setPayment = async () => {
    setIsLoadingPayment(true);

    try {
      trackEvent('select_item', {
        item_name: 'pagar_agora',
        borrower_id: borrowerId,
        item_variant: methodSelected[0].value,
      });

      setTrackingEvent({
        category: 'negotiation',
        action: 'offer_payment_requested',
        details: {
          method: methodSelected[0].value,
          accepted_offer_id: offer?.uuid,
        },
        loanUuid: true,
      });

      const responseRegisterPaymentIntent =
        await PaymtService.createRenegotiationCheckout({
          accepted_offer_uuid: offerUuid,
        });

      const { data } = await PaymtService.confirmCheckout({
        method: methodSelected[0].value,
        checkoutUuid: responseRegisterPaymentIntent?.data?.checkout_uuid,
        ...(!isEmpty(creditCards) &&
          methodSelected[0].value === 'creditcard' && {
            credicardUuid: creditCards[0].id,
          }),
      });

      history.push(`/dashboard/payment-polling/${data?.id}`);
    } catch (err) {
      console.warn({
        title: 'Erro ao criar checkout de pagamento',
        err,
      });
    } finally {
      setIsLoadingPayment(false);
    }
  };

  const getWallets = async () => {
    setIsLoadingCreditCard(true);
    try {
      const { data } = await PaymtService.getCreditCards();

      if (isEmpty(data)) {
        handleWalletRegister();
        return;
      }

      setCreditCards(data);
    } catch (err) {
      console.warn({
        title: 'Erro ao recuperar dados do cartão',
        err,
      });
    }

    setIsLoadingCreditCard(false);
  };

  function handleWalletRegister() {
    const state = {
      origin: location.pathname,
      offer: { ...offer },
    };
    history.push({
      pathname: '/dashboard/wallet-register',
      state,
    });
  }

  useEffect(() => {
    if (offer?.creditCardId) {
      setCreditCards([{ id: offer.creditCardId }]);

      const indexCreditCard = 2;

      const options = [...radioOptions];
      options[indexCreditCard].selected = true;

      setRadioOptions(options);
    }
  }, [offer]);

  return (
    <S.Wrapper>
      {/* FAIXA COM O VALOR E A DESCRIÇÃO */}

      {!hidePanel && (
        <S.ContainerValue
          containerValueType={headerOptions?.containerValueType}
        >
          <S.ContainerValueUpperText>
            {headerOptions?.description}
          </S.ContainerValueUpperText>
          <S.ContainerValuePrice>{headerOptions?.value}</S.ContainerValuePrice>
        </S.ContainerValue>
      )}

      <S.ContainerOptions>
        {panelItems?.map((option, key) => (
          <S.ContainerOptionsCard key={key}>
            {/* TÍTULO */}
            <S.ContainerOptionsCardTitleGroup>
              <S.ContainerOptionsCardTitle>
                {option.title}
              </S.ContainerOptionsCardTitle>

              {/* TAG DO TÍTULO */}
              {option.titleTag && (
                <S.ContainerOptionsCardTag>
                  {option.titleTag}
                </S.ContainerOptionsCardTag>
              )}
            </S.ContainerOptionsCardTitleGroup>

            {/* DESCRIÇÃO */}
            <S.ContainerOptionsCardDescription>
              {option.description}
            </S.ContainerOptionsCardDescription>

            {/* ARRAY DE OFERTAS */}
            <OffersList offers={option.offers} />

            {/* PAYMENT METHODS */}
            {hasPaymentMethods && confirmation && (
              <PaymentMethods
                options={radioOptions}
                onClickInOption={handleRadioItem}
                isLoading={isLoadingCreditCard}
              />
            )}
          </S.ContainerOptionsCard>
        ))}
      </S.ContainerOptions>

      {hidePanel && (
        <S.ContainerActions>
          <S.ContainerOptions2>
            <S.CTAInformation>
              {`Essa oferta é válida até dia ${format(
                new Date(expires_at),
                'dd/MM/yyyy',
                {
                  locale: pt,
                },
              )}`}
            </S.CTAInformation>

            <PanelButton
              disabled={isLoadingCreditCard || !verifyRadioSelection}
              onClick={() => setPayment()}
              confirmationAction
              isLoading={isLoadingPayment}
            >
              Pagar agora
            </PanelButton>
          </S.ContainerOptions2>
        </S.ContainerActions>
      )}

      {enableActions && !hidePanel && (
        <S.ContainerActions>
          <ContainerOptions2>
            {!confirmation && actionsWith?.buttonWithInfo?.showText && (
              <S.CTAInformation>
                {actionsWith.buttonWithInfo.text}
              </S.CTAInformation>
            )}

            {actionsWith?.button && (
              <PanelButton
                onClick={confirmOrder}
                confirmationAction={confirmation}
                isLoading={isLoading}
              >
                Quero essa
              </PanelButton>
            )}

            {actionsWith?.terms && (
              <S.ContainerTerms>
                {!confirmation && (
                  <PanelButton
                    onClick={confirmOrder}
                    confirmationAction={confirmation}
                    isLoading={isLoading}
                  >
                    Quero essa
                  </PanelButton>
                )}

                {moreOffers && moreOffers.length > 1 && (
                  <PanelButton
                    variant="secondary"
                    onClick={() => {
                      trackEvent('select_item', {
                        item_name: 'mais_ofertas',
                        borrower_id: borrowerId,
                      });

                      openModal({
                        title: 'mais ofertas',
                        content: moreOffers && (
                          <ModalMoreOffers
                            offers={moreOffers}
                            selectedOffer={selectedOffer}
                          />
                        ),
                        size: 420,
                      });
                    }}
                  >
                    mostrar mais ofertas
                  </PanelButton>
                )}
              </S.ContainerTerms>
            )}
          </ContainerOptions2>
        </S.ContainerActions>
      )}
    </S.Wrapper>
  );
}
