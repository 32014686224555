import { isAfter } from 'date-fns';
import { isEqual } from 'date-fns/esm';

import { extractPartsOfDate } from '../helpers/extract-parts-of-date-by-string';

const oneYearInMilliseconds = 31557600000;
export const isInvalidDate = (rawDate: string) => {
  const MONTH_OF_YEAR = 12;
  const date = extractPartsOfDate(rawDate);

  if (!date) {
    return true;
  }

  const maxAge = 115;
  const { day, month, year } = date;

  const daysInMonth = new Date(Number(year), Number(month), 0).getDate();
  const isGreaterThanDayOfMonth = Number(day) > daysInMonth;
  const isGreaterMonthThan = Number(month) > MONTH_OF_YEAR;
  const inputtedDate = new Date(Number(year), Number(month) - 1, Number(day));
  const currentDate = new Date();

  const itsLive =
    isAfter(inputtedDate, currentDate) || isEqual(inputtedDate, currentDate);
  const futureYear = Number(year) > new Date().getFullYear();
  const wrongYear = year.length !== 4;
  const isInvalidYear = wrongYear || itsLive || futureYear;

  if (isGreaterThanDayOfMonth || isGreaterMonthThan || isInvalidYear) {
    return true;
  }

  const timestampInputted = new Date(`${month}/${day}/${year}`).getTime();

  const age = Math.floor(
    (new Date().getTime() - timestampInputted) / oneYearInMilliseconds,
  );

  const ageNotAllowed = !(age <= maxAge);

  if (ageNotAllowed) {
    return true;
  }

  return false;
};
