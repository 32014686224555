import { Group } from './style';

interface ProfileData {
  title: string;
  value: string;
}

export const ProfileLineGroupDashboard = ({ title, value }: ProfileData) => {
  return (
    <Group>
      <span>{title}</span>
      <p>{value}</p>
    </Group>
  );
};
