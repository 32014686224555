import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

const colors = getThemeColors();

export const Container = styled.div`
  border-radius: 12px;
  height: fit-content;
  width: 100%;

  @media ${device.sm} {
    box-shadow: 0 0 0.5rem #e8e8e8;
    padding: 2rem;
    max-width: 40rem;
  }
`;

export const ItemContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.greyborders};
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.1rem;
  padding: 1rem;

  p {
    color: ${colors.black};
    font-size: 0.875rem;
    font-weight: 600;
  }
  span {
    color: ${colors.noverde};
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

export const Document = styled.div`
  padding-top: 20px;
  p {
    margin: 1rem 0;
  }
`;
