import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDBTracking } from 'contexts/DBTrackingContext';
import { useRouter } from 'hooks/useRouter';
import { useTracking } from 'hooks/useTracking';
import RenegotiationService from 'services/Offers/Renegotiation';
import { storageKeys } from 'store/storage-keys';
import { RenegotiationTypes } from 'types/Renegotiation/Renegotiation';
import { toCurrency, toCurrencyBrl } from 'utils/masks';
import { storage } from 'utils/storage';

import {
  RenegotiationPanel,
  RenegotiationPanelProps,
} from '../RenegotiationPanel';

export function RenegotiationPayNow() {
  const [confirmation] = useState(false);
  const { location } = useHistory<RenegotiationTypes>();
  const [offer] = useState<RenegotiationTypes>(
    location.state || ({} as RenegotiationTypes),
  );
  const [isLoading, setIsLoading] = useState(false);

  const { history } = useRouter();
  const { setTrackingEvent } = useDBTracking();
  const { trackEvent } = useTracking();
  const borrowerId = storage.getItem(storageKeys.borrowerId);

  useEffect(() => {
    document.title = 'Renegociacao - Detalhe';
  }, []);

  const [panelData] = useState({
    headerOptions: {
      description: 'sua dívida atual é',
      value: toCurrencyBrl(offer?.offers?.debt_amount.toFixed(2) || 0),
      containerValueType: 'current_debt',
    },
    panelItems: [
      {
        title: 'Pague tudo agora!',
        description: (
          <>
            Você paga o valor total da sua dívida e recebe{' '}
            <strong>
              {offer?.offers?.settlement?.percent_discount.toFixed(2)}% de
              desconto.
            </strong>
          </>
        ),
        offers: [
          {
            description: 'VALOR DO DESCONTO',
            label: toCurrencyBrl(
              offer?.offers?.settlement?.discount_value.toFixed(2) || 0,
            ),
          },
          {
            description: 'VALOR TOTAL A PAGAR',
            value: `${toCurrency(offer?.offers?.settlement?.value.toFixed(2))}`,
          },
        ],
      },
    ],
  } as RenegotiationPanelProps);

  const confirmOffer = async () => {
    setIsLoading(true);

    try {
      trackEvent('select_item', {
        item_name: 'quero_essa',
        borrower_id: borrowerId,
      });

      setTrackingEvent({
        category: 'negotiation',
        action: 'offer_accepted',
        details: {
          type: 'settlement',
          offfer_id: offer.offers?.uuid,
        },
        loanUuid: true,
      });

      const { data } = await RenegotiationService.AcceptOffer({
        type: 'settlement',
        period: 1,
        debt_amount: offer?.offers?.debt_amount || 0,
        uuidOffer: offer?.offers?.uuid || '',
      });

      history.push('/dashboard/renegociacao/pagamento', {
        uuid: data.uuid,
        type: 'settlement',
        value: offer?.offers?.settlement?.value,
        expires_at: offer?.offers?.expires_at,
      });
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <RenegotiationPanel
      headerOptions={panelData.headerOptions}
      panelItems={panelData.panelItems}
      confirmation={confirmation}
      confirmOrder={confirmOffer}
      hasPaymentMethods={confirmation}
      actionsWith={{
        button: true,
      }}
      expires_at={offer?.offers?.expires_at}
      offerUuid={offer?.offers?.uuid}
      isLoading={isLoading}
    />
  );
}
