import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';

import { PartnerOffetLoansResponseData } from './types';

class PartnerOfferLoansRemoteService extends HttpClient {
  public constructor() {
    super(Services.Loans.remote.v1);
  }

  public requestPartnerOfferLoans(token: string) {
    return this.instance.get<PartnerOffetLoansResponseData>(
      '/loans/partner-offer',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}

export default new PartnerOfferLoansRemoteService();
