import { ReactNode, useEffect, useRef, useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import useWindowDimensions from 'hooks/useWindowDimensions';

import * as S from './style';

interface Props {
  fixedContentTop?: ReactNode;
  fixedContentBottom?: ReactNode;
  baseColor?: 'white' | 'galaxy' | 'noverde';
  children: ReactNode;
  maxHeight?: boolean;
  maxWidth?: string;
  padding?: string;
}

export function WithFixedContent({
  fixedContentTop,
  fixedContentBottom,
  baseColor,
  children,
  maxHeight = false,
  maxWidth,
  padding = '0px 16px',
}: Props) {
  const ContainerRef = useRef<any>(null);
  const fixedTopRef = useRef<any>(null);
  const fixedBottomRef = useRef<any>(null);

  const { hidelg } = useWindowDimensions();

  const [finishedScroll, setFinishedScroll] = useState(false);
  const [fixedContentHeightTop, setFixedContentHeightTop] = useState(0);
  const [fixedContentHeightBottom, setFixedContentHeightBottom] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const handleContentScroll = ({ clientHeight, scrollTop, scrollHeight }) => {
    const hasArrivedBottom = scrollHeight - scrollTop <= clientHeight;

    setFinishedScroll(hasArrivedBottom);
  };

  const handleScroll = ({ target }) => {
    handleContentScroll(target);
  };

  const setContentScroll = (data) => {
    handleContentScroll({
      clientHeight: Array(...data)[0].clientHeight,
      scrollTop: Array(...data)[0].scrollTop,
      scrollHeight: Array(...data)[0].scrollHeight,
    });
  };

  const handleScrollListener = (event: any) => {
    setContentScroll(event.target.children);
  };

  useEffect(() => {
    const fixedTopHeight = fixedTopRef?.current?.clientHeight ?? 0;
    const fixedBottomHeight = fixedBottomRef?.current?.clientHeight ?? 0;

    setFixedContentHeightTop(fixedTopHeight);
    setFixedContentHeightBottom(fixedBottomHeight);

    setTimeout(() => {
      if (hidelg) {
        if (ContainerRef.current) {
          handleContentScroll(ContainerRef.current);
        }
      } else {
        setContentScroll(document.getElementsByTagName('html'));

        window.addEventListener('scroll', handleScrollListener);
      }
    }, 100);

    return () => window.removeEventListener('scroll', handleScrollListener);
  }, []);

  useEffect(() => {
    if (containerHeight === 0) {
      setContainerHeight(window.innerHeight);
    }
  }, []);

  return (
    <S.WithFixedContentContainer
      onScroll={handleScroll}
      ref={ContainerRef}
      fixedContentHeightTop={fixedContentHeightTop}
      maxHeight={maxHeight}
      containerHeight={containerHeight}
    >
      {fixedContentTop && (
        <S.FixedContentTop ref={fixedTopRef}>
          {fixedContentTop}
        </S.FixedContentTop>
      )}

      <S.WithFixedHolder maxWidth={maxWidth} padding={padding}>
        <S.ScrollableContent
          fixedContentHeight={fixedContentHeightTop + fixedContentHeightBottom}
          paddingTop={!fixedContentTop}
          paddingBottom={!fixedContentBottom}
        >
          {children}
        </S.ScrollableContent>

        {fixedContentBottom && (
          <S.FixedContentBottom
            ref={fixedBottomRef}
            baseColor={baseColor}
            maxWidth={maxWidth}
          >
            <AnimatePresence>
              {!finishedScroll && (
                <S.FadeContent
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              )}
            </AnimatePresence>

            {fixedContentBottom}
          </S.FixedContentBottom>
        )}
      </S.WithFixedHolder>
    </S.WithFixedContentContainer>
  );
}

WithFixedContent.defaultProps = {
  fixedContentTop: null,
  fixedContentBottom: null,
  baseColor: 'white',
};
