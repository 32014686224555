import { ButtonLoader } from 'components/ButtonLoader';
import { darken } from 'polished';
import styled, { css } from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';
import { device } from 'styles/medias';

import { PanelButtonProps } from '.';

const renderVariant: Record<keyof PanelButtonProps['variant'], unknown> = {
  outlined: css`
    background-color: transparent;
    border: 1px solid ${colorsV2['brand-primary']};
    color: ${colorsV2['brand-primary']};
  `,
};

export const Button = styled(ButtonLoader)<
  Pick<PanelButtonProps, 'size' | 'variant' | 'confirmationAction'>
>`
  ${({ size, variant, confirmationAction }) => css`
    width: 100%;
    ${!!variant && renderVariant[variant]}

    :disabled {
      background-color: ${colorsV2['action-conversion-disabled']};
      cursor: not-allowed !important;
      color: ${colorsV2['neutral-ultra-light']};
    }

    @media ${device.md} {
      width: ${size || '23rem'};
    }

    ${!!confirmationAction &&
    `background: ${colorsV2['action-conversion-static']};
    &:hover {
      background: ${darken(0.025, colorsV2['action-conversion-static'])};
    }
    `};
  `}
`;
