const bar = '/';
const hyphen = '-';

const validParts = ({
  day,
  month,
  year,
}: {
  day: string;
  month: string;
  year: string;
}) =>
  !(day && isLength(day)) ||
  !(month && isLength(month)) ||
  !(year && isLength(year, 4));

const tryConvertDate = (value: string | number) => {
  const date = new Date(value);
  const decimalRounding = -2;

  if (!Number.isNaN(date.getTime())) {
    const day = `0${date.getUTCDate()}`.slice(decimalRounding);
    const month = `0${date.getUTCMonth() + 1}`.slice(decimalRounding);
    const year = date.getUTCFullYear().toString();
    const weekDay = date.getUTCDay().toString();

    if (validParts({ day, month, year })) {
      return false;
    }

    return {
      day,
      month,
      year,
      weekDay,
    };
  }

  return false;
};

const isLength = (value: string, length = 2) => value.length === length;

export const extractPartsOfDate = (value = '') => {
  const [prefix] = [bar, hyphen].filter((_prefix) => value?.match(_prefix));

  if (!prefix) {
    return tryConvertDate(value);
  }

  const [day, month, year] = value.split(prefix);

  return tryConvertDate(
    Date.parse(
      `${prefix === bar ? year : day}-${month}-${prefix === bar ? day : year}`,
    ),
  );
};
