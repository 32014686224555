import styled from 'styled-components';
import { device } from 'styles/medias';

export const ContainerOptionsItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.sm} {
    width: fit-content;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 28px;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    font-weight: 700;
  }
`;
