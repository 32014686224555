/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';

import { ButtonLoader } from 'components/ButtonLoader';
import { FieldWithLabel } from 'components/FieldWithLabel';
import { MessageCallout } from 'components/MessageCallout';
import { useBorrower } from 'hooks/useBorrower';
import useIugu from 'hooks/useIugu';
import { useRouter } from 'hooks/useRouter';
import { ScreenLoader } from 'pages/Dashboard/components/ScreenLoader';
import { mask } from 'remask';
import FetchAddressService from 'services/Address/FetchAddressService';
import PaymtService from 'services/Paymt/PaymtService';
import { MessageHolder } from 'styles/miscellany';
import { isEmpty } from 'utils/helpers/is-empty';
import { later } from 'utils/helpers/later';
import { replaceAll } from 'utils/helpers/replaceAll';
import { masks } from 'utils/masks';
import { formValidations } from 'utils/validators/form-validations';
import { useStore } from 'zstore';

import { Box, InputGrid, InputGridItem } from './style';

export interface FormWalletProps {
  numeroCartao: string;
  titularCartao: string;
  vencimentoCartao: string;
  ccvCartao: number;
  cep: string;
  endereco: string;
  numero: string;
  complemento?: string;
  bairro: string;
  cidade: string;
  estado: string;
}

interface ResponseType {
  loading: boolean;
  success: boolean | any;
  error: boolean;
}

export function FormWalletDashboard() {
  const { history, location }: any = useRouter();
  const params: any = useParams();
  const { borrowerData } = useBorrower();
  const userInfo = borrowerData?.data?.attributes;
  const methods = useForm<FormWalletProps>();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const {
    numeroCartao,
    vencimentoCartao,
    ccvCartao,
    name,
    cep,
    endereco,
    numero,
    bairro,
    cidade,
    estado,
  } = formValidations();
  const { handleSubmit, setValue } = methods;
  const [view, setView] = useState(false);
  const [viewAddress, setViewAddress] = useState(false);
  const [isCreditCardLoading, setCreditCardLoading] = useState(true);
  const [isCreditCardError, setCreditCardError] = useState(false);
  const isViewMode = view || buttonLoading;
  const viewMode = isViewMode
    ? { readOnly: true, style: { cursor: 'not-allowed' } }
    : {};
  const [iuguInfos, setIuguInfos] = useState<any>({});
  const { setCreditCardId } = useStore();
  const { Iugu, iuguStatus } = useIugu(params.id);
  const [zipcodeStatus, setZipcodeStatus] = useState<ResponseType>({
    loading: false,
    error: false,
    success: false,
  });
  const viewModeZipcode =
    isViewMode || zipcodeStatus.loading
      ? { readOnly: true, style: { cursor: 'not-allowed' } }
      : {};

  const viewModeAddress = (field) =>
    zipcodeStatus.success[field] || viewAddress
      ? {
          readOnly: true,
          style: { cursor: 'not-allowed' },
        }
      : viewMode;

  const loadingZipcode = (element) =>
    zipcodeStatus.loading ? <Skeleton height={52} /> : element;

  async function onSubmit(data: FormWalletProps) {
    await handleCreateWallet(data);
  }

  const creditCardPoll = async (id) => {
    let count = 0;

    while (count < 11) {
      await later(3000);

      try {
        const { data } = await PaymtService.getCreditCard(id);

        if (data?.state === 'failed') {
          if (data?.content?.error?.message.includes('zip_code')) {
            throw Error('invalid_zip_code');
          } else {
            throw Error('creditcard_failed');
          }
        }

        if (data?.state === 'confirmed') {
          count = 11;

          setCreditCardId(id);
        } else {
          count += 1;
        }
      } catch (err: any) {
        count = 11;

        if (['invalid_zip_code', 'creditcard_failed'].includes(err.message)) {
          throw Error(err.message);
        } else {
          setCreditCardError(true);
        }
      }
    }
  };

  async function handleCreateWallet(data: FormWalletProps) {
    setErrorMessage(null);
    setCreditCardError(false);

    try {
      setButtonLoading(true);
      const expiratinCard = data.vencimentoCartao.split('/');
      const expirationMonth = expiratinCard[0];
      const expirationYear = expiratinCard[1];

      const creditCardData = {
        cardNumber: data.numeroCartao.replace(/\s+/g, ''),
        expirationMonth,
        expirationYear,
        firstName: data.titularCartao.split(' ')[0],
        lastName: data.titularCartao
          .split(' ')
          .filter((item, index) => (index > 0 ? item : ''))
          .join(' '),
        verificationValue: String(data.ccvCartao),
      };

      const creditCardForm = Iugu.createCreditCard(creditCardData);

      if (!creditCardForm.valid()) {
        throw Error('invalid_credit_card');
      }

      const paymentToken: any = await Iugu.createPaymentToken(creditCardForm);

      const creditCardResponse = await PaymtService.creatCreditCard({
        creditCardToken: paymentToken.id,
        city: data.cidade,
        complement: data.complemento,
        district: data.bairro,
        number: data.numero,
        state: data.estado,
        street: data.endereco,
        zipcode: data.cep.replace(/[^\w\s]/gi, ''),
      });

      await creditCardPoll(creditCardResponse.data.id);

      if (location?.state?.origin.includes('payment-methods')) {
        history.replace({
          pathname: '/dashboard/checkout-summary',
          state: location?.state?.state,
        });
      } else {
        history.push(location?.state.origin, {
          ...location?.state?.offer,
          creditCardId: creditCardResponse.data.id,
        });
      }
      setButtonLoading(false);
    } catch (error: any) {
      setButtonLoading(false);
      const errorMessages = {
        invalid_credit_card:
          'Informações do cartão de credito inválidas, tente novamente!',
        invalid_zip_code: 'CEP inválido, tente novamente!',
      };

      setErrorMessage(
        errorMessages[error.message] ||
          'Não foi possível criar o cartão, tente novamente!',
      );
    }
  }

  const requestCreditCard = useCallback(async (id) => {
    setCreditCardLoading(true);

    try {
      const { data } = await PaymtService.getCreditCard(id);

      setIuguInfos(data.content.iugu_data);
    } catch {
      setCreditCardError(true);
    } finally {
      setCreditCardLoading(false);
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      setView(true);

      requestCreditCard(params.id);
    } else {
      setCreditCardLoading(false);
    }
  }, []);

  useEffect(() => {
    const { customer_infos, payment_method_infos } = iuguInfos;

    if (params.id) {
      setValue(
        'numeroCartao',

        replaceAll(payment_method_infos?.data?.display_number, '-', ' '),
      );
      setValue('titularCartao', payment_method_infos?.data?.holder_name);
      setValue(
        'vencimentoCartao',
        `${
          payment_method_infos?.data?.month > 9
            ? payment_method_infos?.data?.month
            : `0${payment_method_infos?.data?.month}`
        }/${payment_method_infos?.data?.year}`,
      );
      setValue(
        'cep',
        customer_infos?.zip_code &&
          mask(customer_infos?.zip_code, masks('zipCode')),
      );
      setValue('endereco', customer_infos?.street);
      setValue('numero', customer_infos?.number);
      setValue('complemento', customer_infos?.complement);
      setValue('bairro', customer_infos?.district);
      setValue('cidade', customer_infos?.city);
      setValue('estado', customer_infos?.state);
    } else {
      setValue(
        'cep',
        userInfo?.['address-zipcode'] &&
          mask(userInfo?.['address-zipcode'], masks('zipCode')),
      );
      setValue('endereco', userInfo?.['address-address']);
      setValue('numero', userInfo?.['address-number']);
      setValue('complemento', userInfo?.['address-complement']);
      setValue('bairro', userInfo?.['address-neighborhood']);
      setValue('cidade', userInfo?.['address-city']);
      setValue('estado', userInfo?.['address-state']);

      setViewAddress(true);
    }
  }, [isCreditCardLoading, iuguStatus.loading]);

  const handleGetZipcode = async (e) => {
    const zipcode = e.currentTarget.value.replace('-', '');

    if (zipcode.length === 8) {
      try {
        setZipcodeStatus({ loading: true, success: false, error: false });
        setViewAddress(false);

        setValue('numero', '');
        setValue('complemento', '');

        const { data } = await FetchAddressService.getAddress({ zipcode });

        setZipcodeStatus({ loading: false, success: data, error: false });

        if (!isEmpty(data)) {
          setValue('endereco', data.street);
          setValue('bairro', data.district);
          setValue('cidade', data.city);
          setValue('estado', data.state);
        }
      } catch {
        setZipcodeStatus({ loading: false, success: false, error: true });

        setValue('endereco', '');
        setValue('bairro', '');
        setValue('cidade', '');
        setValue('estado', '');
      }
    }
  };

  if (isCreditCardLoading || iuguStatus.loading) return <ScreenLoader />;

  if (iuguStatus.error)
    return (
      <MessageHolder>
        <MessageCallout
          title="Houve um erro"
          message="Não foi possível carregar os dados para cadastrar o seu cartão"
          type="error"
        />
      </MessageHolder>
    );

  if (isCreditCardError)
    return (
      <MessageHolder>
        <MessageCallout
          title="Houve um erro"
          message="Não foi possível carregar os dados do seu cartão"
          type="error"
        />
      </MessageHolder>
    );

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputGrid>
            <InputGridItem>
              <p>Dados do cartão</p>
              <FieldWithLabel
                name="numeroCartao"
                label="Número do cartão *"
                validation={numeroCartao}
                pattern={masks('creditCardNumber')}
                maxLength={19}
                {...viewMode}
              />
              <FieldWithLabel
                name="titularCartao"
                label="Nome do titular*"
                type="text"
                onInput={(e) => {
                  e.currentTarget.value = e.currentTarget.value.toUpperCase();
                }}
                validation={name}
                {...viewMode}
              />
              <FieldWithLabel
                name="vencimentoCartao"
                label="Data de vencimento (mm/aaaa)*"
                validation={vencimentoCartao}
                pattern={masks('creditCardDate')}
                {...viewMode}
              />
              {!view && (
                <FieldWithLabel
                  name="ccvCartao"
                  label="CCV *"
                  pattern={masks('creditCardCCV')}
                  validation={ccvCartao}
                  maxLength={3}
                  {...viewMode}
                />
              )}
            </InputGridItem>
            <InputGridItem>
              <p>Endereço de cobrança</p>
              <FieldWithLabel
                name="cep"
                label="CEP *"
                pattern={masks('zipCode')}
                validation={cep}
                onInput={handleGetZipcode}
                {...viewModeZipcode}
              />

              {loadingZipcode(
                <FieldWithLabel
                  name="endereco"
                  label="Endereço*"
                  validation={endereco}
                  {...viewModeAddress('street')}
                />,
              )}

              <FieldWithLabel
                name="numero"
                label="Número *"
                validation={numero}
                {...viewMode}
              />
              <FieldWithLabel
                name="complemento"
                label="Complemento"
                {...viewMode}
              />

              {loadingZipcode(
                <FieldWithLabel
                  name="bairro"
                  label="Bairro*"
                  validation={bairro}
                  {...viewModeAddress('district')}
                />,
              )}

              {loadingZipcode(
                <FieldWithLabel
                  name="cidade"
                  label="Cidade *"
                  validation={cidade}
                  {...viewModeAddress('city')}
                />,
              )}

              {loadingZipcode(
                <FieldWithLabel
                  name="estado"
                  label="Estado *"
                  validation={estado}
                  {...viewModeAddress('state')}
                />,
              )}
            </InputGridItem>
          </InputGrid>
          {errorMessage && (
            <MessageHolder margin="0 auto 1rem">
              <MessageCallout
                title="Houve um erro"
                message={errorMessage}
                type="error"
              />
            </MessageHolder>
          )}

          {!view && (
            <ButtonLoader type="submit" isLoading={buttonLoading}>
              Cadastrar
            </ButtonLoader>
          )}
        </form>
      </FormProvider>
    </Box>
  );
}
