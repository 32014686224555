import BannerDotz from 'assets/img/SideBanner/dotz/documents.jpg';
import Banner from 'assets/img/SideBanner/man-documents.jpg';
import { SideBanner } from 'components/SideBanner';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getThemeAssets } from 'styles/helpers/getThemeAssets';
import { useStore } from 'zstore';

export function SideBannerIdentity() {
  const assets = getThemeAssets({
    default: { banner: Banner },
    dotz: { banner: BannerDotz },
  });

  const { loanDetails } = useStore();
  const isPendencies = loanDetails?.status === 'pending';
  const { hidelg } = useWindowDimensions();

  const title = isPendencies ? (
    <>
      Reenvio de <b>Documentos</b>
    </>
  ) : (
    <>
      Sua <b>identidade</b>
    </>
  );

  return hidelg ? (
    <SideBanner banner={String(assets?.banner)} title={title} />
  ) : null;
}
