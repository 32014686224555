import { storageKeys } from 'store/storage-keys';
import { ExpiredStorage } from 'utils/expiredStorage';

type ThemeType = 'default' | 'dotz';
type RecordAssets<P> = Partial<
  Record<ThemeType, Partial<Record<keyof P, string | any>>>
>;

export function getThemeAssets<P>(
  assets: RecordAssets<P>,
): RecordAssets<P>[keyof RecordAssets<P>] {
  const themeAssets: RecordAssets<P> = {
    default: assets.default,
    dotz: assets.dotz ? { ...assets.dotz } : {},
  };

  const storagedTheme = ExpiredStorage.getItemWithExpiry('multitheme');
  if (storagedTheme !== null) return themeAssets[storagedTheme];
  localStorage.removeItem(storageKeys.multitheme);
  return { ...themeAssets.default };
}
