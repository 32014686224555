import styled from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  width: 100%;

  @media ${device.sm} {
    max-width: 15rem;
  }

  svg {
    color: ${colors.greyborders};
    height: 4rem;
    width: 4rem;
  }
`;

export const Title = styled.p`
  color: ${colors.black};
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
`;

export const Description = styled.p`
  color: ${colors.text};
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
`;
