import { extractPartsOfDate } from '../helpers/extract-parts-of-date-by-string';

type FormatDate = {
  format:
    | 'DD/MM/YYYY'
    | 'YYYY-MM-DD'
    | 'DD de Mon de YYYY'
    | 'DD MMM YYYY - EEEE';
};

const months: Record<string, Record<'full' | 'short', string>> = {
  '01': {
    full: 'janeiro',
    short: 'jan',
  },
  '02': {
    full: 'fevereiro',
    short: 'fev',
  },
  '03': {
    full: 'março',
    short: 'mar',
  },
  '04': {
    full: 'abril',
    short: 'abr',
  },
  '05': {
    full: 'maio',
    short: 'maio',
  },
  '06': {
    full: 'junho',
    short: 'jun',
  },
  '07': {
    full: 'julho',
    short: 'jul',
  },
  '08': {
    full: 'agosto',
    short: 'ago',
  },
  '09': {
    full: 'setembro',
    short: 'set',
  },
  '10': {
    full: 'outubro',
    short: 'out',
  },
  '11': {
    full: 'novembro',
    short: 'nov',
  },
  '12': {
    full: 'dezembro',
    short: 'dez',
  },
};

const weekDays: Record<string, Record<'full' | 'short', string>> = {
  '0': {
    full: 'domingo',
    short: 'domingo',
  },
  '1': {
    full: 'segunda-feira',
    short: 'segunda',
  },
  '2': {
    full: 'terça-feira',
    short: 'terça',
  },
  '3': {
    full: 'quarta-feira',
    short: 'quarta',
  },
  '4': {
    full: 'quinta-feira',
    short: 'quinta',
  },
  '5': {
    full: 'sexta-feira',
    short: 'sexta',
  },
  '6': {
    full: 'sábado',
    short: 'sábado',
  },
};

export const formatDate = (
  rawDate: string,
  { format = 'YYYY-MM-DD' }: FormatDate,
) => {
  const date = extractPartsOfDate(rawDate);

  if (!date) {
    return '';
  }

  const { day, month, year, weekDay } = date;

  return format
    .replace('Mon', months[month].full.toUpperCase())
    .replace('MMM', months[month].short.toUpperCase())
    .replace('DD', day)
    .replace('EEEE', weekDays[weekDay].full)
    .replace('MM', month)
    .replace('YYYY', year);
};
