import { useRouter } from 'hooks/useRouter';
import { WalletsDashboard } from 'pages/Dashboard/components/WalletsDashboard';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

export const Wallets = () => {
  const { history } = useRouter();

  function handleGoBack() {
    history.replace('/dashboard');
  }

  return (
    <DashBoardTemplate headerTitle="Carteira" handleReturn={handleGoBack}>
      <WalletsDashboard />
    </DashBoardTemplate>
  );
};
