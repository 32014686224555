import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import { RenegotiationPayment } from './components/RenegotiationPayment';

export function PageRenegotiationPayment() {
  return (
    <DashBoardTemplate headerTitle="Renegociação" contentDirection="baseline">
      <RenegotiationPayment />
    </DashBoardTemplate>
  );
}
