import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import TrackEventsService from 'services/Tracker/TrackEventsService';
import { storageKeys } from 'store/storage-keys';
import { UtmDetailsType } from 'types/Tracker/TrackingType';
import { isEmpty } from 'utils/helpers/is-empty';
import { storage } from 'utils/storage';
import { useStore } from 'zstore';

interface DBTrackingData {
  utmData: UtmDetailsType;
  setTrackingEvent: (data) => Promise<void>;
  setUtmTrackingData: (data) => void;
  clearUtmData: () => void;
}

interface DBTrackingProviderTypes {
  children?: React.ReactNode;
}

export const TrackingContext = createContext({} as DBTrackingData);

export const DBTrackingProvider = ({ children }: DBTrackingProviderTypes) => {
  const [utmData, setUtmData] = useState<UtmDetailsType>({} as UtmDetailsType);
  const [storedData, setStoredData] = useLocalStorage(
    storageKeys.utmDetails,
    utmData,
  );
  const { loanDetails } = useStore();

  const setTrackingEvent = async (data) => {
    const utmBody = data.utmData ? utmData : {};

    const mergedDetails = {
      ...utmBody,
      ...data.requirements,
      ...data.details,
    };

    await TrackEventsService.setEvent({
      category: data.category,
      action: data.action,
      loan_uuid:
        typeof data.loanUuid === 'boolean'
          ? loanDetails.uid || ''
          : data.loanUuid,
      device_id: storage.getItem(storageKeys.userUid) || '',
      ...(isEmpty(mergedDetails) ? mergedDetails : { details: mergedDetails }),
    });
  };

  const setUtmTrackingData = (data) => setStoredData(data);
  const clearUtmData = () => storage.removeItem(storageKeys.utmDetails);

  useEffect(() => {
    setUtmData(storedData || null);
  }, [storedData]);

  return (
    <TrackingContext.Provider
      value={{ utmData, setTrackingEvent, setUtmTrackingData, clearUtmData }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export const useDBTracking = () => {
  const ctx = useContext(TrackingContext);

  return useMemo(() => ctx, [ctx]);
};

DBTrackingProvider.defaultProps = {
  children: null,
};
