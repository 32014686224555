import { FaCreditCard } from 'react-icons/fa';

import { Button } from 'components/Button';
import { useRouter } from 'hooks/useRouter';

import { Container, Description, Title } from './style';

export const EmptyWalletDashboard = () => {
  const { history, location } = useRouter();

  function handleWalletRegister() {
    const state = {
      origin: location.pathname,
    };
    history.push({
      pathname: '/dashboard/wallet-register',
      state,
    });
  }

  return (
    <Container>
      <Title>Ainda não há cartões de crédito cadastrados</Title>
      <FaCreditCard />
      <Button className="primary" onClick={handleWalletRegister}>
        Cadastrar meu cartão
      </Button>
      <Description>
        Cadastre seu cartão de crédito e comece a pagar suas parcelas agora
        mesmo!
      </Description>
    </Container>
  );
};
