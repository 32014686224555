import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { ButtonLoader } from 'components/ButtonLoader';
import { FieldWithLabel } from 'components/FieldWithLabel';
import { MessageCallout } from 'components/MessageCallout';
import { Navigation } from 'components/Navigation';
import { WithFixedContent } from 'components/WithFixedContent';
import { unMask } from 'remask';
import UpdatePhoneService from 'services/Authorizer/UpdatePhoneService';
import { storageKeys } from 'store/storage-keys';
import { masks } from 'utils/masks';
import { storage } from 'utils/storage';
import { formValidations } from 'utils/validators/form-validations';

import { PhonePresentation, InfoContainer } from './style';

export function UpdatePhoneForm() {
  const methods = useForm();
  const { handleSubmit } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const { cellphone } = formValidations();
  const [message, setMessage] = useState(<></>);

  const { data: borrowerData } = storage.getItem(storageKeys.borrowerData);

  const history = useHistory();

  async function onSubmit(form) {
    setIsLoading(true);

    try {
      await UpdatePhoneService.changePhone(unMask(form.mobileNumber));

      history.push({
        pathname: '/confirm-code',
        state: {
          mobileNumber: form.mobileNumber,
          document: borrowerData?.document,
          type: 'update_phone',
        },
      });
    } catch (error) {
      setMessage(
        <MessageCallout
          type="error"
          title="Falha ao adicionar telefone"
          message="Não foi possível realizar o cadastro do telefone. Por favor tente novamente mais tarde."
        />,
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <WithFixedContent
      fixedContentTop={<Navigation />}
      fixedContentBottom={
        <ButtonLoader isLoading={isLoading} form="phoneForm">
          Avançar
        </ButtonLoader>
      }
    >
      <InfoContainer>
        <PhonePresentation
          headline={
            <>
              Insira <b>seu celular</b>
            </>
          }
          subheading="Insira seu número de celular principal, ele é importante para mantermos suas informações atualizadas"
        />

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} id="phoneForm">
            <FieldWithLabel
              name="mobileNumber"
              type="tel"
              label="Telefone com ddd*"
              pattern={masks('phone')}
              validation={cellphone}
            />
          </form>
        </FormProvider>
      </InfoContainer>

      {message}
    </WithFixedContent>
  );
}
