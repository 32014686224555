import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

type Props = {
  open: boolean;
};

export const Background = styled.div<Props>`
  ${({ open }) => css`
    align-items: center;
    display: flex;
    justify-content: start;
    height: 100vh;
    position: fixed;
    width: ${open ? '100%' : '0'};
    top: 0;
    left: 0;
    z-index: 10;
  `}
`;

export const MenuContainer = styled.nav<Props>`
  ${({ open }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${colors.white};
    transform: ${open ? 'translateX(0)' : 'translateX(-100%)'};
    height: 100vh;
    text-align: left;
    position: relative;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    width: 22.5rem;
    z-index: 1;
  `}
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  padding: 0 2rem 0 2rem;
`;

export const MenuItems = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 2.2rem;
  list-style: none;
  width: 100%;
`;

export const Overlay = styled.div<Props>`
  ${({ open }) => css`
    height: 100vh;
    background: ${open ? '#00000025' : 'transparent'};
    transition: background ${open ? '0.5s ease-in-out' : '0.5 ease-out'};
    width: ${open ? '100%' : '0'};
    top: 0;
    left: 0;
    z-index: 1;
  `}
`;
