import { useNavigation } from 'contexts/NavigationContext';

import { TopLoaderBar, TopLoaderContainer } from './style';

export const TopLoader = () => {
  const { isLoadingNavigation } = useNavigation();

  return isLoadingNavigation ? (
    <TopLoaderContainer>
      <TopLoaderBar />
    </TopLoaderContainer>
  ) : null;
};
