/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */

import { useLocation } from 'react-router-dom';

import { useRouter } from 'hooks/useRouter';
import { ScreenLoader } from 'pages/Dashboard/components/ScreenLoader';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';

export function DotzRedirect() {
  const { history } = useRouter();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const setToken = () => {
    const button = document?.getElementById('button') as HTMLButtonElement;

    storage.setItem(storageKeys.accessToken, button.value);
    storage.setItem(storageKeys.hideDownloadButton, 'true');
    if (query.get('route')) {
      history.push(`/${query.get('route')}`);
    } else {
      history.push('/dashboard');
    }
  };

  return (
    <div>
      <ScreenLoader />

      <button
        id="button"
        style={{ display: 'none' }}
        onClick={() => setToken()}
      />
    </div>
  );
}
