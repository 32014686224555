import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';

interface InfoContractType {
  paymentDate?: string;
  uid?: string;
}

class InfoContractService extends HttpClient {
  public constructor() {
    super(Services.InfoContract);
  }

  public getInfoContract = (body: InfoContractType) => {
    return this.instance.get(
      `/${storage.getItem(storageKeys.loanUid) || body?.uid}?payment_date=${
        body?.paymentDate || ''
      }`,
    );
  };
}

export default new InfoContractService();
