import { setState, StoreSlice } from 'zstore';

export interface ILivenessSlice {
  livenessStatus: string | null;
  setLivenessStatus: (data: string | null) => void;
}

export const livenessSlice: StoreSlice<ILivenessSlice> = () => ({
  livenessStatus: null,
  setLivenessStatus: (data: any) => {
    setState((state: ILivenessSlice) => {
      state.livenessStatus = data;
    });
  },
});
