import styled from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

const calloutColors = {
  success: { border: colors.noverde, background: `${colors.noverde}10` },
  error: { border: colors.errors, background: '#fff7f6' },
  alert: { border: colors.sunset, background: '#fffbf5' },
  info: { border: colors.info, background: '#EFF8FF' },
  info2: { border: colorsV2['support-soft-blue'], background: '#FAFCFD' },
  disclaimer: { border: colors.text, background: '#fdfdfd' },
  tip: { border: colors.info, background: '#eff8ff', text: colors.noverde },
  login: { border: '#0C0F25', background: '#0C0F25' },
};

export const MessageContainer = styled.div`
  width: 100%;
  border: 1px solid ${calloutColors.success.border};
  background: ${calloutColors.success.background};
  border-radius: 4px;
  padding: 1rem;
  &.error {
    border: 1px solid ${calloutColors.error.border};
    background: ${calloutColors.error.background};
  }
  &.alert {
    border: 1px solid ${calloutColors.alert.border};
    background: ${calloutColors.alert.background};
  }
  &.info {
    border: 1px solid ${calloutColors.info.border};
    background: ${calloutColors.info.background};
  }
  &.info2 {
    border: 1px solid ${calloutColors.info2.border};
    background: ${calloutColors.info2.background};
  }
  &.disclaimer {
    border: 1px solid ${calloutColors.disclaimer.border};
    background: ${calloutColors.disclaimer.background};
  }
  &.tip {
    border: 1px solid ${calloutColors.tip.border};
    background: ${calloutColors.tip.background};
  }
  &.login {
    border: 1px solid ${calloutColors.login.border};
    background: ${calloutColors.login.background};
    border-radius: 8px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const IconContainer = styled.i`
  font-size: 24px;
  color: ${calloutColors.success.border};
  &.error {
    color: ${calloutColors.error.border};
  }
  &.alert {
    color: ${calloutColors.alert.border};
  }
  &.info {
    color: ${calloutColors.info.border};
  }
  &.info2 {
    color: ${calloutColors.info2.border};
  }
  &.disclaimer {
    color: ${calloutColors.disclaimer.border};
  }
  &.tip {
    color: ${calloutColors.tip.border};
  }
`;

export const TitleCallout = styled.p`
  color: ${colors.galaxy};
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 8px;

  &.login {
    color: #f5f5f5;
    margin: 0;
  }
`;

export const ContentCallout = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;

  a {
    color: ${colors.galaxy};
    font-weight: 600;
    text-decoration: underline;
  }

  strong {
    color: ${colors.galaxy};
    font-weight: 700;
  }

  &.tip {
    a {
      color: ${calloutColors.tip.text};
      font-weight: 700;
      text-decoration: none;
    }
  }

  &.login,
  &.login strong {
    color: #f5f5f5;
  }
`;
