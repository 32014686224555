import { ReactNode } from 'react';

import { motion } from 'framer-motion';

interface IFadeContainer {
  children: ReactNode;
  transition?: any;
  exit?: boolean;
}

export const FadeContainer = ({
  children,
  transition,
  exit,
}: IFadeContainer) => {
  const exitAnimation: any = exit && { opacity: 0 };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={exitAnimation}
      transition={transition}
    >
      {children}
    </motion.div>
  );
};

FadeContainer.defaultProps = {
  exit: true,
  transition: { duration: 0.3 },
};
