import { useState } from 'react';
import { FiUser, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { useAuth } from 'contexts/AuthContext';

import { Container, Content, MenuPerfilItem, MenuPerfil } from './style';

interface AvatarProps {
  userName?: string;
}

export const Avatar = ({ userName }: AvatarProps) => {
  const { sessionData, requestLogout } = useAuth();

  const [openMenuPerfil, setOpenMenuPerfil] = useState(false);

  const handlePerfil = () => setOpenMenuPerfil(!openMenuPerfil);

  return (
    <Container>
      <Content onClick={handlePerfil}>
        {userName?.charAt(0).toUpperCase()}
      </Content>
      {sessionData && openMenuPerfil && (
        <MenuPerfil isOpen={openMenuPerfil} onMouseLeave={handlePerfil}>
          <Link to="/dashboard/profile">
            <MenuPerfilItem>
              <FiUser />
              <span>meu perfil</span>
            </MenuPerfilItem>
          </Link>
          <hr />
          <MenuPerfilItem onClick={requestLogout}>
            <FiX />
            <span>sair</span>
          </MenuPerfilItem>
        </MenuPerfil>
      )}
    </Container>
  );
};

Avatar.defaultProps = {
  userName: '',
};
