import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import { RenegotiationRepay } from './components/RenegotiationRepay';

export function PageRenegotiationRepay() {
  return (
    <DashBoardTemplate headerTitle="Renegociação" contentDirection="baseline">
      <RenegotiationRepay />
    </DashBoardTemplate>
  );
}
