import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDBTracking } from 'contexts/DBTrackingContext';
import { useRouter } from 'hooks/useRouter';
import { useTracking } from 'hooks/useTracking';
import RenegotiationService from 'services/Offers/Renegotiation';
import { storageKeys } from 'store/storage-keys';
import { RenegotiationTypes } from 'types/Renegotiation/Renegotiation';
import { toCurrency, toCurrencyBrl } from 'utils/masks';
import { storage } from 'utils/storage';

import { RenegotiationPanel } from '../RenegotiationPanel';

export function RenegotiationRegularize() {
  const [confirmation] = useState(false);
  const { location } = useHistory<RenegotiationTypes>();
  const [offer] = useState<RenegotiationTypes>(
    location.state || ({} as RenegotiationTypes),
  );
  const { history } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { setTrackingEvent } = useDBTracking();
  const { trackEvent } = useTracking();
  const borrowerId = storage.getItem(storageKeys.borrowerId);

  useEffect(() => {
    document.title = 'Renegociacao - Detalhe';
  }, []);

  const confirmOffer = async () => {
    setIsLoading(true);

    try {
      trackEvent('select_item', {
        item_name: 'quero_essa',
        borrower_id: borrowerId,
      });

      setTrackingEvent({
        category: 'negotiation',
        action: 'offer_accepted',
        details: {
          type: 'regularization',
          offfer_id: offer.offers?.uuid,
        },
        loanUuid: true,
      });

      const { data } = await RenegotiationService.AcceptOffer({
        type: 'regularization',
        period: 1,
        debt_amount: offer?.offers?.debt_amount || 0,
        uuidOffer: offer?.offers?.uuid || '',
      });

      history.push('/dashboard/renegociacao/pagamento', {
        uuid: data.uuid,
        type: 'regularization',
        value: offer?.offers?.regularization?.value,
        expires_at: offer?.offers?.expires_at,
      });
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <RenegotiationPanel
      headerOptions={{
        description: 'valor total em atraso',
        value: toCurrencyBrl(offer?.offers?.late_debt_amount.toFixed(2) || 0),
        containerValueType: 'late',
      }}
      panelItems={[
        {
          title: 'Regularize',
          description:
            'Você paga todo o valor em atraso e fica em dia com a gente!',
          offers: [
            {
              description: 'PARCELAS EM ATRASO',
              label: offer?.offers?.regularization?.late_installments_quantity,
              value: '',
            },
            {
              description: 'VALOR TOTAL A PAGAR',
              value: toCurrency(
                offer?.offers?.regularization?.value.toFixed(2) || 0,
              ),
            },
          ],
        },
      ]}
      actionsWith={{
        buttonWithInfo: {
          text: (
            <>
              ATENÇÃO: Você <strong>não </strong>
              vai quitar todo o empréstimo, apenas as parcelas em atraso`
            </>
          ),
          showText: true,
        },
        button: true,
      }}
      confirmation={confirmation}
      confirmOrder={confirmOffer}
      hasPaymentMethods
      expires_at={offer?.offers?.expires_at}
      offerUuid={offer?.offers?.uuid}
      isLoading={isLoading}
    />
  );
}
