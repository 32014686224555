import { format } from 'date-fns';
import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { RetrieveAppKey } from 'types/Analyses/AnalysesTypes';

export interface RetrieveAnalysesData {
  data: {
    uuid: string;
    appkey: string;
    production_key: string;
    pub_key: string;
    session_token: string;
    device_key: string;
  };
}

interface ValidateAnalysesData {
  uuid: string;
}

class CertifaceService extends HttpClient {
  public constructor() {
    super(Services.Certiface);
  }

  public retrieveAnalysesData = async (body: RetrieveAppKey) =>
    this.instance.post('/analyses', body) as Promise<RetrieveAnalysesData>;

  public validateAnalysesData = async (body: ValidateAnalysesData) => {
    const completed_at = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");

    return this.instance.patch(`/analyses/${body.uuid}`, {
      completed_at,
    });
  };
}

export default new CertifaceService();
