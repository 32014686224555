import { useState, createContext, useContext, useMemo } from 'react';

import PendenciesService from 'services/PlatformLoans/PendenciesService';

interface PendenciesContextProps {
  pendenciesList: any;
  fetchPendenciesList: () => Promise<any>;
}

export const PendenciesContext = createContext({} as PendenciesContextProps);

export const PendenciesProvider = ({ children = null }) => {
  const [pendenciesList, setPendenciesList] = useState([]);

  const fetchPendenciesList = async () => {
    try {
      const { data } = await PendenciesService.getPendencies();

      setPendenciesList(data.data);

      return data.data;
    } catch {
      throw Error();
    }
  };

  const value = { pendenciesList, fetchPendenciesList };

  return (
    <PendenciesContext.Provider value={value}>
      {children}
    </PendenciesContext.Provider>
  );
};

export const usePendencies = () => {
  const ctx = useContext(PendenciesContext);

  return useMemo(() => ctx, [ctx]);
};
