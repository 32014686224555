import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const InputStyled = styled.input`
  width: 100%;
  height: 56px;
  padding: 0 15px;

  border: 1px solid ${colors.text};
  border-radius: 8px;

  background: ${colors.white};
  color: ${colors.text};

  outline: none;

  &.error {
    border: 1px solid ${colors.errors};

    &:focus {
      box-shadow: 0 0 0 1px ${colors.errors};
      border-color: ${colors.errors};
    }
  }

  &:focus {
    ${({ color }) => css`
      box-shadow: 0 0 0 1px ${color || colors.noverde};
      border-color: ${color || colors.noverde};
    `}
  }

  &:disabled {
    border: 1px solid ${colors.greyborders};
    color: ${colors.disable};
    cursor: not-allowed;
  }
`;

type HelperTextProps = {
  marginLeft?: string | number;
};
export const HelperTextStyled = styled.span<HelperTextProps>`
  font-size: 12px;
  margin-top: 5px;
  margin-left: ${({ marginLeft }) => marginLeft || '15px'};
`;
