import { HTMLAttributes, ReactNode } from 'react';

import * as S from './style';

export type PanelButtonProps = {
  children: string | ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  size?: string;
  variant?: 'secondary';
  confirmationAction?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

export function PanelButton({
  children,
  isLoading = false,
  disabled = false,
  size,
  variant,
  confirmationAction = false,
  ...props
}: PanelButtonProps) {
  return (
    <S.Button
      isLoading={isLoading}
      disabled={disabled}
      size={size}
      className={variant}
      confirmationAction={confirmationAction}
      {...props}
    >
      {children}
    </S.Button>
  );
}
