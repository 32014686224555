import { createContext, useContext, useState, Dispatch } from 'react';

import { useRouter } from 'hooks/useRouter';
import LoansService from 'services/PlatformLoans/LoansService';
import { storageKeys } from 'store/storage-keys';
import {
  DocumentsVariants,
  LoanState,
  ScreensRequirements,
} from 'utils/constants/enums';
import { storage } from 'utils/storage';
import { useStore } from 'zstore';

import { usePendencies } from './PendenciesContext';
import { useRequirements } from './RequirementsContext';

interface NavigationContextProps {
  isLoadingNavigation: boolean;
  setIsLoadingNavigation: Dispatch<any>;
  goPrevScreen: () => Promise<any>;
  goNextScreen: () => Promise<any>;
  checkPendencies: () => Promise<any>;
}

export const NavigationContext = createContext({} as NavigationContextProps);

export const NavigationProvider = ({ children = null }) => {
  const { history } = useRouter();
  const { fetchRequirements, fetchRequirementsByLoanUid } = useRequirements();
  const { fetchPendenciesList } = usePendencies();
  const [isLoadingNavigation, setIsLoadingNavigation] = useState(false);
  const { loanDetails, setLoanDetails } = useStore();

  const goNextScreen = async () => {
    setIsLoadingNavigation(true);

    try {
      const { data } = await LoansService.getStatusLoan();
      setLoanDetails(data.data);
      storage.setItem(storageKeys.loanUid, data.data?.uid);

      const {
        application: applicationRequirements,
        credit_analysis: creditAnalysisRequirements,
      } = await fetchRequirementsByLoanUid(data.data?.uid);

      // TODO: Temp solution for save life proof tip
      data.data?.attachments?.forEach((item) => {
        if (item.document_type === DocumentsVariants.SELFIE) {
          storage.setItem(storageKeys.lifeProofTip, item.analysis.life_proof);
        }
      });

      const { status } = data.data;

      switch (status) {
        case LoanState.SIMULATION:
          checkRequirements(
            data.data,
            creditAnalysisRequirements,
            '/offer-analysis',
          );
          break;
        case LoanState.WAITING_CREDIT_ANALYSIS:
          history.push('/offer-analysis', {
            isFgts: data?.data?.product_category === 'FGTS',
          });
          break;
        case LoanState.CANCELLED:
          history.push('/offer-denied', {
            isFgts:
              data.data?.status === 'cancelled' &&
              data.data?.product_category === 'FGTS',
            loanSelected: data?.data,
            isTeG:
              data.data?.status === 'cancelled' &&
              data.data?.flow === 'refinancing_flow',
          });
          break;
        case LoanState.PROPOSAL:
          checkRequirements(
            { ...data.data },
            applicationRequirements,
            '/finish',
          );
          break;
        case LoanState.WAITING_ACCEPTANCE:
          handleWaitingAcceptance(data.data);
          break;
        case LoanState.PENDING:
          await handlePending({ ...data.data }, applicationRequirements);
          break;
        case LoanState.APPLICATION:
          history.push('/finish');
          break;
        default:
          history.push('/dashboard');
          break;
      }
    } catch {
      history.push('/unavailable');
    } finally {
      setIsLoadingNavigation(false);
    }
  };

  const goPrevScreen = async () => {
    setIsLoadingNavigation(true);

    if (loanDetails.status === LoanState.SIMULATION) {
      const { credit_analysis: creditAnalysisRequirements } =
        await fetchRequirements();

      const routePathname = history.location.pathname.replace('/', '');
      const activeScreenRequirements = ScreensRequirements[routePathname] || [];

      let validatedRequirements = activeScreenRequirements.map((req) => {
        if (creditAnalysisRequirements.includes(req)) {
          const previousRequirements =
            creditAnalysisRequirements.indexOf(req) - 1;

          return previousRequirements;
        }

        return null;
      });

      validatedRequirements = validatedRequirements.filter(
        (item) => item && item,
      );

      const requirementOfPrevScreen =
        creditAnalysisRequirements[validatedRequirements[0]];

      if (!requirementOfPrevScreen) {
        window.location.href = '/dashboard';
      }

      Object.entries(ScreensRequirements).forEach(([key, value]) => {
        value.forEach((item) => {
          if (item === requirementOfPrevScreen) {
            if (key === 'dashboard') {
              window.location.href = '/dashboard';
            } else {
              history.push(`/${key}`);
            }
          }
        });
      });
    } else {
      window.location.href = '/dashboard';
    }

    setIsLoadingNavigation(false);
  };

  const checkRequirements = (data, requirements, goalScreen) => {
    const isFgts = data?.product_category === 'FGTS';

    const requiredRequestedAmount =
      !data.requested_amount && requirements.includes('requested_amount');

    if (requiredRequestedAmount) {
      return history.push('/requested-amount');
    }

    const requiredRequestedConfig =
      (!data.requested_period && requirements.includes('requested_period')) ||
      (!data.requested_payday && requirements.includes('requested_payday'));

    if (requiredRequestedConfig) {
      return history.push('/requested-config');
    }

    const requiredEmploymentStatus =
      !data.employment_status && requirements.includes('employment_status');

    if (requiredEmploymentStatus) {
      return history.push('/employment-status');
    }

    const hasOfferFgtsAccepted = !!data?.offer_accepted_at;

    if (!hasOfferFgtsAccepted && isFgts && data?.status === 'proposal') {
      return history.push({
        pathname: '/offer-approved-fgts',
        state: {
          proposal: {
            proposals: data?.proposals,
            requested_period: data?.requested_period,
            requested_amount: data?.requested_amount,
          },
        },
      });
    }

    const requiredMaritalStatus =
      !data.marital_status && requirements.includes('marital_status');

    if (requiredMaritalStatus && isFgts) {
      return history.push('/marital-status');
    }

    const requiredGender = !data.gender && requirements.includes('gender');

    if (requiredGender && isFgts) {
      return history.push('/gender');
    }

    const requiredMotherName =
      !data.mother_name && requirements.includes('mother_name');

    if (requiredMotherName && isFgts) {
      return history.push('/mother-name');
    }

    const requiredIncome = !data.income && requirements.includes('income');

    if (requiredIncome) {
      return history.push({
        pathname: '/income',
        state: {
          fgts: isFgts,
        },
      });
    }

    const requiredAddress = !data.address && requirements.includes('address');

    if (requiredAddress) {
      return history.push('/address');
    }

    const hasOfferAccepted = !!data?.offer_accepted_at;

    if (!hasOfferAccepted && data?.status === 'proposal') {
      const route =
        data.flow === 'refinancing_flow' ? '/refinancing' : '/offer-approved';

      return history.push({
        pathname: route,
        state: {
          proposal: {
            proposals: data?.proposals,
            requested_period: data?.requested_period,
            requested_amount: data?.requested_amount,
            granted_amount: data?.granted_amount,
          },
        },
      });
    }

    const requiredBankData =
      !data.bank_account &&
      requirements.includes('bank_account') &&
      !data.pix_key;

    if (requiredBankData) {
      return history.push(isFgts ? '/bank-account' : '/receiving-forms');
    }

    const requiredContract = !data.accepted_at && requirements.includes('ccb');

    if (requiredContract) {
      if (
        data?.status !== 'pending' &&
        !requirements.includes('bank_account')
      ) {
        return history.push('/list-accounts');
      }

      return history.push('/contract-sign');
    }

    let hasDocumentFront;
    let hasIncomeProof;
    let hasResidenceProof;
    let hasSelfie;
    let hasLifeProof;

    data?.attachments?.forEach((item) => {
      switch (item.document_type) {
        case DocumentsVariants.DOCUMENT_FRONT:
          hasDocumentFront = true;
          break;
        case DocumentsVariants.INCOME_PROOF:
          hasIncomeProof = true;
          break;
        case DocumentsVariants.RESIDENCE_PROOF:
          hasResidenceProof = true;
          break;
        case DocumentsVariants.SELFIE:
          if (Object.keys(item.analysis).length) {
            hasSelfie = true;
          }
          break;
        case DocumentsVariants.LIFE_PROOF:
          if (item.analysis.verified) {
            hasLifeProof = true;
          }
          break;
        default:
          break;
      }
    });

    const requiredSelfie = !hasSelfie && requirements.includes('selfie');

    if (requiredSelfie) {
      return history.push('/liveness-disclaimer');
    }

    const requiredLifeProof =
      hasSelfie && !hasLifeProof && requirements.includes('life_proof');

    if (requiredLifeProof) {
      return history.push('/proof-disclaimer');
    }

    const requiredDocumentFront =
      !hasDocumentFront && requirements.includes('document_front');
    const requiredIncomeProof =
      !hasIncomeProof && requirements.includes('income_proof');
    const requiredResidence =
      !hasResidenceProof && requirements.includes('residence_proof');

    if (requiredDocumentFront || requiredResidence || requiredIncomeProof) {
      return history.push('/documents');
    }

    const requiredDocumentInfos =
      !data.document_infos && requirements.includes('document_infos');

    if (requiredDocumentInfos && isFgts) {
      return history.push('/identification-document');
    }

    if (goalScreen) {
      return history.push(goalScreen, {
        fgts: isFgts,
      });
    }

    return history.push('/unavailable');
  };

  const handleWaitingAcceptance = (data) => {
    if (data.product_category === 'CDC') {
      return history.push('/proposal-acceptance');
    }

    return history.push('/approved');
  };

  const handlePending = async (data, applicationRequirements) => {
    const pendenciesData = await fetchPendenciesList();

    if (!pendenciesData?.length) {
      return checkRequirements(data, applicationRequirements, '/finish');
    }

    return history.push('/pendencies-list');
  };

  const checkPendencies = async () => {
    setIsLoadingNavigation(true);

    const pendenciesData = await fetchPendenciesList();

    setIsLoadingNavigation(false);

    let hasDocumentFront;
    let hasIncomeProof;
    let hasResidenceProof;
    let hasSelfie;
    let bankAccount;

    pendenciesData?.forEach((item) => {
      switch (item.type) {
        case DocumentsVariants.DOCUMENT_FRONT:
          hasDocumentFront = true;
          break;
        case DocumentsVariants.INCOME_PROOF:
          hasIncomeProof = true;
          break;
        case DocumentsVariants.RESIDENCE_PROOF:
          hasResidenceProof = true;
          break;
        case DocumentsVariants.SELFIE:
          hasSelfie = true;
          break;
        case DocumentsVariants.BANK_ACCOUNT:
          bankAccount = true;
          break;
        default:
          break;
      }
    });

    if (hasSelfie) {
      return history.push('/liveness-disclaimer');
    }

    if (
      !hasSelfie &&
      storage.getItem(storageKeys.lifeProofPending) === 'pending'
    ) {
      return history.push('/proof-disclaimer');
    }

    if (hasDocumentFront || hasIncomeProof || hasResidenceProof) {
      return history.push('/documents');
    }

    if (bankAccount) {
      storage.setItem(storageKeys.pendingBankAccount, true);

      return history.push('/receiving-forms');
    }

    return history.push('/unavailable');
  };

  const value = {
    isLoadingNavigation,
    setIsLoadingNavigation,
    goNextScreen,
    goPrevScreen,
    checkPendencies,
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
