import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

type Props = {
  open: boolean;
};

export const ModalDataWrapper = styled.div<Props>`
  ${({ open }) => css`
    background: ${open ? '#00000075' : 'transparent'};
    border-radius: 8px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background 0.5s ease-out;
    width: ${open ? '100%' : '0'};
    z-index: 1;
  `}
`;

export const ModalContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  place-items: center;
  height: 100%;
  width: 100%;
`;

export const Overlay = styled.div`
  background: transparent;
  height: 100%;
  width: 100%;
`;

export const ModalContent = styled.div<Props>`
  ${({ open }) => css`
    flex-direction: column;
    border-radius: 8px;
    overflow-x: auto;
    width: ${open ? '95%' : '0'};
    padding: 0 1rem;
    display: ${open ? 'flex' : 'none'};
  `}
`;

export const ModalItem = styled.p`
  align-items: center;
  background: ${colors.white};
  border: 1px solid ${colors.greyborders};
  color: ${colors.noverde};
  font-weight: 700;
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  &:hover {
    cursor: pointer;
    filter: brightness(0.95);
  }
`;
