import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { FieldWithLabel } from '../FieldWithLabel';

import { PasswordFieldStyled, IconStyled } from './style';

type InputType = React.InputHTMLAttributes<HTMLInputElement>;
interface PasswordFieldProps {
  name: string;
  label: string;
  helperText?: string;
  disabled?: boolean;
  pattern?: string;
  validation?: any;
}

export function PasswordField(props: PasswordFieldProps & InputType) {
  const { name, label, helperText, disabled, pattern, validation, ...rest } =
    props;
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <PasswordFieldStyled>
      <FieldWithLabel
        name={name}
        disabled={disabled}
        type={passwordShown ? 'text' : 'password'}
        pattern={pattern}
        validation={validation}
        helperText={helperText}
        label={label}
        {...rest}
      />
      <IconStyled onClick={togglePasswordVisiblity}>
        {passwordShown ? <FiEye /> : <FiEyeOff />}
      </IconStyled>
    </PasswordFieldStyled>
  );
}

const defaultProps = {
  helperText: null,
  disabled: false,
  pattern: null,
  validation: null,
};

PasswordField.defaultProps = defaultProps;
