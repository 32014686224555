import { useEffect } from 'react';

import { useNavigation } from 'contexts/NavigationContext';
import PlatformLoansRemoteService from 'services/Loans/Remote/V1/PlatformLoans';
import { isEmpty } from 'utils/helpers/is-empty';
import { useStore } from 'zstore';

export const withLoadApp =
  <T extends (args?: any) => any>(Component: T) =>
  (props: Parameters<T>[0]) => {
    const { setIsLoadingNavigation } = useNavigation();
    const { loansData, setLoansData } = useStore();

    const loadStack = async () => {
      setIsLoadingNavigation(true);

      try {
        if (isEmpty(loansData)) {
          const { data } =
            await PlatformLoansRemoteService.requestPlatformLoans();

          setLoansData(data);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err);
      } finally {
        setIsLoadingNavigation(false);
      }
    };

    useEffect(() => {
      loadStack();
    }, []);

    return <Component {...props} />;
  };
