import { useEffect } from 'react';

export function RDStation() {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'rdstation';
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      'https://d335luupugsy2.cloudfront.net/js/loader-scripts/303054d0-31e2-4d0a-b81a-5c355c022a7e-loader.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <></>;
}
