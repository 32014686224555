import styled, { keyframes } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  border-top: 4px solid ${colors.greyborders};
  border-right: 4px solid ${colors.greyborders};
  border-bottom: 4px solid ${colors.greyborders};
  border-left: 4px solid ${colors.noverde};
  border-radius: 50%;
  background: transparent;
  height: 2rem;
  margin-right: 12px;
  transform: translateZ(0);
  width: 2rem;
`;
