/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

type StackProps = {
  gap?: string | number;
  width?: string | number;
  position?: string;
  margin?: string | number;
  top?: string;
};

export const Stack = styled.div<StackProps>`
  ${({ gap, width, position, margin, top }) => css`
    display: flex;
    flex-direction: column;
    gap: ${gap || '20px'};
    width: ${width || '100%'};
    justify-content: center;
    align-items: center;
    position: ${position || 'relative'};
    z-index: 1;
    margin: ${margin || 'auto'};
    top: ${top && top};
  `}
`;

export const AlertContainer = styled.div`
  position: relative;
  background: ${colors.neutralLight};
  min-height: 120px;
  width: 100%;
  padding: 16px;
`;

export const Img = styled.img`
  width: 48px;
  height: 48px;
`;

type TextProps = {
  color?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  margin?: string | number;
};

export const Text = styled.p<TextProps>`
  ${({ color, fontSize, fontWeight, margin }) => css`
    font-size: ${fontSize || '14px'};
    font-weight: ${fontWeight || '400'};
    color: ${colors[color!] || colors.black};
    margin: ${margin || 'auto'};
  `}
`;

export const Box = styled.div`
  position: relative;
`;

export const TextWithStrokes = styled(Text)`
  display: flex;
  color: ${colors.noverde};
  align-items: baseline;
  width: 100%;

  :after,
  :before {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    margin: 0 auto;
    background: ${colors.noverde};
  }
`;
