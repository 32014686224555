import { ReactNode } from 'react';

import { AlignSelfProps } from 'contexts/ModalContext';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';

import { getThemeColors } from './helpers/getThemeColors';
import { device } from './medias';

const colors = getThemeColors();

export const ModalContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  position: fixed;
  bottom: 0;

  height: 100vh;
  width: 100%;

  z-index: 999999;

  @media ${device.sm} {
    align-items: center;
    flex-direction: column;
  }
`;

export const ModalLayer = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;

  background-color: ${transparentize(0.8, colors.black)};

  z-index: 1;
`;

interface ModalContentProps {
  size?: number;
  padding?: string;
  alignSelf: AlignSelfProps;
  fixedContent?: ReactNode;
}

interface ModalHolderProps {
  removedScroll?: boolean;
  mb: number;
}

interface ModalCloseProps {
  closeBtnPosition: 'left' | 'right';
  variant: string;
}

export const ModalContent = styled(motion.div)<ModalContentProps>`
  ${({ size, padding, alignSelf }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: auto;
    max-height: 75vh;
    min-height: 250px;

    border-radius: 10px 10px 0 0;
    padding: ${padding || '16px'};

    background-color: ${colors.white};

    z-index: 2;
    position: relative;

    @media ${device.sm} {
      width: ${size}px;
      border-radius: 10px;
      padding: 16px;
    }

    @media ${device.lg} {
      align-self: ${alignSelf};

      margin: 0 calc((50vw - ${size}px) / 2);
    }
  `}
`;

export const ModalHolder = styled.div<ModalHolderProps>`
  position: relative;

  max-height: 100vh;
  overflow-y: ${({ removedScroll }) => (removedScroll ? 'hidden' : 'scroll')};
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  @media ${device.sm} {
    overflow-y: auto;
  }
`;

export const ModalClose = styled.i<ModalCloseProps>`
  font-size: 28px;
  padding: 10px;

  color: ${colors.noverde};

  position: absolute;
  top: 0;
  ${({ closeBtnPosition }) =>
    closeBtnPosition === 'left' ? 'left: 0;' : 'right: 0;'}

  cursor: pointer;
  z-index: 999;

  ${({ variant }) =>
    variant === 'fgts' &&
    css`
      color: ${colorsV2['neutral-ultra-dark']};
    `}
`;

interface ModalTitleProps {
  variant: string;
}

export const ModalTitle = styled.h1<ModalTitleProps>`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  padding: 0 20px;
  margin-bottom: 40px;
  min-height: 2px;

  color: ${colors.galaxy};

  ${({ variant }) =>
    variant === 'fgts' &&
    css`
      color: ${colorsV2['neutral-ultra-dark']};
      font-size: 12px;
      line-height: 20px;

      @media ${device.sm} {
        font-size: 16px;
        line-height: 24px;
      }
    `}
`;

interface ModalFixedContentProps {
  padding: string;
}

export const ModalFixedContent = styled.div<ModalFixedContentProps>`
  width: 100%;
  padding: ${({ padding }) => padding};

  background-color: ${colors.white};

  position: sticky;
  bottom: -1px;
  left: 0;

  z-index: 998;
`;
