import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDBTracking } from 'contexts/DBTrackingContext';
import { UtmDetailsType } from 'types/Tracker/TrackingType';

export function useUtmTracking() {
  const { setUtmTrackingData } = useDBTracking();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [utmTrackingDetails] = useState<UtmDetailsType>({
    utm_source: `${query.get('utm_source')}`,
    utm_medium: `${query.get('utm_medium') ? query.get('utm_medium') : ''}`,
    utm_campaign: `${
      query.get('utm_campaign') ? query.get('utm_campaign') : ''
    }`,
    utm_term: `${query.get('utm_term') ? query.get('utm_term') : ''}`,
    utm_content: `${query.get('utm_content') ? query.get('utm_content') : ''}`,
    assigned_at: new Date(),
  });

  const storeUtmData = () => {
    if (query.get('utm_source')) {
      setUtmTrackingData(utmTrackingDetails);
    }
  };

  return useMemo(() => {
    return {
      storeUtmData,
    };
  }, [storeUtmData]);
}
