import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { storageKeys } from 'store/storage-keys';
import { ConfirmCheckoutTypes } from 'types/Checkout/CheckoutTypes';
import { CreatCreditCardType } from 'types/Payments';
import { storage } from 'utils/storage';

class PaymtService extends HttpClient {
  public constructor() {
    super(Services.Paymt);
  }

  public createCheckout = (body) => {
    return this.instance.post('/installments/checkout', body);
  };

  public confirmCheckout = (body: ConfirmCheckoutTypes) => {
    const requestBody = {
      method: body.method,
      ...(body.credicardUuid && {
        creditcard_id: body.credicardUuid,
      }),
    };

    return this.instance.post(
      `/checkout/${body.checkoutUuid}/payments`,
      requestBody,
    );
  };

  public getCheckout = (uuid: string) => {
    return this.instance.get(`/payments/${uuid}`);
  };

  public getPaymentSlipPdf = (uuid: string) => {
    return this.instance.get(`payments/${uuid}/download`);
  };

  public createRenegotiationCheckout = (body) => {
    return this.instance.post('/accepted-offers/checkout', body);
  };

  public getCreditCards = () => {
    return this.instance.get('/creditcards');
  };

  public getCreditCard = (id) => {
    return this.instance.get(`/creditcards/${id}`);
  };

  public creatCreditCard = (body: CreatCreditCardType) => {
    const requestBody = {
      address: {
        zip_code: body.zipcode,
        street: body.street,
        number: body.number,
        complement: body.complement,
        district: body.district,
        city: body.city,
        state: body.state,
      },
      credit_card_token: body.creditCardToken,
    };

    return this.instance.post('/creditcards', requestBody);
  };

  public deleteCreditCard = (id) => {
    return this.instance.delete(`/creditcards/${id}`);
  };

  public getAccountId = () => {
    return this.instance.get(
      `/creditcards/accounts/?loan_uuid=${storage.getItem(
        storageKeys.loanUid,
      )}`,
    );
  };
}

export default new PaymtService();
