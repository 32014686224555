import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';

import { PlatformLoansResponseData } from './types';

class PlatformLoansRemoteService extends HttpClient {
  public constructor() {
    super(Services.Loans.remote.v1);
  }

  public requestPlatformLoans() {
    return this.instance.get<PlatformLoansResponseData>('/bff/loans');
  }
}

export default new PlatformLoansRemoteService();
