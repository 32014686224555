import { useHistory } from 'react-router-dom';

import { useLocationState } from 'hooks/useLocationState';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import { CreditCardPayment } from './components/CreditCardPayment';

export function PageCreditCardPayment() {
  const history = useHistory();
  const { cleanAndReturn } = useLocationState();

  return (
    <DashBoardTemplate
      headerTitle="Pagamento via Cartão de Credito"
      handleReturn={() =>
        cleanAndReturn({
          targetRoute: '/dashboard',
          origin: history.location.pathname,
        })
      }
      withSocial={false}
    >
      <CreditCardPayment />
    </DashBoardTemplate>
  );
}
