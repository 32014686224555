const global = {};

const getItem = (key: string) => {
  try {
    if (window.localStorage) {
      return localStorage?.getItem(key);
    }
    return global[key];
  } catch (error) {
    return global[key];
  }
};

const setItem = (key: string, value: any) => {
  try {
    if (window.localStorage) {
      localStorage.setItem(key, value);
    } else {
      global[key] = value;
    }
  } catch (error) {
    global[key] = value;
  }
};

const removeItem = (key: string) => {
  try {
    if (window.localStorage) {
      localStorage.removeItem(key);
    } else {
      delete global[key];
    }
  } catch (error) {
    delete global[key];
  }
};

const getStorage = () => {
  try {
    if (window.localStorage) {
      return localStorage;
    }
    return global;
  } catch (error) {
    return global;
  }
};

export const storage = {
  getItem,
  setItem,
  removeItem,
  getStorage,
};

export const indexedDBExists = (callback) => {
  const req = indexedDB.open('db');
  let existed = true;
  req.onsuccess = () => {
    req.result.close();
    if (!existed) indexedDB.deleteDatabase('db');
    callback(existed);
  };
  req.onerror = () => {
    existed = false;
    callback(existed);
  };
};
