const toCamel = (value) => {
  return value.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const isArray = (array) => {
  return Array.isArray(array);
};

const isObject = (object) => {
  return (
    object === Object(object) &&
    !isArray(object) &&
    typeof object !== 'function'
  );
};

export const formatInCamelCase = (values) => {
  if (isObject(values)) {
    const n = {};

    Object.keys(values).forEach((k) => {
      n[toCamel(k)] = formatInCamelCase(values[k]);
    });

    return n;
  }
  if (isArray(values)) {
    return values.map((i) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return formatInCamelCase(i);
    });
  }

  return values;
};
