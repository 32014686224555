import { createGlobalStyle, css } from 'styled-components';
import { colors } from 'styles/colors';

interface GlobalProps {
  isModalOpen?: boolean;
  isLoadingNavigation?: boolean;
}

export const GlobalStyle = createGlobalStyle<GlobalProps>`
${({ isModalOpen, isLoadingNavigation }) => css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body,
  input,
  textarea,
  button {
    font: 400 16px 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  }

  body {
    background: ${colors.white};
    color: ${colors.text};

    overflow: ${isModalOpen || isLoadingNavigation ? 'hidden' : 'auto'};
    /* overflow: hidden; */
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  b {
    font-weight: 600;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .__react_component_tooltip {
    border-radius: 6px !important;

    @media (max-width: 1023px) {
      border-radius: 0 !important;
      width: 100%;
      top: auto !important;
      bottom: 0 !important;
      left: 0 !important;
    }

    &.show {
      opacity: 1 !important;
    }
  }
`}
`;
