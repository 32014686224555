import { useEffect } from 'react';
import { Redirect, Route } from 'react-router';

import { FadeContainer } from 'components/FadeContainer';
import { useNavigation } from 'contexts/NavigationContext';
import { addMinutes } from 'date-fns';
import { useRouter } from 'hooks/useRouter';
import AuthenticationService from 'services/Authorizer/AuthenticationService';
import { cleanLocalStorage } from 'store/clean';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';

export const SimulationRoute = ({ ...rest }) => {
  const { history } = useRouter();
  const {
    isLoadingNavigation,
    goNextScreen,
    goPrevScreen,
    setIsLoadingNavigation,
  } = useNavigation();

  useEffect(() => {
    let timeout;

    const unBlock = history.block((loc, action): any => {
      if (action === 'POP') {
        setIsLoadingNavigation(true);

        timeout = setTimeout(() => {
          goPrevScreen();
        }, 1000);

        return false;
      }

      return true;
    });

    return () => {
      unBlock();

      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (history.action === 'POP') {
      goNextScreen();
    }
  }, [history]);

  return isLoadingNavigation ? (
    <Route {...rest} component={() => null} />
  ) : (
    <ProtectedRoute {...rest} />
  );
};

export const ProtectedRoute = ({ ...rest }) => {
  const isAuthenticated = !!storage.getItem(storageKeys.accessToken);

  useEffect(() => {
    const userTokenExpiration = storage.getItem(storageKeys.expiresAt)
      ? new Date(storage.getItem(storageKeys.expiresAt) as string)
      : null;
    const refreshToken = storage.getItem(storageKeys.refreshToken) as string;
    const today = new Date();
    const todayExpiredSession = addMinutes(today, 30);
    if (userTokenExpiration && todayExpiredSession > userTokenExpiration) {
      AuthenticationService.refreshToken(refreshToken)
        .then(({ data }) => {
          storage.setItem(storageKeys.accessToken, data.access_token);
          storage.setItem(storageKeys.expiresAt, data.expires_at);
          window.location.reload();
        })
        .catch(() => {
          cleanLocalStorage();
          window.location.href = '/';
        });
    }
  }, []);

  return isAuthenticated ? <AnimatedRoute {...rest} /> : <Redirect to="/" />;
};

export const PublicRoute = ({ ...rest }) => {
  const isAuthenticated = !!storage.getItem(storageKeys.accessToken);

  return !isAuthenticated ? (
    <AnimatedRoute {...rest} />
  ) : (
    <Redirect to="/dashboard" />
  );
};

export const AnimatedRoute = ({ ...rest }) => {
  return (
    <FadeContainer>
      <Route {...rest} />
    </FadeContainer>
  );
};
