import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonLoader } from 'components/ButtonLoader';
import { FooterBar } from 'components/FooterBar';
import { HeaderNavigation } from 'components/HeaderNavigation';
import { InterceptBackBrowser } from 'components/InterceptBackBrowser';
import { Layout } from 'components/Layout';
import { RDStation } from 'components/RDStation';
import { WithFixedContent } from 'components/WithFixedContent';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { PasswordForm } from 'pages/CreatePassword/PasswordForm';
import { colorsV2 } from 'styles/colors-v2';
import { CreatePasswordVariants } from 'utils/constants/enums';

export function CreatePassword() {
  const { hidesm } = useWindowDimensions();
  const [isDirty, setIsDirty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const param = useParams<{ type: string }>();
  const { RESET } = CreatePasswordVariants;

  return (
    <>
      <InterceptBackBrowser interceptRedirect="/" />
      <WithFixedContent
        maxWidth="768px"
        padding="0px"
        fixedContentTop={<HeaderNavigation />}
        fixedContentBottom={
          <FooterBar
            borderRadius="8px 8px 0 0"
            background="neutral-ultra-light"
            borderTop={`1px solid ${colorsV2['neutral-medium-2']}`}
            hasOneChild={hidesm}
          >
            <ButtonLoader
              form="passwordForm"
              isLoading={isLoading}
              disabled={isDirty}
              className="primary-v2"
            >
              {param.type === RESET ? 'próximo' : 'criar conta'}
            </ButtonLoader>
          </FooterBar>
        }
      >
        <Layout>
          <PasswordForm
            reset={param.type}
            setIsLoading={setIsLoading}
            setIsDirty={setIsDirty}
          />
        </Layout>
      </WithFixedContent>
      <RDStation />
    </>
  );
}
