import { Button, ButtonProps } from 'components/Button';
import { transparentize } from 'polished';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { LoaderIconStyled } from 'styles/loader';

interface ButtonLoaderProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const colors = getThemeColors();

export function ButtonLoader({
  children,
  isLoading,
  disabled,
  ...rest
}: ButtonLoaderProps & ButtonProps) {
  return (
    <Button {...rest} disabled={isLoading || disabled}>
      {isLoading ? (
        <LoaderIconStyled
          width={20}
          height={20}
          color={transparentize(0.35, colors.noverde)}
        />
      ) : (
        children
      )}
    </Button>
  );
}
