import { useContext, useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';

import 'firebase/remote-config';
import { Splash } from 'components/Splash';
import { Toaster } from 'components/Toaster';
import { TopLoader } from 'components/TopLoader';
import { MockContext } from 'contexts/MockContext';
import { useNavigation } from 'contexts/NavigationContext';
import firebase from 'firebase/app';
import { useModal } from 'hooks/useModal';
import { useModalBanner } from 'hooks/useModalBanner';
import { useUtmTracking } from 'hooks/useUtmTracking';
import Routes from 'routes';
import { GlobalStyle } from 'styles';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { FillFormButton } from 'styles/miscellany';
import { DEV_ENV, FIREBASE_CONFIG } from 'utils/env';

import 'styles/fonts.css';

const colors = getThemeColors();

function App() {
  const { isModalOpen } = useModal();
  const { storeUtmData } = useUtmTracking();
  const { isModalBannerOpen } = useModalBanner();
  const { isLoadingNavigation } = useNavigation();
  const [isLoading, setIsLoading] = useState(true);
  const { setFillForm } = useContext(MockContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    storeUtmData();
  }, []);

  if (isLoading)
    return (
      <>
        <GlobalStyle
          isModalOpen={isModalOpen || isModalBannerOpen}
          isLoadingNavigation={isLoadingNavigation}
        />
        <Splash />
      </>
    );
  return (
    <>
      <TopLoader />
      <Toaster />

      {DEV_ENV && (
        <FillFormButton type="button" onClick={() => setFillForm(true)}>
          <FaPencilAlt color={colors.noverde} />
        </FillFormButton>
      )}

      <GlobalStyle
        isModalOpen={isModalOpen}
        isLoadingNavigation={isLoadingNavigation}
      />
      <Routes />
    </>
  );
}

try {
  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_CONFIG);
  } else {
    firebase.app();
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.warn('Unable to initialize FirebaseApp');
}

export default App;
