import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import Banner from 'assets/img/SideBanner/woman-simulation.jpg';
import { Button } from 'components/Button';
import { Navigation } from 'components/Navigation';
import { SelectField } from 'components/SelectField';
import { SideBanner } from 'components/SideBanner';
import { WithFixedContent } from 'components/WithFixedContent';
import { useNavigation } from 'contexts/NavigationContext';
import { addDays, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useLoadPartnerOffer } from 'hooks/useLoadPartnerOffer';
import { useModal } from 'hooks/useModal';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CustomSlider } from 'pages/OfferApproved/CustomSlider';
import { NewProposalDetail } from 'pages/OfferApproved/NewProposalDetail';
import { storageKeys } from 'store/storage-keys';
import { SideBySideLayout } from 'styles/grids';
import { isEmpty } from 'utils/helpers/is-empty';
import { toCurrency } from 'utils/masks';
import { storage } from 'utils/storage';

import * as S from './style';

export default function PartnerOffer() {
  const { hidelg } = useWindowDimensions();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const authToken = params && params.get('token');
  const hasAuthToken = Boolean(authToken);
  const { openModal, closeModal } = useModal();
  const history = useHistory();

  const { setIsLoadingNavigation } = useNavigation();

  const methods = useForm();

  const { setValue, watch } = methods;

  const selectedPeriodValue = watch('selectedPeriod') ?? {
    label: '',
    value: '',
  };

  const {
    partnerOffetLoansData,
    selectedOfferRange,
    selectedOffer,
    minMaxSlider,
    startIndexSlider,
    stepSlider,
    handleSlider,
  } = useLoadPartnerOffer(
    authToken as string,
    selectedPeriodValue,
    setIsLoadingNavigation,
  );

  useEffect(() => {
    if (selectedOfferRange) {
      setValue(
        'selectedPeriod',
        selectedOfferRange[selectedOfferRange.length - 1],
      );
    }
  }, [selectedOfferRange, setValue]);

  function openDetailModal() {
    openModal({
      title: 'Detalhes do empréstimo',
      size: 500,
      alignSelf: 'flex-end',
      closeButtonPosition: 'right',
      content: (
        <S.ModalContent>
          <NewProposalDetail proposal={selectedOffer} />
        </S.ModalContent>
      ),
      fixedContent: <Button onClick={closeModal}>Voltar</Button>,
    });
  }

  const handleFirstAccess = () => {
    storage.setItem(storageKeys.partnerOfferUid, selectedOffer.uuid);
    storage.setItem(storageKeys.loanUid, partnerOffetLoansData?.data.uid);

    closeModal();

    history.push('/first-access');
  };

  const handleLogin = () => {
    closeModal();

    history.push('/');
  };

  const handleOffer = () => {
    openModal({
      title: ' ',
      size: 500,
      alignSelf: 'flex-end',
      closeButtonPosition: 'right',
      content: (
        <S.OfferModal>
          <h2>
            <strong>Acesse ou cadastre</strong> sua conta NoVerde para continuar
            com seu pedido
          </h2>
        </S.OfferModal>
      ),
      fixedContent: (
        <div>
          <Button onClick={handleFirstAccess}>Cadastrar</Button>
          <br />
          <Button variant="secondary" onClick={handleLogin}>
            Acessar minha conta
          </Button>
        </div>
      ),
    });
  };

  if (!hasAuthToken) return <Redirect to="/" />;

  return (
    <SideBySideLayout>
      {hidelg && (
        <SideBanner
          title={
            <>
              Sua oferta <b>pré aprovada</b>
            </>
          }
          banner={Banner}
        />
      )}

      {!isEmpty(selectedOffer) && (
        <WithFixedContent
          fixedContentTop={
            <>
              <Navigation variant="secondary" />
              <S.TopBanner>
                <S.ContainerValue>
                  <p>Valor a receber</p>
                  <S.ApprovedValue>
                    <S.Money>
                      <p>R$</p>
                      {toCurrency(selectedOffer.net_value.toFixed(2))}
                    </S.Money>
                  </S.ApprovedValue>
                </S.ContainerValue>
                <S.ContainerSlider>
                  <CustomSlider
                    minMaxSlider={minMaxSlider}
                    setSliderValue={handleSlider}
                    currentSliderValue={startIndexSlider}
                    stepSlider={stepSlider}
                  />
                </S.ContainerSlider>
              </S.TopBanner>
            </>
          }
          fixedContentBottom={
            <S.FooterHolder>
              <Button className="secondary" onClick={openDetailModal}>
                ver detalhes do empréstimo
              </Button>

              <Button onClick={handleOffer}>quero agora</Button>
            </S.FooterHolder>
          }
        >
          <S.InstallmentsDataHolder>
            <FormProvider {...methods}>
              <SelectField
                name="selectedPeriod"
                placeholder="Número de parcelas *"
                options={selectedOfferRange.map((option) => {
                  return {
                    value: option.value,
                    label: option.label,
                  };
                })}
                defaultValue={selectedOfferRange[selectedOfferRange.length - 1]}
              />
            </FormProvider>
          </S.InstallmentsDataHolder>

          <S.InstallmentsDataHolder>
            <S.Content>
              <p>Data da primeira parcela</p>
              <strong>
                {' '}
                {format(
                  addDays(new Date(selectedOffer.first_due_date), 1),
                  "dd 'de' MMMM'",
                  {
                    locale: pt,
                  },
                )}
              </strong>
              <p>
                Com vencimento todo dia{' '}
                {format(
                  addDays(new Date(selectedOffer.first_due_date), 1),
                  'dd',
                  {
                    locale: pt,
                  },
                )}{' '}
                até{' '}
                {format(
                  addDays(new Date(selectedOffer.last_due_date), 1),
                  "MMMM 'de' yyyy",
                  {
                    locale: pt,
                  },
                )}
              </p>
            </S.Content>
          </S.InstallmentsDataHolder>
        </WithFixedContent>
      )}
    </SideBySideLayout>
  );
}
