import { useHistory } from 'react-router-dom';

import { MessageWithButton } from 'components/MessageWithButton';

export function NotFound() {
  const history = useHistory();

  return (
    <MessageWithButton
      title={<b>Página não encontrada</b>}
      text="Você entrou em uma página que não existe."
      primaryButton={{
        action: () => history.push('/'),
        label: 'Voltar para tela inicial',
      }}
    />
  );
}
