import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useRequirements } from 'contexts/RequirementsContext';
import PartnerOfferLoansRemoteService from 'services/Loans/Remote/V1/PartnerOfferLoans';
import {
  IOfferProps,
  IProposal,
  IProposalsProps,
  PartnerOffetLoansResponseData,
} from 'services/Loans/Remote/V1/PartnerOfferLoans/types';
import { isEmpty } from 'utils/helpers/is-empty';
import { toCurrencyBrl } from 'utils/masks';

export type MinMaxSliderProps = {
  step: number;
  value: number;
};

export interface SelectedOfferRangeProps extends IOfferProps {
  label: string;
  value: string;
}

export const useLoadPartnerOffer = (
  authToken: string,
  selectedPeriodValue: { label: string; value: string },
  setIsLoadingNavigation: Dispatch<SetStateAction<boolean>>,
) => {
  const { creditAnalysisRequirements } = useRequirements();

  const [partnerOffetLoansData, setPartnerOffetLoansData] =
    useState<PartnerOffetLoansResponseData>();
  const [proposals, setProposals] = useState<IProposalsProps>();
  const [proposal, setProposal] = useState<IProposal>();
  const [offerGroupKeys, setOfferGroupKeys] = useState<number[]>([]);
  const [minMaxSlider, setMinMaxSlider] = useState<MinMaxSliderProps[]>([
    { step: 1, value: 0 },
    { step: 2, value: 0 },
  ]);
  const [stepSlider, setStepSlider] = useState<number>(0);
  const [selectedOffer, setSelectedOffer] = useState<IOfferProps>(
    {} as IOfferProps,
  );
  const [startIndexSlider, setStartIndexSlider] = useState<number>(0);
  const [selectedOfferRange, setSelectedOfferRange] = useState<
    SelectedOfferRangeProps[]
  >([]);

  const closestValue = (onlyPeriods: number[], findPeriod: number) =>
    onlyPeriods.reduce((accumulator, _current) => {
      return Math.abs(_current - findPeriod) <
        Math.abs(accumulator - findPeriod)
        ? _current
        : accumulator;
    });

  const getInstallments = (data: IOfferProps[]) =>
    data.map((offer, index) => ({
      ...offer,
      value: index.toString(),
      label: `${offer.period}x ${toCurrencyBrl(
        offer.installment_value.toFixed(2),
      )}`,
    }));

  const handleSlider = (value: number) => {
    const currentKey = offerGroupKeys[value];

    const currentSelectedOffer = proposals?.offers?.[currentKey];

    const newOfferSelected = currentSelectedOffer?.sort(
      (a, b) => a.period - b.period,
    );

    const newOfferRangeSelected = getInstallments(
      newOfferSelected as IOfferProps[],
    );

    if (newOfferSelected) {
      setSelectedOffer(newOfferSelected[0]);
    }

    if (newOfferRangeSelected) {
      setSelectedOfferRange(newOfferRangeSelected);
    }
  };

  const loadOffer = async () => {
    setIsLoadingNavigation(true);
    try {
      const { data: proposalResponse } =
        await PartnerOfferLoansRemoteService.requestPartnerOfferLoans(
          authToken,
        );

      if (!isEmpty(proposalResponse)) {
        setPartnerOffetLoansData(proposalResponse);
        setProposals(proposalResponse.data.proposals);
        setProposal(proposalResponse.data.proposal);
      }
    } catch (err) {
      console.warn(err);
    } finally {
      setIsLoadingNavigation(false);
    }
  };

  useEffect(() => {
    if (partnerOffetLoansData && proposals && proposal) {
      const allOfferKeys = Object.keys(proposals.offers).map((keyOffer) =>
        Number(keyOffer),
      );

      setOfferGroupKeys(allOfferKeys);

      if (allOfferKeys.length > 1) {
        setMinMaxSlider([
          {
            step: 0,
            value: allOfferKeys[0] as number,
          },
          {
            step: allOfferKeys.length - 1,
            value: allOfferKeys[allOfferKeys.length - 1] as number,
          },
        ]);

        setStepSlider(1);
      }

      const startOfferKey = closestValue(
        allOfferKeys,
        proposal?.granted_amount as number,
      );

      setStartIndexSlider(
        allOfferKeys.findIndex((element) => element === startOfferKey),
      );

      const startRangeOffers = getInstallments(
        proposals?.offers[startOfferKey],
      );

      setSelectedOfferRange(startRangeOffers);

      if (creditAnalysisRequirements.includes('requested_period')) {
        const currentSelectedPeriodOffers: number[] = startRangeOffers.map(
          (item: { period: number }) => item.period,
        );

        const currentPeriodOffer = closestValue(
          currentSelectedPeriodOffers,
          partnerOffetLoansData?.data.requested_period as number,
        );

        const startSelectedOffer: IOfferProps =
          startRangeOffers.find(
            (_offer) => _offer.period === currentPeriodOffer,
          ) || ({} as IOfferProps);

        setSelectedOffer(startSelectedOffer);
      } else {
        const startStandardOffer =
          startRangeOffers[startRangeOffers.length - 1];

        setSelectedOffer(startStandardOffer);
      }
    }
  }, [partnerOffetLoansData, proposals, proposal]);

  useEffect(() => {
    if (selectedPeriodValue.value) {
      const index = Number(selectedPeriodValue.value);
      setSelectedOffer(selectedOfferRange[index]);
    }
  }, [selectedPeriodValue]);

  useEffect(() => {
    loadOffer();
  }, [authToken, setIsLoadingNavigation]);

  return {
    partnerOffetLoansData,
    handleSlider,
    minMaxSlider,
    stepSlider,
    selectedOffer,
    selectedOfferRange,
    startIndexSlider,
  };
};
