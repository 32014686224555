import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useLocationState } from 'hooks/useLocationState';
import { BoletoPaymentDashboard } from 'pages/Dashboard/components/BoletoPaymentDashboard';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

export interface BoletoPaymentRouteProps {
  total: string;
  dueDate: string;
  paymentId: string;
  digitableLine: string;
}

export function PageBoletoPayment() {
  const history = useHistory();
  const { state } = useLocation<BoletoPaymentRouteProps>();
  const { cleanAndReturn, verifyLocationState } = useLocationState();

  useEffect(() => {
    verifyLocationState('/dashboard');
  }, []);

  return (
    <DashBoardTemplate
      headerTitle="Pagamento via Boleto"
      handleReturn={() =>
        cleanAndReturn({
          targetRoute: '/dashboard',
          origin: history.location.pathname,
        })
      }
    >
      <BoletoPaymentDashboard
        dueDate={state?.dueDate || ''}
        paymentId={state?.paymentId || ''}
        digitableLine={state?.digitableLine || ''}
        total={state?.total || ''}
      />
    </DashBoardTemplate>
  );
}
