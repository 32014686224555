import { RegisterOptions } from 'react-hook-form';

type Fn<T, J> = (value: J) => {
  [K in keyof T]: RegisterOptions;
};

export const createFormValidations =
  <T extends Record<string, unknown>, J = Partial<Record<keyof T, string>>>(
    fn: Fn<T, J>,
  ) =>
  (_?) =>
    fn(_);
