import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import BannerDotz from 'assets/img/SideBanner/dotz/woman-simulation-dotz.jpg';
import Banner from 'assets/img/SideBanner/woman-simulation.jpg';
import { LoadingPage } from 'components/LoadingPage';
import { SideBanner } from 'components/SideBanner';
import { useDBTracking } from 'contexts/DBTrackingContext';
import { useNavigation } from 'contexts/NavigationContext';
import { useBorrower } from 'hooks/useBorrower';
import useWindowDimensions from 'hooks/useWindowDimensions';
import SimulationService from 'services/PlatformLoans/SimulationService';
import { storageKeys } from 'store/storage-keys';
import { SideBySideLayout } from 'styles/grids';
import { getThemeAssets } from 'styles/helpers/getThemeAssets';
import { isEmpty } from 'utils/helpers/is-empty';
import { storage } from 'utils/storage';

const assets = getThemeAssets({
  default: {
    banner: Banner,
  },
  dotz: {
    banner: BannerDotz,
  },
});

export function CreateSimulation() {
  const { goNextScreen } = useNavigation();
  const { hidelg } = useWindowDimensions();
  const { utmData, setTrackingEvent, clearUtmData } = useDBTracking();
  const history = useHistory();
  const { borrowerData } = useBorrower();
  const { uuid } = useParams<{ uuid: string }>();

  const DOTZ_SUFFIX = process.env.REACT_APP_DOTZ_DOMAIN;
  const utmDotz = DOTZ_SUFFIX
    ? {
        utm_source: 'dotz',
        utm_medium: 'web_dotz',
        utm_content: '',
        assigned_at: new Date().toISOString(),
      }
    : null;

  useEffect(() => {
    if (!isEmpty(borrowerData)) {
      if (
        !borrowerData.data?.attributes['mobile-number'] ||
        borrowerData.data?.attributes['mobile-number'].length <= 0
      ) {
        history.push('/update-phone');
      }
    }
  }, [borrowerData]);

  const createSimulation = async () => {
    const utmBody: any = utmData.utm_source ? utmData : utmDotz;

    try {
      const payload = {
        ...(utmBody && {
          utm: utmBody,
        }),
        ...(!!uuid && {
          offer_id: uuid,
        }),
      };

      const { data } = await SimulationService.createSimulation(payload);

      storage.setItem(storageKeys.loanUid, data.uid);

      if (utmData.utm_source) {
        setTrackingEvent({
          category: 'attribution',
          action: 'loan_create',
          loanUuid: true,
          deviceId: true,
          utmData: true,
        });
      }

      clearUtmData();

      goNextScreen();
    } catch {
      history.push('/dashboard');
    }
  };

  useEffect(() => {
    createSimulation();
  }, []);

  return (
    <SideBySideLayout>
      {hidelg && (
        <SideBanner
          title={
            <>
              Faça sua <b>simulação</b>
            </>
          }
          banner={String(assets?.banner)}
        />
      )}

      <LoadingPage
        title={
          <>
            Iniciando sua <b>simulação</b>
          </>
        }
        text={
          <>
            <p>Aguarde alguns instantes</p>
            <p>É rapidinho.</p>
          </>
        }
      />
    </SideBySideLayout>
  );
}
