import Skeleton from 'react-loading-skeleton';

import * as S from './style';

export const PresentationVariants = {
  primary: {
    headline: 'galaxy',
    subheading: 'text',
  },
  secondary: {
    headline: 'white',
    subheading: 'white',
  },
  tertiary: {
    headline: 'text',
    subheading: 'text',
  },
  'primary-v2': {
    headline: 'text',
    subheading: 'text',
  },
};

interface PresentationProps {
  headline?: React.ReactNode | string;
  subheading?: React.ReactNode;
  variant?: keyof typeof PresentationVariants;
  isLoading?: boolean;
}

export function Presentation({
  headline,
  subheading = 'this is a subheading or orientation text',
  variant = 'primary',
  isLoading = false,
  ...rest
}: PresentationProps) {
  if (isLoading) return <Skeleton height={56} />;

  return (
    <S.PresentationStyled variant={variant} {...rest}>
      <S.Headline>{headline}</S.Headline>
      <S.Subheading>{subheading}</S.Subheading>
    </S.PresentationStyled>
  );
}
