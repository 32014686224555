import styled, { keyframes } from 'styled-components';

const logoAnim = keyframes`
      to {
        transform: translateY(-50%);
        top: 50%;
        opacity: 1;
      }
    `;

const letteringAnim = keyframes`
      to {
        transform: translateY(50%);
        bottom: 50%;
        opacity: 1;
      }
    `;

export const SplashContainer = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoHolder = styled.div`
  display: grid;
  grid-template-columns: 51px 200px;
  align-items: center;

  width: 200px;
  height: 100px;
  overflow: hidden;
  position: relative;

  svg {
    display: block;
  }

  #logo {
    position: absolute;
    left: 0;

    animation: ${logoAnim} 0.4s ease-out;
    animation-fill-mode: forwards;

    background-color: white;
    z-index: 2;

    top: 0;
    opacity: 0;
    transform: translateY(0);
  }

  #lettering {
    margin-left: 10px;
    width: 154px;
    height: 34px;
    animation: ${letteringAnim} 0.4s ease-out;
    animation-fill-mode: forwards;
    position: absolute;
    bottom: 0;
    z-index: 1;
    right: 0;
    opacity: 0;
    transform: translateY(0);
  }
`;
