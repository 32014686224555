import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const FieldWithLabelStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;

  input:focus,
  input:not(:placeholder-shown) {
    & ~ label {
      top: -6px;
      left: 10px;

      font-size: 12px;
      padding: 0 5px;
    }
  }

  input:focus {
    & ~ label {
      ${({ color }) => css`
        color: ${color || colors.noverde};
      `}
    }
  }

  input.error {
    & ~ label,
    & ~ span {
      color: ${colors.errors};
    }
  }

  input:disabled {
    & ~ label,
    & ~ span {
      color: ${colors.disable};
      cursor: not-allowed;
    }
  }
`;

export const LabelStyled = styled.label`
  position: absolute;
  top: 20px;
  left: 15px;

  transition: 0.1s all;
  line-height: 16px;

  cursor: text;

  background: ${colors.white};
`;
