/* eslint-disable react/no-array-index-key */
import { ModalViewAllInstallmentsData } from '../../ModalViewAllInstallments';
import { OfferItem } from '../OfferItem';

import * as S from './style';

export type OffersListProps = {
  offers: {
    description: string;
    value: string | number;
    button?: {
      text: string;
      onClick: () => void;
    };
    modalData?: ModalViewAllInstallmentsData[];
  }[];
};

export function OffersList({ offers }: OffersListProps) {
  return (
    <S.ContainerOptionsCardList>
      {offers.map((offer, key) => (
        <OfferItem key={key} offer={offer} />
      ))}
    </S.ContainerOptionsCardList>
  );
}
