import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

import { DashBoardTemplateProps } from '.';

export const MainContainer = styled.main`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 100%;
`;

export const Content = styled.section<{
  contentDirection: DashBoardTemplateProps['contentDirection'];
  isDashboard: DashBoardTemplateProps['isDashboard'];
}>`
  ${({ contentDirection, isDashboard }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: ${contentDirection};

    flex: ${!isDashboard && 1};
    width: 100%;
  `}
`;

export const ContainerMidias = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem 0;

  a {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const MidiaIcon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;
