import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import { RenegotiationPayNow } from './components/RenegotiationPayNow';

export function PageRenegotiationPayNow() {
  return (
    <DashBoardTemplate headerTitle="Renegociação" contentDirection="baseline">
      <RenegotiationPayNow />
    </DashBoardTemplate>
  );
}
