import React, { createContext, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { AnimatePresence } from 'framer-motion';
import { ColorsTypes, getThemeColors } from 'styles/helpers/getThemeColors';
import * as S from 'styles/modalBanner';
import { isEmpty } from 'utils/helpers/is-empty';

import { useDBTracking } from './DBTrackingContext';

interface ModalProps {
  size?: number;
  content: React.ReactNode;
  top: React.ReactNode;
  fixedContent?: React.ReactNode;
  redirectOnClose?: string;
  trackerData?: any;
  customContent?: boolean;
  colorIcon?: keyof ColorsTypes;
}

interface ModalBannerContextProps {
  isModalOpen?: boolean;
  openModal: (props: ModalProps) => void;
  closeModal: () => void;
  onModalClose?: () => void;
}

export const ModalBannerContext = createContext<ModalBannerContextProps>({
  openModal: () => null,
  closeModal: () => null,
  onModalClose: () => null,
});

interface ModalBannerProviderProps {
  children?: React.ReactNode;
}

export const ModalBannerProvider = ({ children }: ModalBannerProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSize, setModalSize] = useState<number>(525);
  const [modalContent, setModalContent] = useState<React.ReactNode>();
  const [modalTop, setModalTop] = useState<React.ReactNode>();

  const [fixedContent, setFixedContent] = useState<React.ReactNode>(null);
  const { setTrackingEvent } = useDBTracking();
  const [trackerDataEvent, setTrackerDataEvent] = useState({});
  const [noContent, setContent] = useState<boolean>(false);
  const colors = getThemeColors();

  const [customColorIcon, setCustomColorIcon] = useState(colors.white);

  function openModal({
    size,
    content,
    fixedContent: fixedContentProp,
    top,
    trackerData,
    customContent,
    colorIcon,
  }: ModalProps) {
    setModalTop(top);
    setIsModalOpen(true);
    setModalSize(size ?? 493);
    setModalContent(content);
    setFixedContent(fixedContentProp ?? null);
    setTrackerDataEvent(trackerData);
    setContent(!!customContent);
    setCustomColorIcon(colorIcon ? colors[colorIcon] : colors.white);
  }

  function closeModal() {
    if (!isEmpty(trackerDataEvent)) {
      setTrackingEvent(trackerDataEvent);
    }

    setIsModalOpen(false);
  }

  const value = {
    isModalOpen,
    openModal,
    closeModal,
  };

  const variants = {
    animate: { opacity: 1, scale: 1 },
    initial: { opacity: 0, scale: 0.3 },
    exit: { opacity: 0, y: '20vh' },
  };

  return (
    <ModalBannerContext.Provider value={value}>
      <AnimatePresence>
        {isModalOpen && (
          <S.ModalContainer>
            <S.ModalLayer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            />
            <S.ModalContent
              animate="animate"
              initial="initial"
              exit="exit"
              transition={{ duration: 0.3 }}
              variants={variants}
              size={modalSize}
              alignSelf="center"
              noBackgroundColor={!!noContent}
            >
              {modalTop}

              <S.ModalClose onClick={closeModal}>
                <MdClose color={customColorIcon} />
              </S.ModalClose>

              {noContent ? (
                <>
                  {modalContent}
                  {fixedContent}
                </>
              ) : (
                <>
                  <S.Content>
                    {modalContent}
                    {fixedContent}
                  </S.Content>
                </>
              )}
            </S.ModalContent>
          </S.ModalContainer>
        )}
      </AnimatePresence>
      {children}
    </ModalBannerContext.Provider>
  );
};

ModalBannerProvider.defaultProps = {
  children: null,
};
