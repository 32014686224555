import { getThemeColors } from 'styles/helpers/getThemeColors';
import { InstallmentsTypes } from 'types/InfoContract/InfoContractTypes';
import { formatDate } from 'utils/formatters/format-date';
import { toBrl } from 'utils/formatters/to-brl';
import { useStore } from 'zstore';

import {
  BoxDescription,
  BoxGrid,
  Description,
  QuantityBox,
  Valor,
  Vencimento,
} from './style';

const colors = getThemeColors();

const renderMessagePayment = {
  pix: 'PIX',
  'payment-slip': 'payment-slip',
  creditcard: 'cartão de crédito',
};

const boxColor = {
  paid: colors.noverde,
  late: colors.royalOrange,
  current: colors.galaxy,
  pending: colors.greyborders,
  hasPendingPayment: colors.blue1,
};

interface InstallmentProps {
  item: InstallmentsTypes;
}

export const InstallmentLoanStatementDashboard = ({
  item,
}: InstallmentProps) => {
  const { availableForPayment } = useStore();

  const getPresentValue = (currentUuid: string) =>
    availableForPayment.installments.find(
      (installment) => installment.uuid === currentUuid,
    ) || { presentValue: '' };

  return (
    <BoxGrid key={item.number}>
      <BoxDescription>
        <div>
          <QuantityBox
            color={
              boxColor?.[
                item?.hasPendingPayment && item?.status !== 'paid'
                  ? 'hasPendingPayment'
                  : item?.status
              ]
            }
            textColor={item.status === 'pending' ? 'text' : 'white'}
          >
            {item?.number}
          </QuantityBox>
          <div>
            {item?.status === 'paid' && (
              <>
                <Vencimento>
                  pago em{' '}
                  {formatDate(item.paidAt, {
                    format: 'DD/MM/YYYY',
                  })}
                </Vencimento>
                {item?.paymentMethod && (
                  <Description>
                    via {renderMessagePayment[item.paymentMethod]}
                  </Description>
                )}
              </>
            )}
            {item?.hasPendingPayment && item?.status !== 'paid' && (
              <Vencimento>em processamento</Vencimento>
            )}
            {['pending'].includes(item?.status) && !item?.hasPendingPayment && (
              <Vencimento>
                vence em&nbsp;
                {formatDate(item?.dueDate, {
                  format: 'DD/MM/YYYY',
                })}
              </Vencimento>
            )}
            {['current'].includes(item?.status) && !item?.hasPendingPayment && (
              <>
                <Vencimento>
                  vence em&nbsp;
                  {formatDate(item?.dueDate, {
                    format: 'DD/MM/YYYY',
                  })}
                </Vencimento>
              </>
            )}
            {item?.status === 'late' && !item?.hasPendingPayment && (
              <>
                <Vencimento>
                  venceu em{' '}
                  {formatDate(item?.dueDate, {
                    format: 'DD/MM/YYYY',
                  })}
                </Vencimento>
                <Description style={{ color: colors.royalOrange }}>
                  parcela em atraso
                </Description>
              </>
            )}
          </div>
        </div>

        <Valor>
          {item?.status === 'late' && !item.hasPendingPayment
            ? toBrl(getPresentValue(item.uuid)?.presentValue)
            : toBrl(item?.value)}
        </Valor>
      </BoxDescription>
    </BoxGrid>
  );
};
