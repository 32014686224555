export const dotzTheme = {
  colorBrandPrimaryLight: '#FFF1E4',
  colorBrandPrimaryPure: '#F56700',
  colorBrandPrimaryDark: '#DD5D00',
  colorBrandSecondaryLight: '#E3F0FF',
  colorBrandSecondaryPure: '#2976D3',
  colorBrandSecondaryDark: '#004191',
  colorNeutralLightPure: '#FFFFFF',
  colorNeutralLightUp: '#F4F4F4',
  colorNeutralLightDown: '#EAEAEA',
  colorNeutralMedium: '#C8C8C8',
  colorNeutralDarkUp: '#8E8E8E',
  colorNeutralDarkDown: '#3D3D3D',
  colorNeutralDarkPure: '#121212',
  colorFeedbackPositiveLight: '#DEF9D4',
  colorFeedbackPositivePure: '#308812',
  colorFeedbackPositiveDark: '#2B7A10',
  colorFeedbackNegativeLight: '#FFE4E4',
  colorFeedbackNegativePure: '#CC3030',
  colorFeedbackNegativeDark: '#B82B2B',
  colorFeedbackInfoLight: '#B82B2B',
  colorFeedbackInfoPure: '#B82B2B',
  colorFeedbackInfoDark: '#B82B2B',
  customDotzLight: '#FF9200',
  colorBrandOthersGrayPure: '#E3D2C8',
  colorBrandOthersYellowPure: '#FEC114',
};
