import { FormWalletDashboard } from 'pages/Dashboard/components/FormWalletDashboard';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

export function WalletRegister() {
  return (
    <DashBoardTemplate headerTitle="Cadastro de carteira">
      <FormWalletDashboard />
    </DashBoardTemplate>
  );
}
