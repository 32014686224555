import styled, { css } from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';
import { device } from 'styles/medias';

export const ContainerOptionsCardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.sm} {
    width: fit-content;
  }
`;

export const ContainerOptionsCardItemUpperText = styled.h3`
  color: ${colorsV2['neutral-dark']};
  font-size: 16px;
  font-weight: 500;
  text-align: start;
  margin-bottom: 8px;
`;

type ContainerOptionsCardItemPriceTypes = {
  isLabel?: boolean;
};

export const ContainerOptionsCardItemPrice = styled.p<ContainerOptionsCardItemPriceTypes>`
  ${({ isLabel }) => css`
    color: ${isLabel
      ? colorsV2['neutral-ultra-dark']
      : colorsV2['brand-primary']};
    font-size: ${isLabel ? '16px' : '40px'};
    font-weight: ${isLabel ? 400 : 700};
    line-height: 48px;
    text-align: start;
    margin-bottom: 8px;
    display: flex;
    gap: 4px;

    p {
      font-size: 16px;
    }
  `}
`;

export const TextViewAllInstallments = styled.p`
  cursor: pointer;
  color: ${colorsV2['brand-primary']};
  font-size: 0.75rem;
  font-weight: 400;
  margin-bottom: 8px;
`;
