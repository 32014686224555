import { FiArrowLeft } from 'react-icons/fi';

import Hamburger from 'hamburger-react';
import { useBorrower } from 'hooks/useBorrower';
import { useRouter } from 'hooks/useRouter';
import { Avatar } from 'pages/Dashboard/components/Avatar';
import { getThemeColors } from 'styles/helpers/getThemeColors';

import { Container, ContainerButton, Text } from './style';

export type HeaderProfileProps = {
  openMenu: boolean;
  headerTitle: string;
  toggleSideMenu: () => void;
  handleReturn?: () => void;
};

export const HeaderProfileDashboard = ({
  openMenu,
  headerTitle,
  toggleSideMenu,
  handleReturn,
}: HeaderProfileProps) => {
  const colors = getThemeColors();
  const { location, history } = useRouter();
  const { borrowerData } = useBorrower();

  return (
    <Container>
      <ContainerButton open={openMenu}>
        {['/dashboard', '/dashboard/'].includes(location.pathname) ? (
          <Hamburger
            color={colors.noverde}
            toggled={openMenu}
            toggle={toggleSideMenu}
          />
        ) : (
          <FiArrowLeft
            {...(handleReturn
              ? {
                  onClick: handleReturn,
                }
              : {
                  onClick: () => {
                    ['/dashboard/renegociacao/pagamento'].includes(
                      location.pathname,
                    )
                      ? history.replace('/dashboard')
                      : history.goBack();
                  },
                })}
          />
        )}
      </ContainerButton>
      <Text>{headerTitle}</Text>
      <Avatar userName={borrowerData.data?.attributes.name} />
    </Container>
  );
};
