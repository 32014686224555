import { ReactNode, useState } from 'react';

import { ButtonLoader } from 'components/ButtonLoader';
import { useDBTracking } from 'contexts/DBTrackingContext';
import { useModal } from 'hooks/useModal';
import { useRouter } from 'hooks/useRouter';
import PaymtService from 'services/Paymt/PaymtService';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { CheckoutTypes } from 'types/Checkout/CheckoutTypes';
import { formatDate } from 'utils/formatters/format-date';
import { toBrl } from 'utils/formatters/to-brl';
import { isEmpty } from 'utils/helpers/is-empty';
import { useStore } from 'zstore';

import {
  Box,
  LineItem,
  LineItemPaymentType,
  LineItemTitle,
  LineItemValue,
  InstallmentBox,
  InstallmentBoxTitle,
  InstallmentList,
  InstallmentNumber,
  Vencimento,
  Valor,
  InformativeMessage,
  ModalButton,
  ModalContainer,
  ModalDescription,
  ModalDescriptionContainer,
} from './style';

interface IRenderProps {
  [paymentType: string]: {
    vencimento: string;
    formasPagamento: string;
    informativeMessage?: string;
    errorMessage: string;
    errorMessageDenied: string;
    textButton: string;
    onClick?: () => void;
  };
}

interface IModalInfo {
  title: string;
  redirect?: string;
  textButton?: string;
  description: string | ReactNode;
  size: number;
  onClick?: () => void;
}

export const PaymentSummaryDashboard = ({
  checkoutUuid = '',
  paymentDate = '',
  totalValue = '',
  installments = [],
  paymentType = '',
}: CheckoutTypes) => {
  const colors = getThemeColors();

  const { history } = useRouter();
  const { openModal, closeModal } = useModal();
  const { setTrackingEvent } = useDBTracking();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { creditCardId } = useStore();

  const pendingInstallments = installments.filter(
    (_installment) => _installment.status === 'late',
  );

  const currentInstallments = installments.filter(
    (_installment) => _installment.status === 'current',
  );

  function modalInfo(info: IModalInfo) {
    openModal({
      title: info.title,
      redirectOnClose: info.redirect,
      content: (
        <ModalContainer>
          <ModalDescriptionContainer>
            {info.description}
          </ModalDescriptionContainer>
          {info.textButton && (
            <ModalButton onClick={info.onClick}>{info.textButton}</ModalButton>
          )}
        </ModalContainer>
      ),
      size: info.size,
    });
  }

  async function onClickConfirmSummary() {
    try {
      setButtonLoading(true);

      const { data } = await PaymtService.confirmCheckout({
        method: paymentType,
        checkoutUuid,
        ...(paymentType === 'creditcard' && {
          credicardUuid: creditCardId,
        }),
      });

      history.push(`/dashboard/payment-polling/${data.id}`);
    } catch (err: any) {
      if ([400].includes(err?.response?.status)) {
        modalInfo({
          title: 'Ops!',
          description: (
            <>
              <ModalDescription>
                Parece que você já gerou um pagamento pra esse resumo. <br />
              </ModalDescription>
              <ModalDescription>
                Iremos te redirecionar para a tela do dashboard.
              </ModalDescription>
            </>
          ),
          size: 252,
          textButton: 'retornar',
          redirect: '/dashboard',
          onClick: () => {
            closeModal();
            history.push('/dashboard');
          },
        });
      }
    } finally {
      setButtonLoading(false);
    }
  }

  const render: IRenderProps = {
    pix: {
      vencimento: 'Vencimento do PIX',
      formasPagamento: 'PIX',
      errorMessage: 'Não foi possível gerar o PIX',
      errorMessageDenied: 'Não foi possível gerar o PIX',
      informativeMessage:
        'Efetue o pagamento até a data indicada, para tentativas de pagamento após esse período será necessário gerar um novo código PIX',
      textButton: 'confirmar',
      onClick: onClickConfirmSummary,
    },
    'payment-slip': {
      vencimento: 'Vencimento do boleto',
      formasPagamento: 'boleto bancário',
      errorMessage: 'Não foi possível gerar o boleto',
      errorMessageDenied: 'Não foi possível gerar o boleto',
      informativeMessage:
        'Sempre confira as datas de vencimento do boleto e da sua parcela. Parcelas pagas depois do vencimento sofrem encargos por atraso',
      textButton: 'confirmar',
      onClick: onClickConfirmSummary,
    },
    creditcard: {
      vencimento: '',
      formasPagamento: 'cartão de crédito',
      errorMessage: 'Ops, ocorreu um erro ao solicitar seu pagamento.',
      errorMessageDenied:
        'Não identificamos seu cartão.  Por favor verifique os dados do seu cartão de crédito e tente novamente.',
      textButton: 'pagar',
      onClick: onClickConfirmSummary,
    },
  };

  function sendTrackingEventPaymentSummary() {
    setTrackingEvent({
      category: 'dashboard-checkout',
      action: 'confirm_button_click',
      loanUuid: true,
      deviceId: true,
    });
  }

  return (
    <Box>
      <LineItem>
        <LineItemTitle>Valor total</LineItemTitle>
        <LineItemValue>{toBrl(totalValue)}</LineItemValue>
      </LineItem>
      {render[paymentType]?.vencimento && (
        <LineItem>
          <LineItemTitle>{render[paymentType]?.vencimento}</LineItemTitle>
          <LineItemValue>
            {formatDate(paymentDate, {
              format: 'DD/MM/YYYY',
            })}
          </LineItemValue>
        </LineItem>
      )}
      <LineItem>
        <LineItemTitle>Forma de pagamento</LineItemTitle>
        <LineItemPaymentType>
          {render[paymentType]?.formasPagamento}
        </LineItemPaymentType>
      </LineItem>

      <InstallmentBoxTitle>Parcelas</InstallmentBoxTitle>
      <InstallmentList>
        {!isEmpty(pendingInstallments) &&
          pendingInstallments.map((item) => (
            <InstallmentBox key={item.number}>
              <InstallmentNumber color={colors.safeareaError}>
                {item.number}
              </InstallmentNumber>
              <div>
                <Vencimento>
                  vence em{' '}
                  {formatDate(item.dueDate, {
                    format: 'DD/MM/YYYY',
                  })}
                </Vencimento>
                <Valor>{toBrl(item.presentValue)}</Valor>
              </div>
            </InstallmentBox>
          ))}

        {!isEmpty(currentInstallments) &&
          currentInstallments.map((item) => (
            <InstallmentBox key={item.number}>
              <InstallmentNumber
                color={
                  item.status === 'current' ? colors.galaxy : colors.greyborders
                }
              >
                {item.number}
              </InstallmentNumber>
              <div>
                <Vencimento>
                  vence em{' '}
                  {formatDate(item.dueDate, {
                    format: 'DD/MM/YYYY',
                  })}
                </Vencimento>
                <Valor>{toBrl(item.value)}</Valor>
              </div>
            </InstallmentBox>
          ))}
      </InstallmentList>

      {render[paymentType]?.informativeMessage && (
        <InformativeMessage>
          {render[paymentType]?.informativeMessage}
        </InformativeMessage>
      )}

      <ButtonLoader
        isLoading={buttonLoading}
        onClick={() => {
          const result = render?.[paymentType]?.onClick;
          result && result();
          sendTrackingEventPaymentSummary();
        }}
      >
        {render?.[paymentType]?.textButton}
      </ButtonLoader>
    </Box>
  );
};
