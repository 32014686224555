import { HTMLAttributes } from 'react';

import { Container, Text } from './style';

type MenuItemDashboardProps = {
  icon: React.ReactElement;
  text: string;
} & HTMLAttributes<HTMLDivElement>;

export const MenuItemDashboard = ({
  icon,
  text,
  ...props
}: MenuItemDashboardProps) => (
  <Container {...props}>
    {icon}
    <Text>{text}</Text>
  </Container>
);
