import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

const colors = getThemeColors();

export const Container = styled.div`
  position: relative;
`;

export const Content = styled.div`
  align-items: center;
  background-color: ${colors.noverde};
  border-radius: 50%;
  color: ${colors.white};
  display: none;
  height: 48px;
  position: relative;
  width: 48px;
  justify-content: center;

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

  @media ${device.sm} {
    display: flex;
  }
`;

type MenuPerfilProps = {
  isOpen?: boolean;
};
export const MenuPerfil = styled.div<MenuPerfilProps>`
  ${() => css`
    background-color: ${colors.galaxy};
    border-radius: 8px;
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: absolute;
    bottom: -8rem;
    right: 0rem;
    width: 156px;
    z-index: 1;
  `}
`;

export const MenuPerfilItem = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: relative;
  margin: 8px 0;

  &:hover {
    cursor: pointer;
  }
  svg {
    margin-right: 12px;
  }
  span {
    width: fit-content;
  }
`;
