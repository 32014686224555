/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState } from 'react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

import * as S from './style';

interface AccordionLoanStatementProps {
  title: string;
  children: string | ReactNode;
}

export const AccordionLoanStatement = ({
  children,
  title = '',
}: AccordionLoanStatementProps) => {
  const [open, setOpen] = useState(false);
  return (
    <S.Root>
      <S.Head role="button" onClick={() => setOpen(!open)}>
        <p>{title}</p>
        {open ? <FiArrowUp /> : <FiArrowDown />}
      </S.Head>
      <S.Body isOpen={open}>{children}</S.Body>
    </S.Root>
  );
};
