import { Controller } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import Select, { components } from 'react-select';

import { ErrorMessage } from '@hookform/error-message';
import { ErrorMessageStyled } from 'styles/miscellany';
import { required } from 'utils/validators/form-validations';

import { ConnectForm } from '../ConnectForm';
import { RadioItem } from '../RadioItem';

import { LabelStyled, SelectContainer } from './style';

interface optionProp {
  value: string;
  label: string;
}

interface SelectFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  menuPlacement?: string;
  options: optionProp[];
  defaultValue?: optionProp;
  validation?: any;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const CustomOption = (props) => {
  const { children, isSelected } = props;

  return (
    <components.Option {...props} style={{ marginBottom: 20 }}>
      <RadioItem content={children} selected={isSelected} />
    </components.Option>
  );
};

export function SelectField(props: SelectFieldProps) {
  const {
    name,
    label,
    placeholder,
    menuPlacement,
    options,
    defaultValue,
    validation,
    isSearchable,
    isDisabled,
    isLoading = false,
  } = props;

  if (isLoading) return <Skeleton height={56} />;

  return (
    <SelectContainer>
      {label && <LabelStyled>{label}</LabelStyled>}
      <ConnectForm>
        {({ errors, control }) => {
          const errorMessage = errors[name]?.message;

          return (
            <>
              <Controller
                as={
                  <Select
                    options={options}
                    components={{ Option: CustomOption }}
                    classNamePrefix="installments"
                    placeholder={placeholder}
                    menuPlacement={menuPlacement}
                    className={errorMessage && 'error'}
                    name="requested_period"
                    validation={validation}
                    isSearchable={isSearchable}
                    isDisabled={isDisabled}
                    noOptionsMessage={() => 'Nenhum resultado encontrado'}
                  />
                }
                name={name}
                defaultValue={defaultValue}
                control={control}
                rules={{ required }}
              />
              <ErrorMessageStyled>
                <ErrorMessage errors={errors} name={name} />
              </ErrorMessageStyled>
            </>
          );
        }}
      </ConnectForm>
    </SelectContainer>
  );
}

SelectField.defaultProps = {
  label: null,
  placeholder: 'selecionar',
  menuPlacement: 'auto',
  validation: null,
  isSearchable: false,
  isDisabled: false,
};
