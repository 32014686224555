import vanillaMasker from 'vanilla-masker';

const allowedMasks = {
  cpf: '999.999.999-99',
  rg: '9999999999999',
  cnh: '99999999999',
  pin: '9',
  date: '99/99/9999',
  phone: '(99) 99999-9999',
  zipCode: '99999-999',
  agency: '9999',
  agencyDigit: 'S',
  accountDigit: 'S',
  account: '99999999999',
  creditCardDate: '99/9999',
  creditCardNumber: '9999 9999 9999 9999',
  creditCardCCV: '999',
};

export const toCurrency = (val) => {
  return vanillaMasker.toMoney(val, {
    precision: 2,
    separator: ',',
    delimiter: '.',
  });
};

export const toCurrencyBrl = (val) => {
  return vanillaMasker.toMoney(val, {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: 'R$',
  });
};

export const toCurrencyBrlFgts = (val) => {
  const options = {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  };
  const formatNumber = new Intl.NumberFormat('pt-BR', options);
  return formatNumber.format(val);
};

export const clearCurrencyStr = (val) => {
  return val.replace(/[.,]/g, '');
};

export const clearCurrencyStrBrl = (val) => {
  return val.replace(/[.,R$]/g, '');
};

export const onlyAlphabet = (val) => {
  return val.replace(
    /[^ áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇa-zA-Z]+/g,
    '',
  );
};

export type Masks = keyof typeof allowedMasks;
export type MasksReturnType<T extends Masks> = typeof allowedMasks[T];
export const masks = <T extends Masks>(maskType: T): MasksReturnType<T> => {
  return allowedMasks[maskType];
};
