import { HTMLAttributes } from 'react';

import { Container, Icon } from './style';

type SeletorDataInstallmentDashboardProps = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const InstallmentDateSelectorDashboard = ({
  children,
  ...props
}: SeletorDataInstallmentDashboardProps) => {
  return (
    <Container {...props}>
      {children} <Icon />
    </Container>
  );
};
