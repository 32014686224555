import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { AccountFormType } from 'types/Authorizer/AccountFormType';

class AccountFormService extends HttpClient {
  public constructor() {
    super(Services.Authorizer);
  }

  public createAccount = (body: AccountFormType) => {
    const requestBody = {
      name: body.name,
      mobile_number: body.mobileNumber,
      email: body.email,
      document: body.document,
      birthdate: body.birthdate,
    };

    return this.instance.post('/account', requestBody);
  };
}

export default new AccountFormService();
