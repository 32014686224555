import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 20rem;
  padding: 1rem;
  row-gap: 1rem;
  width: 100%;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    color: ${colors.galaxy};
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;

export const BoxInstallments = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  row-gap: 8px;
  width: 100%;
`;

export const InstallmentGroup = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const GroupOrderDate = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Order = styled.span`
  align-self: flex-start;
`;

export const Date = styled.span`
  align-self: flex-start;
`;

export const Value = styled.span`
  align-self: flex-start;
`;
