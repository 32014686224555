import { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiArrowDown } from 'react-icons/fi';

import { Button } from 'components/Button';
import { useDBTracking } from 'contexts/DBTrackingContext';
import { useModal } from 'hooks/useModal';
import html2Canvas from 'html2canvas';
import { PixPaymentRouteProps } from 'pages/Dashboard/pix-payment';
import QRCode from 'qrcode.react';
import { storageKeys } from 'store/storage-keys';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { formatDate } from 'utils/formatters/format-date';
import { toBrl } from 'utils/formatters/to-brl';
import { storage } from 'utils/storage';
import { useStore } from 'zstore';

import {
  Box,
  BoxTitle,
  BoxImagePix,
  ButtonWrapper,
  DetailsContainer,
  ContainerDetails,
  DescriptionDetail,
  TitleDetail,
  ValueDetail,
  ValueTitle,
  ModalContainer,
} from './style';

export const PixPaymentDashboard = ({
  total,
  pix,
  receiver,
}: PixPaymentRouteProps) => {
  const colors = getThemeColors();
  const { openModal, closeModal } = useModal();
  const { setTrackingEvent } = useDBTracking();
  const { setContractInfo } = useStore();
  const hideDownloadButton =
    storage.getItem(storageKeys.hideDownloadButton) === 'true';

  useEffect(() => {
    setContractInfo({});
  }, []);

  function createUrl(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function handleDownloadQRCode() {
    html2Canvas(document.getElementById('qrcode') as HTMLImageElement, {
      scale: 2,
    }).then((canvas) => {
      const image = canvas.toDataURL();
      createUrl(image, String(pix.id));
    });
  }

  function sendTrackingEventPaymentPix(eventAction: string) {
    setTrackingEvent({
      category: 'dashboard-pix',
      action: eventAction,
      loanUuid: true,
      deviceId: true,
    });
  }

  function handleCopyPix() {
    openModal({
      title: 'código PIX copiado',
      content: (
        <ModalContainer>
          <p>
            Acesse o app do seu banco selecione
            <strong>PIX copia e cola &gt; pagar &gt; copia e cola</strong>
          </p>
          <Button onClick={closeModal}>entendi</Button>
        </ModalContainer>
      ),
      size: 320,
    });
  }

  function openDetailsModal() {
    openModal({
      title: '',
      size: 320,
      content: (
        <ContainerDetails>
          <div>
            <ValueTitle>Valor a ser pago</ValueTitle>
            <ValueDetail>{toBrl(total)}</ValueDetail>
            <p>
              para <strong>NoVerde Tecnologia e Pagamentos S/A</strong>
            </p>
          </div>

          <div>
            <TitleDetail>Vencimento</TitleDetail>
            <DescriptionDetail>
              {formatDate(pix.dueDate, {
                format: 'DD/MM/YYYY',
              })}
            </DescriptionDetail>
          </div>

          <div>
            <TitleDetail>Chave Pix</TitleDetail>
            <DescriptionDetail>{receiver.key}</DescriptionDetail>
          </div>

          <div>
            <TitleDetail>CNPJ</TitleDetail>
            <DescriptionDetail>{receiver.cnpj}</DescriptionDetail>
          </div>

          <div>
            <TitleDetail>Instituição</TitleDetail>
            <DescriptionDetail>{receiver.bank}</DescriptionDetail>
          </div>

          <div>
            <TitleDetail>Identificador</TitleDetail>
            <DescriptionDetail>{pix.transactionId}</DescriptionDetail>
          </div>
        </ContainerDetails>
      ),
    });
  }

  return (
    <Box>
      <BoxTitle>
        Abra o app do seu banco, selecione PIX e escaneie o código. Você também
        pode copiar para usar o copia e cola PIX no aplicativo do seu banco
      </BoxTitle>
      <BoxImagePix>
        <QRCode id="qrcode" value={pix.emvqrcps} key={receiver.key} />
      </BoxImagePix>
      <ButtonWrapper>
        <CopyToClipboard
          text={pix.emvqrcps}
          onCopy={() => {
            sendTrackingEventPaymentPix('pix_payment_button_click');
            handleCopyPix();
          }}
        >
          <Button> pagar com pix</Button>
        </CopyToClipboard>

        {!hideDownloadButton && (
          <Button
            className="secondary"
            onClick={() => {
              sendTrackingEventPaymentPix('pix_qr_code_button_click');
              handleDownloadQRCode();
            }}
          >
            baixar QR CODE
          </Button>
        )}
      </ButtonWrapper>
      <DetailsContainer
        onClick={() => {
          sendTrackingEventPaymentPix('pix_details_button_click');
          openDetailsModal();
        }}
      >
        <span>detalhes</span>
        <FiArrowDown color={colors.noverde} />
      </DetailsContainer>
    </Box>
  );
};
