import { Prompt, useHistory } from 'react-router';

interface IInterceptBackBrowser {
  interceptRedirect: string;
}

export const InterceptBackBrowser = ({
  interceptRedirect,
}: IInterceptBackBrowser) => {
  const history = useHistory();

  return (
    <Prompt
      message={(location, action) => {
        if (action === 'POP') {
          history.push(interceptRedirect);

          return false;
        }

        return true;
      }}
    />
  );
};
