import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

const colors = getThemeColors();

export const Container = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100;
  justify-content: space-between;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1rem 0;
  width: 100%;

  @media ${device.sm} {
    box-shadow: 0 0 0.5rem #e8e8e8;
    max-width: 40rem;
    width: 100%;
  }
`;

export const ContainerDetails = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
  width: 100%;
`;

export const Detail = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: inherit;

  p {
    color: ${colors.black};
    font-size: 0.875rem;
    font-weight: 700;

    svg {
      color: ${colors.noverde};
      cursor: pointer;
    }
  }

  span {
    color: ${colors.text};
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

export const InstallmentsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  max-height: 15rem;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  width: 100%;
`;

export const Box = styled.div`
  display: grid;
  row-gap: 12px;
  margin-bottom: 1.2rem;
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  color: ${colors.galaxy};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1rem;
  width: 100%;

  .total {
    color: ${colors.noverde};
  }
`;

export const ActionsPagination = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  padding: 1rem;
  width: 100%;
  span {
    color: ${colors.black};
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 700;
    align-self: flex-end;
    :before {
      content: '< ';
    }
  }
`;

export const MaisParcelas = styled.p`
  align-self: flex-start;
  color: ${colors.black};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  :after {
    content: ' >';
  }
`;

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  width: 100%;

  p {
    color: ${colors.text};
    text-align: center;
  }
`;
