import React, { createContext, useEffect, useState } from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import BorrowersRemoteService from 'services/Loans/Remote/V2/Borrowers';
import { BorrowerResponseData } from 'services/Loans/Remote/V2/Borrowers/types';
import { storageKeys } from 'store/storage-keys';

interface BorrowerContextData {
  borrowerData: BorrowerResponseData;
  requestBorrower: () => Promise<void>;
  resetBorrowerData: () => void;
}

interface BorrowerProviderTypes {
  children?: React.ReactNode;
}

export const BorrowerContext = createContext({} as BorrowerContextData);

export const BorrowerProvider = ({ children }: BorrowerProviderTypes) => {
  const [borrowerData, setBorrowerData] = useState<BorrowerResponseData>(
    {} as BorrowerResponseData,
  );
  const [storedData, setStoredData] = useLocalStorage(
    storageKeys.borrowerData,
    borrowerData,
  );

  const requestBorrower = async () => {
    const { data } = await BorrowersRemoteService.requestBorrower();
    setStoredData(data);
  };

  const resetBorrowerData = () => setStoredData({} as BorrowerResponseData);

  useEffect(() => {
    setBorrowerData(storedData || null);
  }, [storedData]);

  return (
    <BorrowerContext.Provider
      value={{ borrowerData, requestBorrower, resetBorrowerData }}
    >
      {children}
    </BorrowerContext.Provider>
  );
};

BorrowerProvider.defaultProps = {
  children: null,
};
