import { useModal } from 'hooks/useModal';
import { useTracking } from 'hooks/useTracking';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';

import {
  ModalViewAllInstallments,
  ModalViewAllInstallmentsData,
} from '../../ModalViewAllInstallments';
import { PanelButton } from '../PanelButton';

import * as S from './style';

export type OfferItemType = {
  description: string;
  value: string | number;
  label?: string | number;
  button?: {
    text: string;
    onClick: () => void;
  };
  modalData?: ModalViewAllInstallmentsData[];
  originalValue?: number | string;
  selectedOffer?: number;
  period?: number | string;
};

type OfferItemProps = {
  offer: OfferItemType;
};

export function OfferItem({ offer }: OfferItemProps) {
  const { openModal } = useModal();
  const { trackEvent } = useTracking();
  const borrowerId = storage.getItem(storageKeys.borrowerId);

  return (
    <S.ContainerOptionsCardItem>
      <S.ContainerOptionsCardItemUpperText>
        {offer.description}
      </S.ContainerOptionsCardItemUpperText>
      <S.ContainerOptionsCardItemPrice isLabel={!!offer.label}>
        {offer.label ? (
          offer?.label
        ) : (
          <>
            <p>R$</p> {offer.value}
          </>
        )}
      </S.ContainerOptionsCardItemPrice>

      {offer.modalData && (
        <S.TextViewAllInstallments
          onClick={() => {
            trackEvent('select_item', {
              item_name: 'ver_todas_parcelas',
              borrower_id: borrowerId,
            });

            openModal({
              title: 'todas as parcelas',
              content: (
                <ModalViewAllInstallments installments={offer.modalData} />
              ),
            });
          }}
        >
          Ver todas as parcelas
        </S.TextViewAllInstallments>
      )}

      {offer.button?.text && (
        <PanelButton onClick={offer.button.onClick || null}>
          {offer.button.text}
        </PanelButton>
      )}
    </S.ContainerOptionsCardItem>
  );
}
