import { FiArrowDown } from 'react-icons/fi';

import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const Container = styled.div`
  align-items: center;
  border: 1px solid ${colors.greyborders};
  border-radius: 24px;
  color: ${colors.noverde};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  margin-bottom: 12px;
  padding: 0.75rem;
  position: relative;
  width: 100%;
`;

export const Icon = styled(FiArrowDown)`
  color: ${colors.text};
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  margin-right: 1rem;
  right: 0;
  z-index: 1;
`;
