import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import { PaymentPolling } from './components/PaymentPolling';

export function PagePaymentPooling() {
  return (
    <DashBoardTemplate headerTitle="Processando pagamento" withSocial={false}>
      <PaymentPolling />
    </DashBoardTemplate>
  );
}
