import { useEffect, useState } from 'react';

import { RadioItem } from 'components/RadioItem';
import { useModal } from 'hooks/useModal';

import { ModalViewAllInstallmentsData } from '../../ModalViewAllInstallments';
import { PanelButton } from '../PanelButton';

import * as S from './style';

export type OfferItemType = {
  description: string;
  value: string | number;
  button?: {
    text: string;
    onClick: () => void;
  };
  modalData?: ModalViewAllInstallmentsData[];
  period?: string | number;
};

type OfferItemProps = {
  offers: OfferItemType[];
  selectedOffer: number;
};

export function OfferOptions({
  offers,
  selectedOffer: currentSelectedOffer,
}: OfferItemProps) {
  const { closeModal } = useModal();
  const [selectedOptions, setSelectedOptions] = useState<boolean[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<OfferItemType>(
    {} as OfferItemType,
  );
  const [disabledOption, setDisabledOption] = useState<boolean>(true);

  useEffect(() => {
    if (offers.length > 0) {
      setSelectedOptions(
        offers.map((_, index) => index === currentSelectedOffer),
      );
    }
  }, [offers]);

  const handleSelectedOffer = (index: number) => {
    const options = [...selectedOptions].map(() => false);

    options[index] = !options[index];

    setSelectedOptions(options);

    setSelectedOffer(offers[index]);

    setDisabledOption(false);
  };

  return (
    <S.ContainerOptionsItem>
      <S.RadioGroup>
        {offers.map((offer, index) => (
          <RadioItem
            key={offer.value}
            content={`${offer.period}x de ${offer.value}`}
            selected={selectedOptions[index]}
            onClick={() => handleSelectedOffer(index)}
          />
        ))}
      </S.RadioGroup>

      <S.ContainerButton>
        {offers.length > 0 && (
          <PanelButton
            disabled={disabledOption}
            {...(selectedOffer?.button?.onClick && {
              onClick: selectedOffer.button.onClick,
            })}
          >
            quero essa!
          </PanelButton>
        )}

        <PanelButton variant="secondary" onClick={closeModal}>
          voltar
        </PanelButton>
      </S.ContainerButton>
    </S.ContainerOptionsItem>
  );
}
