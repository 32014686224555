import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useLocationState } from 'hooks/useLocationState';
import { PaymentSummaryDashboard } from 'pages/Dashboard/components/PaymentSummaryDashboard';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';
import { CheckoutTypes } from 'types/Checkout/CheckoutTypes';

export function CheckoutSummaryDashboard() {
  const { location } = useHistory<CheckoutTypes>();
  const { verifyLocationState } = useLocationState();

  useEffect(() => {
    verifyLocationState('/dashboard');
  }, []);

  return (
    <DashBoardTemplate headerTitle="Resumo do pagamento">
      <PaymentSummaryDashboard {...location.state} />
    </DashBoardTemplate>
  );
}
