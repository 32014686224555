/* eslint-disable react/no-array-index-key */
import ReactDOM from 'react-dom';

import { ModalViewAllInstallmentsData } from '../ModalViewAllInstallments';
import { OfferOptions } from '../RenegotiationPanel/OfferOptions';
import { SupModalInstallments } from './SupModalInstallments';

import * as S from './style';

type ModalMoreOffersProps = {
  offers: {
    description: string;
    value: string | number;
    button?: {
      text: string;
      onClick: () => void;
    };
    modalData?: ModalViewAllInstallmentsData[];
    period?: string | number;
  }[];
  selectedOffer: number;
};

export function ModalMoreOffers({
  offers,
  selectedOffer,
}: ModalMoreOffersProps) {
  const click = () => {
    return ReactDOM.createPortal(
      <SupModalInstallments />,
      document.getElementById('root') as HTMLElement,
    );
  };

  return (
    <S.Container onClick={click}>
      <OfferOptions offers={offers} selectedOffer={selectedOffer} />
    </S.Container>
  );
}
