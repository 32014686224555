import { ReactNode } from 'react';

import { LoaderIconStyled } from 'styles/loader';

import { InterceptBackBrowser } from '../InterceptBackBrowser';

import * as S from './style';

interface LoadingPageProps {
  title?: ReactNode | string;
  text?: ReactNode | string;
}

export function LoadingPage({
  title = 'Carregando o conteúdo',
  text = 'Aguarde alguns instantes',
}: LoadingPageProps) {
  return (
    <S.LoadingPageContainer>
      <S.Content>
        <InterceptBackBrowser interceptRedirect="/dashboard" />

        <S.TitleStyled>{title}</S.TitleStyled>

        <LoaderIconStyled width={40} height={40} />

        <S.TextStyled>{text}</S.TextStyled>
      </S.Content>
    </S.LoadingPageContainer>
  );
}
