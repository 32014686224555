import { ReactNode } from 'react';

import { AlignSelfProps } from 'contexts/ModalContext';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';

import { colors } from './colors';
import { device } from './medias';

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 0;

  height: 100vh;
  width: 100%;

  z-index: 999999;

  @media ${device.sm} {
    align-items: center;
    flex-direction: column;
  }
`;

export const ModalLayer = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;

  background-color: ${transparentize(0.4, colors.galaxy)};

  z-index: 1;
`;

interface ModalContentProps {
  size?: number;
  alignSelf: AlignSelfProps;
  fixedContent?: ReactNode;
  noBackgroundColor?: boolean;
}

interface ModalHolderProps {
  removedScroll?: boolean;
  mb: number;
}

export const ModalContent = styled(motion.div)<ModalContentProps>`
  ${({ size, alignSelf, noBackgroundColor }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: auto;
    max-height: calc((100vh / 4) * 3.6);
    min-height: 250px;
    padding: 16px;

    z-index: 2;
    position: relative;

    @media ${device.sm} {
      width: ${size}px;
      border-radius: 3px 0px 0px 3px;
      padding: 0;
    }

    @media ${device.lg} {
      max-height: calc((100vh / 4) * 3);

      background-color: ${!noBackgroundColor && colors.white};
      align-self: ${alignSelf};

      margin: 0 calc((50vw - ${size}px) / 2);
    }
  `}
`;

export const ModalHolder = styled.div<ModalHolderProps>`
  position: relative;

  max-height: 100vh;
  overflow-y: ${({ removedScroll }) => (removedScroll ? 'hidden' : 'scroll')};
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  @media ${device.sm} {
    overflow-y: auto;
  }
`;

export const ModalClose = styled.i`
  font-size: 28px;
  padding: 16px;

  color: ${colors.white};

  @media ${device.sm} {
    padding: 10px;
  }

  position: absolute;
  right: 0;
  top: 0;

  cursor: pointer;
  z-index: 999;
`;

export const ModalTitle = styled.h1`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  padding: 0 20px;
  margin-bottom: 16px;
  min-height: 2px;

  color: ${colors.galaxy};
`;

export const ModalFixedContent = styled.div`
  width: 100%;
  padding: 16px;

  background-color: ${colors.white};

  position: sticky;
  bottom: 0;
  left: 0;

  z-index: 9999;
`;

interface TopImage {
  bgImage: string;
}

export const Top = styled.div<TopImage>`
  ${({ bgImage }) => css`
    width: 100%;
    height: 288px;
    background-color: ${colorsV2['support-baker-pink']};
    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `}
`;

export const Content = styled.div`
  background-color: ${colors.galaxy};
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;
