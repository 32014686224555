import { Button } from 'components/Button';
import styled from 'styled-components';

export const TextStyled = styled.p`
  text-align: center;
  margin: 32px;
`;

export const ButtonStyled = styled(Button)`
  margin: 32px 0;
  max-width: 375px;
`;
