import styled from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

export const LoadingPageContainer = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 564px;

  margin: auto;
  padding: 16px;

  display: flex;
  justify-content: flex-start;

  @media ${device.sm} {
    height: 100vh;
  }
`;

export const TitleStyled = styled.p`
  color: ${colors.galaxy};
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  letter-spacing: 0.01em;
  text-align: center;
  @media ${device.lg} {
    align-self: flex-start;
  }
`;

export const TextStyled = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  > p {
    line-height: 34px;
  }
`;

export const Content = styled.div`
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: column;
`;
