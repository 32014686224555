import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const OfferApprovedContainer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
`;

export const LinkNoverde = styled.a`
  color: ${colors.noverde};
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  padding: 16px;
  white-space: pre-wrap;
  > button {
    width: 330px;
    margin: 0 auto;

    &.secondary {
      margin-top: 16px;
    }
  }
`;

export const ContentHolder = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${colors.greyborders};
  text-align: left;
`;

export const DetailHolder = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${colors.greyborders};
  text-align: left;
  display: flex;
  justify-content: space-between;
`;

export const DetailsHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ApprovedValue = styled.h1`
  color: ${colors.galaxy};
  font-size: 24px;
  font-weight: 600;
`;

export const Bold = styled.p`
  color: ${colors.galaxy};
  font-weight: 700;
  text-align: left;
  padding: 5px 0;
`;

export const Value = styled.p`
  text-align: right;
  padding: 5px 0;
`;

interface TextProps {
  color?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
}

export const Text = styled.p<TextProps>`
  ${({ fontSize, color, fontWeight }) => css`
    color: ${color ? colors[color] : colors.text};
    font-size: ${fontSize || '14px'};
    font-weight: ${fontWeight || 400};
  `}
`;

interface StackProps {
  gap?: string;
  margin?: string;
  padding?: string;
}

export const Stack = styled.div<StackProps>`
  ${({ gap, margin, padding }) => css`
    display: flex;
    flex-direction: column;
    gap: ${gap || '10px'};
    margin: ${!!margin && margin};
    padding: ${!!padding && padding};
  `}
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid ${colors.greyborders};
`;
