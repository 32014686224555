import styled from 'styled-components';
import { colors } from 'styles/colors';

export const PasswordFieldStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;

  > div {
    width: 100%;
  }
`;

export const HelperTextStyled = styled.span`
  font-size: 12px;

  margin-top: 5px;
  margin-left: 15px;
`;

export const IconStyled = styled.i`
  position: absolute;
  padding: 16px;
  top: 2px;
  right: 2px;
  border-radius: 6px;
  font-size: 20px;
  line-height: 15px;
  cursor: pointer;
  background: ${colors.white};
`;
