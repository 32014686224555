import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem;
  position: relative;
  width: 100%;
`;
