/* eslint-disable no-shadow */
export enum WalletState {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  REFUSED = 'refused',
}

export const CreatePasswordVariants = {
  RESET: 'reset',
  MARKETPLACE: 'marketplace',
  CREATE_PASSWORD: 'create-password',
};

export const PermissionVariants = {
  GRANTED: 'granted',
  PROMPT: 'prompt',
  PROMPT_MOBILE: 'prompt-mobile',
  DENIED: 'denied',
};

export const DocumentsVariants = {
  SELFIE: 'selfie',
  LIFE_PROOF: 'life_proof',
  DOCUMENT_FRONT: 'document_front',
  DOCUMENT_BACK: 'document_back',
  INCOME_PROOF: 'income_proof',
  RESIDENCE_PROOF: 'residence_proof',
  BANK_ACCOUNT: 'bank_account',
};

export const LoanState = {
  SIMULATION: 'simulation',
  WAITING_CREDIT_ANALYSIS: 'waiting_credit_analysis',
  PROPOSAL: 'proposal',
  APPLICATION: 'application',
  WAITING_ACCEPTANCE: 'waiting_acceptance',
  WAITING_LENDING: 'waiting_lending',
  LENT: 'lent',
  SETTLED: 'settled',
  CANCELLED: 'cancelled',
  SUSPENDED: 'suspended',
  PENDING: 'pending',
};

export const RequestVariants = {
  GEOLOCATION: 'geolocation',
  CAMERA: 'camera',
};

export const LifeProofVariants = {
  WITHOUT_GLASSES: 'without_glasses',
  SMILING: 'smiling',
  SERIOUS: 'serious',
};

export enum SimulationRoutes {
  SIMULATION = 'simulation',
  COMPLEMENTARY = 'complementary',
  BANK_DATA = 'bank_data',
}

export const ScreensRequirements = {
  dashboard: ['name', 'document', 'birthdate', 'email'],
  'requested-amount': ['requested_amount'],
  'requested-config': ['requested_period', 'requested_payday'],
  'employment-status': ['employment_status'],
  income: ['income'],
  address: ['address'],
  'bank-account': ['bank_account'],
  'contract-sign': ['ccb'],
  documents: ['document_front', 'income_proof', 'residence_proof'],
  selfie: ['selfie'],
  proof: ['life_proof'],
};

export enum DenialReasons {
  INSUFFICIENT_BALANCE = 'insufficient_balance',
  ACCOUNT_NOT_FOUND = 'account_not_found',
  OPERATION_OUT_OF_DATE = 'operation_out_of_date',
  DENIED_CREDIT_ANALYSIS = 'denied_credit_analysis',
}
