import styled from 'styled-components';
import { colors } from 'styles/colors';

export const WalletsContainer = styled.div`
  align-items: center;
  background: ${colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  width: 100%;
`;

export const ModalExclusion = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  header {
    h2 {
      color: ${colors.black};
      font-size: 1.1rem;
      padding: 8px;
      text-align: center;
    }
  }

  article {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
      color: ${colors.noverde};
      height: 6rem;
      width: 6rem;
    }
    p {
      color: ${colors.text};
      font-size: 1.1rem;
      font-weight: 700;
    }
    span {
      color: ${colors.text};
      font-size: 1rem;
      font-weight: 400;
    }
  }

  footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      cursor: pointer;
      color: ${colors.text};
      font-size: 1rem;
      padding: 1rem 0;
      text-align: center;
      width: 100%;
    }
    .delete {
      color: ${colors.errors};
      font-weight: 600;
    }
  }
`;

export const ContainerCallout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 330px;
`;
