import styled, { css } from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';
import { device } from 'styles/medias';

interface Props {
  bgcolor: string;
  hasOneChild: boolean;
}

export const NavigationContainer = styled.div<Props>`
  ${({ bgcolor, hasOneChild }) => css`
    background-color: ${bgcolor === 'transparent' ? 'transparent' : bgcolor};
    min-height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${hasOneChild ? 'flex-end' : 'space-between'};
    padding: 0 16px;

    @media ${device.sm} {
      min-height: 72px;
    }
  `}
`;

export const IconHolder = styled.button<{ color: string }>`
  ${({ color }) => css`
    background-color: transparent;
    border: none;

    font-size: 24px;

    color: ${color};
  `}
`;

export const Title = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${colorsV2['neutral-ultra-dark']};
  font-weight: 400;

  @media ${device.sm} {
    font-size: 16px;
    line-height: 24px;
  }
`;
