import React, { createContext, useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import useWindowDimensions from 'hooks/useWindowDimensions';
import * as S from 'styles/modal';

export type AlignSelfProps = 'flex-start' | 'flex-end' | 'center';

interface ModalProps {
  alignSelf?: AlignSelfProps;
  noCloseButton?: boolean;
  size?: number;
  padding?: string;
  removeScroll?: boolean;
  title: string | React.ReactNode;
  content: React.ReactNode;
  fixedContent?: React.ReactNode;
  paddingFixedContent?: string;
  redirectOnClose?: string;
  closeButtonPosition?: 'left' | 'right';
  variant?: string;
}

interface ModalContextProps {
  isModalOpen?: boolean;
  openModal: (props: ModalProps) => void;
  closeModal: () => void;
  onModalClose?: () => void;
}

export const ModalContext = createContext<ModalContextProps>({
  openModal: () => null,
  closeModal: () => null,
  onModalClose: () => null,
});

interface ModalProviderProps {
  children?: React.ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [variantModal, setVariantModal] = useState('default');
  const [modalSize, setModalSize] = useState<number>(525);
  const [modalPadding, setModalPadding] = useState<string>();
  const [modalTitle, setModalTitle] = useState<string | React.ReactNode>();
  const [modalContent, setModalContent] = useState<React.ReactNode>();
  const [withCloseButton, setWithCloseButton] = useState<boolean>(true);
  const [closeBtnPosition, setCloseBtnPosition] = useState<'left' | 'right'>(
    'left',
  );
  const [withRemovedScroll, setWithRemovedScroll] = useState<boolean>(false);
  const [redirectOnCloseState, setRedirectOnClose] = useState<any>(null);
  const [alignSelf, setAlignSelf] = useState<AlignSelfProps>('center');
  const [blockEsc, setBlockEsc] = useState(false);
  const [fixedContent, setFixedContent] = useState<React.ReactNode>(null);
  const [fixedContentHeight, setFixedContentHeight] = useState(0);
  const [fixedContentPadding, setFixedContentPadding] = useState('');

  const { hidesm } = useWindowDimensions();
  const isMobile = !hidesm;
  const history = useHistory();

  const fixedContentRef = useRef<any>(null);

  useEffect(() => {
    setFixedContentHeight(fixedContentRef?.current?.clientHeight);
  }, [isModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27 && !blockEsc) {
        closeModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [blockEsc]);

  function openModal(props: ModalProps) {
    const {
      size,
      padding,
      title,
      content,
      noCloseButton,
      redirectOnClose,
      removeScroll,
      alignSelf: alignSelfProp,
      fixedContent: fixedContentProp,
      closeButtonPosition = 'left',
      paddingFixedContent = '16px',
      variant = 'default',
    } = props;

    setIsModalOpen(true);
    setModalSize(size ?? 493);
    setWithRemovedScroll(!!removeScroll);
    setModalPadding(padding);
    setModalTitle(title);
    setModalContent(content);
    setWithCloseButton(!noCloseButton);
    setRedirectOnClose(redirectOnClose);
    setBlockEsc(!!noCloseButton);
    setAlignSelf(alignSelfProp ?? 'center');
    setFixedContent(fixedContentProp ?? null);
    setCloseBtnPosition(closeButtonPosition);
    setFixedContentPadding(paddingFixedContent);
    setVariantModal(variant);
  }

  function closeModal() {
    setIsModalOpen(false);

    redirectOnCloseState && history.push(redirectOnCloseState);

    setRedirectOnClose(null);
  }

  const value = {
    isModalOpen,
    openModal,
    closeModal,
  };

  const variants = {
    mobile: { y: 0 },
    desktop: { opacity: 1, scale: 1 },
    mobileInitial: { y: '100vh' },
    desktopInitial: { opacity: 0, scale: 0.3 },
    mobileExit: { y: '100vh' },
    desktopExit: { opacity: 0, y: '20vh' },
  };

  return (
    <ModalContext.Provider value={value}>
      <AnimatePresence>
        {isModalOpen && (
          <S.ModalContainer>
            <S.ModalLayer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              onClick={closeModal}
            />
            <S.ModalContent
              animate={isMobile ? 'mobile' : 'desktop'}
              initial={isMobile ? 'mobileInitial' : 'desktopInitial'}
              exit={isMobile ? 'mobileExit' : 'desktopExit'}
              transition={{ duration: 0.3 }}
              variants={variants}
              size={modalSize}
              padding={modalPadding}
              alignSelf={alignSelf}
              fixedContent={fixedContent}
            >
              {withCloseButton && (
                <S.ModalClose
                  closeBtnPosition={closeBtnPosition}
                  onClick={closeModal}
                  variant={variantModal}
                >
                  <MdClose />
                </S.ModalClose>
              )}
              {modalTitle !== '' && (
                <S.ModalTitle variant={variantModal}>{modalTitle}</S.ModalTitle>
              )}
              <S.ModalHolder
                removedScroll={withRemovedScroll}
                mb={fixedContentHeight}
              >
                {modalContent}

                {fixedContent && (
                  <S.ModalFixedContent
                    ref={fixedContentRef}
                    padding={fixedContentPadding}
                  >
                    {fixedContent}
                  </S.ModalFixedContent>
                )}
              </S.ModalHolder>
            </S.ModalContent>
          </S.ModalContainer>
        )}
      </AnimatePresence>
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.defaultProps = {
  children: null,
};
