import { useContext } from 'react';

import { ModalBannerContext } from 'contexts/ModalBanner';

export const useModalBanner = () => {
  const ctx = useContext(ModalBannerContext);

  if (ctx === undefined) {
    throw new Error('useModal must be used inside a ModalContext');
  }

  return {
    isModalBannerOpen: ctx.isModalOpen,
    openModalBanner: ctx.openModal,
    closeModalBanner: ctx.closeModal,
  };
};
