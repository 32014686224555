import styled from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

export const Group = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${colors.greyborders};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 1rem;
  width: 100%;

  @media ${device.sm} {
    align-items: center;
    flex-direction: row;
  }

  span {
    color: ${colors.text};
    font-size: 0.875rem;
    font-weight: 400;
  }

  p {
    color: ${colors.black};
    font-size: 0.875rem;
    font-weight: 600;
  }
`;
