import { useEffect, useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { NavLink, useHistory } from 'react-router-dom';

import iconBoletoBancario from 'assets/img/boleto-bancario.svg';
import iconCartaoCredito from 'assets/img/cartao-credito.svg';
import iconPix from 'assets/img/pix.svg';
import { MessageCallout } from 'components/MessageCallout';
import { useDBTracking } from 'contexts/DBTrackingContext';
import { useLocationState } from 'hooks/useLocationState';
import { useModal } from 'hooks/useModal';
import { CheckoutTypes } from 'types/Checkout/CheckoutTypes';
import { isEmpty } from 'utils/helpers/is-empty';

import { AlertCard } from './AlertCard';
import { ModalCreditCardConfirmation } from './ModalCreditCardConfirmation';

import {
  Container,
  Card,
  Description,
  ContainerText,
  Title,
  Img,
} from './style';

type MethodProps = 'pix' | 'payment-slip' | 'creditcard';

export default function PaymentMethodsDashboard() {
  const {
    location: { state },
  } = useHistory<CheckoutTypes>();

  const { verifyLocationState } = useLocationState();

  useEffect(() => {
    verifyLocationState('/dashboard');
  }, []);

  const { setTrackingEvent } = useDBTracking();
  const [err, setErr] = useState('');
  const { openModal } = useModal();

  function handleOpenAlertCard() {
    sendTrackingEventPaymentMethod('payment-slip');

    openModal({
      title: 'pagamento com boleto',
      size: 360,
      content: <AlertCard />,
      closeButtonPosition: 'right',
    });
  }

  useEffect(() => {
    document.title = 'Dashboard - Payment methods';
  }, []);

  const handleOpenModalCreditCardConfirmation = () => {
    openModal({
      title: 'Cartão de crédito',
      size: 370,
      content: <ModalCreditCardConfirmation setErr={setErr} />,
      closeButtonPosition: 'right',
    });
  };

  const methods = {
    pix: (
      <NavLink
        key="pix"
        onClick={() => sendTrackingEventPaymentMethod('pix')}
        to={{
          pathname: '/dashboard/checkout-summary',
          state: {
            ...state,
            paymentType: 'pix',
          },
        }}
      >
        <Card>
          <Img src={iconPix} />
          <ContainerText>
            <Title>PIX</Title>
            <Description>Pague em tempo real gerando um código PIX</Description>
          </ContainerText>
          <FiArrowRight />
        </Card>
      </NavLink>
    ),
    'payment-slip': (
      <Card onClick={handleOpenAlertCard} key="payment-slip">
        <Img src={iconBoletoBancario} />
        <ContainerText>
          <Title>Boleto bancário</Title>
          <Description>
            Gere um boleto e pague como preferir: Internet banking, lotéricas e
            bancos
          </Description>
        </ContainerText>
        <FiArrowRight />
      </Card>
    ),
    creditcard: (
      <Card
        key="credicard"
        onClick={() => {
          sendTrackingEventPaymentMethod('creditcard');
          handleOpenModalCreditCardConfirmation();
        }}
      >
        <Img src={iconCartaoCredito} />
        <ContainerText>
          <Title>Cartão de crédito</Title>
          <Description>
            Pague online com seu cartão de crédito de preferência
          </Description>
        </ContainerText>
        <FiArrowRight />
      </Card>
    ),
  };

  function sendTrackingEventPaymentMethod(method: MethodProps) {
    setTrackingEvent({
      category: 'dashboard-payment_method',
      action: 'payment_method_selected',
      details: {
        method,
      },
      loanUuid: true,
      deviceId: true,
    });
  }

  return (
    <Container>
      {!isEmpty(state?.availableMethods) &&
        state.availableMethods.map((method) => methods[method])}
      {err && (
        <MessageCallout title="Cartão de crédito" message={err} type="error" />
      )}
      <br />
    </Container>
  );
}
