import { useEffect, useState } from 'react';

import { useModal } from 'hooks/useModal';
import { ScreenLoader } from 'pages/Dashboard/components/ScreenLoader';
import LoansService from 'services/PlatformLoans/LoansService';

import { Container, ItemContainer, Document } from './style';

export const ContractsDashboard = () => {
  const { openModal } = useModal();

  const [contracts, setContracts] = useState<any>([]);

  async function requestAgreements() {
    const { data } = await LoansService.getAgreementsByLoan(
      'privacy_policy,letter_of_attorney,assembled_terms_of_use,ccb',
    );

    const { documents } = data.data;

    setContracts(documents);
  }

  useEffect(() => {
    requestAgreements();
  }, []);

  const handleOpenCCB = (file) => {
    const base64 = file;
    const byteCharacters = atob(base64);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_blank';

    link.click();
  };

  return contracts.length <= 0 ? (
    <ScreenLoader />
  ) : (
    <Container>
      {contracts.map((item, key) => (
        <ItemContainer key={String(key as number)}>
          <p>{item.name}</p>
          <span
            onClick={() => {
              item.content_type === 'pdf'
                ? handleOpenCCB(item.content)
                : openModal({
                    title: '',
                    content: (
                      <Document>
                        <span
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.content.replace('padding: 20px;', ''),
                          }}
                        />
                      </Document>
                    ),
                  });
            }}
          >
            Visualizar
          </span>
        </ItemContainer>
      ))}
    </Container>
  );
};
