import { IoInformationCircleOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';

import { getThemeColors } from 'styles/helpers/getThemeColors';
import { TooltipTitle, TooltipContent } from 'styles/miscellany';

import { colorsV2 } from '../../styles/colors-v2';
import { TextContainer } from './style';

type InputType = React.InputHTMLAttributes<HTMLInputElement>;

interface TextWithTooltipProps {
  titleTooltip: string;
  contentTooltip: string;
  fgts?: boolean;
}

export function TextWithTooltip(props: TextWithTooltipProps & InputType) {
  const colors = getThemeColors();
  const { titleTooltip, contentTooltip, fgts, children } = props;
  return (
    <TextContainer>
      {children}
      <ReactTooltip
        id={`${titleTooltip}tooltip`}
        effect="solid"
        className="react-tooltip"
        backgroundColor={colors.galaxy}
      >
        <TooltipTitle>{titleTooltip}</TooltipTitle>
        <TooltipContent>{contentTooltip}</TooltipContent>
      </ReactTooltip>
      <span data-tip data-for={`${titleTooltip}tooltip`}>
        <IoInformationCircleOutline
          color={fgts ? colorsV2['support-soft-blue'] : colors.noverde}
        />
      </span>
    </TextContainer>
  );
}
