import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const TopLoaderContainer = styled.div`
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 9999999;
`;

export const TopLoaderBar = styled.div`
  height: 4px;
  width: 80%;

  background-color: ${colors.noverde};
  display: flex;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 9999999;

  animation: width 4s linear;

  @keyframes width {
    0% {
      width: 0%;
    }
    100% {
      width: 80%;
    }
  }
`;
