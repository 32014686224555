import { Presentation } from 'components/Presentation';
import styled from 'styled-components';

export const UpdatePhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  min-height: calc(100vh - (60px * 2));
  max-width: 525px;
  margin: 60px auto;
  padding: 0 16px;
`;

export const PhonePresentation = styled(Presentation)`
  margin-bottom: 40px;
`;

export const InfoContainer = styled.div`
  margin-bottom: 32px;
`;
