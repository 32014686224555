import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const Input = styled.input`
  ${({ height, width }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: ${width ?? '18px'};
    height: ${height ?? '18px'};
    border: 0.15rem solid ${colors.galaxy};
    border-radius: 0.2rem;
    transition: background border 0.3s ease-in-out;
    position: relative;
    outline: none;
    transition: 0.2s ease-in-out;
    &:before {
      content: '';
      width: 0.2rem;
      height: 0.5rem;
      border: 0.15rem solid ${colors.white};
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      opacity: 0;
      transition: 0.2s ease-in-out;
    }
    &:hover {
      border-color: ${colors.placeholders};
    }
    &:checked {
      border-color: ${colors.noverde};
      background: ${colors.noverde};
      &:before {
        opacity: 1;
      }
    }
    &:disabled {
      cursor: not-allowed;
    }
  `}
`;
