import { ReactNode } from 'react';

import { colorsV2 } from 'styles/colors-v2';

import { Container, ContainerButton } from './style';

export interface ContainerProps {
  children: ReactNode;
  background?: keyof typeof colorsV2;
  position?: string;
  width?: number | string;
  borderRadius?: string;
  hasOneChild?: boolean;
  border?: string;
  borderTop?: string;
}

export function FooterBar({ children, hasOneChild, ...props }: ContainerProps) {
  return (
    <Container {...props}>
      <ContainerButton hasOneChild={hasOneChild}>{children}</ContainerButton>
    </Container>
  );
}
