import { createContext, useContext, useMemo, useState } from 'react';

import DocumentsService from 'services/PlatformLoans/DocumentsService';
import {
  ApplicationType,
  CreditAnalysisType,
  RequirementsType,
} from 'types/Loans/RequirementsType';

interface RequirementsContextProps {
  applicationRequirements: ApplicationType[];
  creditAnalysisRequirements: CreditAnalysisType[];
  getApplicationRequirements: () => Promise<ApplicationType[]>;
  getCreditAnalysisRequirements: () => Promise<CreditAnalysisType[]>;
  fetchRequirements: () => Promise<RequirementsType>;
  fetchRequirementsByLoanUid: (uid: string) => Promise<RequirementsType>;
}

const RequirementsContext = createContext({} as RequirementsContextProps);

export const RequirementsProvider = ({ children = null }) => {
  const [application, setApplication] = useState<ApplicationType[]>([]);
  const [creditAnalysisRequirements, setCreditAnalysis] = useState<
    CreditAnalysisType[]
  >([]);

  async function fetchRequirements(): Promise<RequirementsType> {
    try {
      const { data } = await DocumentsService.getRequirements();
      const requirements: RequirementsType = data.data;

      setApplication(requirements.application);
      setCreditAnalysis(requirements.credit_analysis);

      return requirements;
    } catch {
      throw Error;
    }
  }

  async function fetchRequirementsByLoanUid(
    uid: string,
  ): Promise<RequirementsType> {
    try {
      const { data } = await DocumentsService.getRequirementsByLoanUid(uid);
      const requirements: RequirementsType = data.data;

      setApplication(requirements.application);
      setCreditAnalysis(requirements.credit_analysis);

      return requirements;
    } catch {
      throw Error;
    }
  }

  async function getApplicationRequirements(): Promise<ApplicationType[]> {
    const requirements: RequirementsType = await fetchRequirements();

    return requirements.application;
  }

  async function getCreditAnalysisRequirements(): Promise<
    CreditAnalysisType[]
  > {
    const requirements: RequirementsType = await fetchRequirements();

    return requirements.credit_analysis;
  }

  const value = {
    applicationRequirements: application,
    creditAnalysisRequirements,
    getApplicationRequirements,
    getCreditAnalysisRequirements,
    fetchRequirements,
    fetchRequirementsByLoanUid,
  };

  return (
    <RequirementsContext.Provider value={value}>
      {children}
    </RequirementsContext.Provider>
  );
};

export const useRequirements = () => {
  const ctx = useContext(RequirementsContext);

  return useMemo(() => ctx, [ctx]);
};
