import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useLocationState } from 'hooks/useLocationState';
import { PixPaymentDashboard } from 'pages/Dashboard/components/PixPaymentDashboard';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

export interface PixPaymentRouteProps {
  total: string;
  pix: {
    dueDate: string;
    emvqrcps: string;
    id: number;
    transactionId: string;
  };
  receiver: {
    bank: string;
    cnpj: string;
    key: string;
    name: string;
  };
}

export function PagePixPayment() {
  const history = useHistory();
  const { state } = useLocation<PixPaymentRouteProps>();
  const { cleanAndReturn, verifyLocationState } = useLocationState();

  useEffect(() => {
    verifyLocationState('/dashboard');
  }, []);

  return (
    <DashBoardTemplate
      headerTitle="Pagamento via PIX"
      handleReturn={() =>
        cleanAndReturn({
          targetRoute: '/dashboard',
          origin: history.location.pathname,
        })
      }
    >
      <PixPaymentDashboard
        total={state?.total || ''}
        pix={{
          dueDate: state?.pix?.dueDate || '',
          emvqrcps: state?.pix?.emvqrcps || '',
          id: state?.pix?.id || 0,
          transactionId: state?.pix?.transactionId || '',
        }}
        receiver={{
          bank: state?.receiver?.bank || '',
          cnpj: state?.receiver?.cnpj || '',
          key: state?.receiver?.key || '',
          name: state?.receiver?.name || '',
        }}
      />
    </DashBoardTemplate>
  );
}
