import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button } from 'components/Button';
import { useDBTracking } from 'contexts/DBTrackingContext';
import { useModal } from 'hooks/useModal';
import { BoletoPaymentRouteProps } from 'pages/Dashboard/boleto-payment';
import PaymtService from 'services/Paymt/PaymtService';
import { storageKeys } from 'store/storage-keys';
import { formatDate } from 'utils/formatters/format-date';
import { toBrl } from 'utils/formatters/to-brl';
import { base64ToArrayBuffer } from 'utils/helpers/base64-to-array-buffer';
import { storage } from 'utils/storage';
import { useStore } from 'zstore';

import {
  Box,
  CodigoBarras,
  Title,
  LineGroup,
  ButtonGroup,
  ModalContainer,
} from './style';

export const BoletoPaymentDashboard = ({
  dueDate = '',
  paymentId = '',
  digitableLine = '',
  total = '',
}: BoletoPaymentRouteProps) => {
  const { openModal, closeModal } = useModal();
  const { setTrackingEvent } = useDBTracking();
  const [downloadUrl, setDownloadUrl] = useState<any>();
  const [existBoleto, setExistBoleto] = useState(false);
  const { setContractInfo } = useStore();
  const hideDownloadButton =
    storage.getItem(storageKeys.hideDownloadButton) === 'true';

  async function downloadBankSlip() {
    try {
      if (!paymentId) return;
      const { data } = await PaymtService.getPaymentSlipPdf(paymentId);

      const arrBuffer = base64ToArrayBuffer(data.content);

      const blobFile = new Blob([arrBuffer], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(blobFile);
      setDownloadUrl(url);
      setExistBoleto(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Não foi possível baixar o boleto');
    }
  }

  function handleCopyBoleto() {
    openModal({
      title: 'código copiado',
      content: (
        <ModalContainer>
          <p>
            Depois de pago temos até <strong>2 dias</strong> úteis para
            identificar o pagamento
          </p>
          <Button onClick={closeModal}>entendi</Button>
        </ModalContainer>
      ),
      size: 320,
    });

    setTrackingEvent({
      category: 'dashboard-boleto',
      action: 'boleto_digitable_line_button_click',
      loanUuid: true,
      deviceId: true,
    });
  }

  useEffect(() => {
    if (paymentId) downloadBankSlip();

    setContractInfo([]);
  }, [paymentId]);

  return (
    <Box>
      <Title>Esse é o seu código de barras para o pagamento do boleto</Title>
      <CodigoBarras id="codigo-barras">{digitableLine}</CodigoBarras>
      <LineGroup>
        <div>
          <p>Valor total</p>
          <span>{toBrl(total)}</span>
        </div>
        <div>
          <p>Vencimento do boleto</p>
          <span>
            {formatDate(dueDate, {
              format: 'DD/MM/YYYY',
            })}
          </span>
        </div>
      </LineGroup>
      <ButtonGroup>
        <CopyToClipboard
          text={document.getElementById('codigo-barras')?.innerHTML}
          onCopy={handleCopyBoleto}
        >
          <Button>copiar código</Button>
        </CopyToClipboard>

        {!hideDownloadButton && (
          <a href={downloadUrl} download={`${digitableLine}.pdf`}>
            <Button
              disabled={!existBoleto}
              className={!existBoleto ? 'primary' : 'secondary'}
            >
              {existBoleto ? 'baixar boleto' : 'aguarde o boleto'}
            </Button>
          </a>
        )}
      </ButtonGroup>
    </Box>
  );
};
