import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { FetchAddressType } from 'types/Address/AddressType';

class FetchAddressService extends HttpClient {
  public constructor() {
    super(Services.Address);
  }

  public getAddress = (body: FetchAddressType) => {
    return this.instance.get(`/zipcode/${body.zipcode}`);
  };
}

export default new FetchAddressService();
