import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';

class UpdatePhoneService extends HttpClient {
  public constructor() {
    super(Services.Authorizer);
  }

  public changePhone = (mobileNumber: string) => {
    const requestBody = {
      mobile_number: mobileNumber,
    };

    return this.instance.post('/mobile-number/change', requestBody);
  };

  public confirmPhone = (pin: string) => {
    const requestBody = {
      pin,
    };

    return this.instance.post('/mobile-number/confirm', requestBody);
  };
}

export default new UpdatePhoneService();
