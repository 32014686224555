import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';

import { ResponseRetriveTermsOfUse } from './TermsOfUseService.types';

class TermsOfUseService extends HttpClient {
  public constructor() {
    super(Services.Agreements);
  }

  public acceptTerms = (body) => {
    const requestBody = {
      agreement_ids: body.agreementsIds,
    };

    return this.instance.post('', requestBody);
  };

  public retriveTermsOfUse = () => {
    return this.instance.get<ResponseRetriveTermsOfUse[]>(
      '?kind=privacy_policy,assembled_terms_of_use',
    );
  };

  public updateAgreements = (kind: string, product: string) => {
    return this.instance.get(`/update?kind=${kind}&product_slug=${product}`);
  };
}

export default new TermsOfUseService();
