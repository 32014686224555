import { useContext, useEffect } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { MockContext } from 'contexts/MockContext';
import { mask } from 'remask';
import {
  clearCurrencyStr,
  clearCurrencyStrBrl,
  onlyAlphabet,
  toCurrency,
  toCurrencyBrl,
} from 'utils/masks';
import { valuesMock } from 'utils/mock/mock-values';

import { ConnectForm } from '../ConnectForm';

import { HelperTextStyled, InputStyled } from './style';

type InputType = React.InputHTMLAttributes<HTMLInputElement>;

interface FieldProps {
  pattern?: string | undefined;
  validation?: any | undefined;
  helperText?: React.ReactNode | undefined;
  marginLeft?: string | number;
}

export function Field(props: FieldProps & InputType) {
  const {
    name,
    pattern,
    validation,
    helperText,
    onChange,
    onInput,
    marginLeft,
    ...rest
  } = props;
  const mocks = valuesMock();
  const { fillForm, setFillForm } = useContext(MockContext);

  function handleChange(e) {
    if (pattern === 'money') {
      const val = clearCurrencyStr(e.target.value);

      e.target.value = toCurrency(val);
    } else if (pattern === 'moneyPrefix') {
      const val = clearCurrencyStrBrl(e.target.value);

      e.target.value = toCurrencyBrl(mask(val, '9999999'));
    } else if (pattern === 'onlyAlphabet') {
      const val = onlyAlphabet(e.target.value);
      e.target.value = val;
    } else if (pattern) {
      e.target.value = mask(e.target.value, pattern);
    }

    if (onChange) onChange(e);
  }

  function handleInput(e) {
    if (e.target.type === 'file') return;

    e.target.value = e.target.value.replace(
      /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]|\uFE0F\u20E3/g,
      '',
    );

    if (onInput) onInput(e);
  }

  return (
    <ConnectForm>
      {({ register, errors, control, setValue }) => {
        const nameField = name ?? '';
        const errorMessage = errors[nameField]?.message;

        useEffect(() => {
          if (fillForm) {
            Object.keys(control.fieldsRef.current).map((fieldName) => {
              setValue(fieldName, mocks[fieldName], {
                shouldDirty: true,
                shouldValidate: true,
              });

              return '';
            });
          }

          return setFillForm(false);
        }, [fillForm]);

        return (
          <>
            <InputStyled
              {...rest}
              name={name}
              ref={register(validation)}
              onChange={handleChange}
              onInput={handleInput}
              className={errorMessage && 'error'}
            />
            {errorMessage ? (
              <HelperTextStyled marginLeft={marginLeft}>
                <ErrorMessage errors={errors} name={nameField} />
              </HelperTextStyled>
            ) : (
              helperText && <HelperTextStyled>{helperText}</HelperTextStyled>
            )}
          </>
        );
      }}
    </ConnectForm>
  );
}

Field.defaultProps = {
  pattern: null,
  validation: null,
  helperText: null,
  marginLeft: 0,
};
