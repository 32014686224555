export function SupModalInstallments() {
  return (
    <p
      style={{
        width: '320px',
        backgroundColor: 'red',
        height: '120px',
      }}
    >
      plk
    </p>
  );
}
