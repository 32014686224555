/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-var */
/* eslint-disable import/no-mutable-exports */
import Certiface, { RetrieveAnalysesData } from 'services/Certiface/Certiface';
import * as FaceTecStringsPtBr from '../../../public/core-sdk-optional/FaceTecStrings.pt-br.js';
import { FaceTecSDK } from '../../../public/core-sdk/FaceTecSDK.js/FaceTecSDK.js';
import { LivenessCheckProcessor } from './processors/LivenessCheckProcessor';
import { useStore } from 'zstore';
import { colorsV2 } from 'styles/colors-v2';

export var Liveness = (function () {
  let latestEnrollmentIdentifier = '';
  let latestSessionResult;
  let latestIDScanResult;
  let latestProcessor;
  let appkey;
  let uuid;
  let sessionToken;

  async function init(data) {
    const { device_key, production_key, pub_key, session_token } = data;
    setSessionToken(session_token);

    FaceTecSDK.setResourceDirectory('./core-sdk/FaceTecSDK.js/resources');

    FaceTecSDK.setImagesDirectory('./core-sdk/FaceTec_images');

    let currentCustomization = new FaceTecSDK.FaceTecCustomization();
    const white = colorsV2['action-contrast-static'];
    const noverdeColor = colorsV2['brand-primary'];
    const emeraldColor = colorsV2['action-navigation-hover'];
    const brandTertiaryColor = colorsV2['brand-tertiary'];
    const ultraDarkColor = colorsV2['neutral-ultra-dark'];
    const mediumColor = colorsV2['neutral-medium'];

    currentCustomization.initialLoadingAnimationCustomization.messageTextColor = ultraDarkColor;
    currentCustomization.initialLoadingAnimationCustomization.backgroundColor = brandTertiaryColor;
    currentCustomization.initialLoadingAnimationCustomization.foregroundColor = noverdeColor;

    currentCustomization.frameCustomization.borderWidth = '0px';

    currentCustomization.feedbackCustomization.backgroundColor = ultraDarkColor;

    currentCustomization.ovalCustomization.strokeColor = mediumColor;
    currentCustomization.ovalCustomization.progressColor1 = mediumColor;
    currentCustomization.ovalCustomization.progressColor2 = mediumColor;
    
    currentCustomization.guidanceCustomization.foregroundColor = ultraDarkColor;

    currentCustomization.guidanceCustomization.buttonTextNormalColor = white;
    currentCustomization.guidanceCustomization.buttonTextHighlightColor = white;
    currentCustomization.guidanceCustomization.buttonTextDisabledColor = white;

    currentCustomization.guidanceCustomization.buttonBackgroundNormalColor = noverdeColor;
    currentCustomization.guidanceCustomization.buttonBackgroundHighlightColor = emeraldColor;
    currentCustomization.guidanceCustomization.buttonBackgroundDisabledColor = brandTertiaryColor;

    currentCustomization.guidanceCustomization.readyScreenHeaderTextColor = ultraDarkColor;
    currentCustomization.guidanceCustomization.readyScreenSubtextTextColor = mediumColor;
    
    currentCustomization.guidanceCustomization.retryScreenHeaderTextColor = ultraDarkColor;
    currentCustomization.guidanceCustomization.retryScreenSubtextTextColor = mediumColor;

    currentCustomization.guidanceCustomization.cameraPermissionsScreenImage = './camera.svg';
    
    currentCustomization.cancelButtonCustomization.customImage = './chevron-left.svg';
    
    currentCustomization.overlayCustomization.brandingImage = './noverde-v-logo.svg';

    currentCustomization.resultScreenCustomization.activityIndicatorColor = noverdeColor;

    currentCustomization.resultScreenCustomization.foregroundColor = ultraDarkColor;

    currentCustomization.resultScreenCustomization.uploadProgressTrackColor = brandTertiaryColor;
    currentCustomization.resultScreenCustomization.uploadProgressFillColor = noverdeColor;

    currentCustomization.resultScreenCustomization.resultAnimationBackgroundColor = noverdeColor;
    currentCustomization.resultScreenCustomization.resultAnimationForegroundColor = white;

    currentCustomization.vocalGuidanceCustomization.mode = 2;  
    
    FaceTecSDK.setCustomization(currentCustomization);

    FaceTecSDK.initializeInProductionMode(
      production_key,
      device_key,
      pub_key,
      function (initializedSuccessfully) {
        if (initializedSuccessfully) {
          FaceTecSDK.configureLocalization(FaceTecStringsPtBr);
        }
      },
    );
  }

  // Initiate a 3D Liveness Check.
  function onLivenessCheckPressed() {
    // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.
    latestProcessor = new LivenessCheckProcessor(sessionToken, Liveness);
  }

  // Show the final result and transition back into the main interface.
  function onComplete() {
    if (!latestProcessor.isSuccess()) {
      // Reset the enrollment identifier.
      latestEnrollmentIdentifier = '';

      const sessionResultStatus = latestProcessor.latestSessionResult.status;

      onLivenessError(sessionResultStatus)

      return;
    }
  }

  //
  // DEVELOPER NOTE:  This is a convenience function for demonstration purposes only so the Sample App can have access to the latest session results.
  // In your code, you may not even want or need to do this.
  //
  function setLatestSessionResult(sessionResult) {
    latestSessionResult = sessionResult;
  }

  function setIDScanResult(idScanResult) {
    latestIDScanResult = idScanResult;
  }

  function getLatestEnrollmentIdentifier() {
    return latestEnrollmentIdentifier;
  }

  function setLatestServerResult(responseJSON) {
    // Void
  }

  function clearLatestEnrollmentIdentifier(responseJSON) {
    latestEnrollmentIdentifier = '';
  }

  async function onLivenessSuccess() {
    useStore.setState({ livenessStatus: 'sdk_completed' })

    try {
      const { data }: any = await Certiface.validateAnalysesData({ uuid })

      useStore.setState({ livenessStatus: data.status })
    } catch (data: any) {
      if (data.status) {
        useStore.setState({ livenessStatus: data.status })
      } else {
        useStore.setState({ livenessStatus: 'certiface_error' })
      }
    }
  }

  function setAppkey(value) {
    appkey = value.appkey;
    uuid = value.uuid;
  }

  function getAppkey() {
    return appkey;
  }

  function setSessionToken(value) {
    sessionToken = value;
  }

  function onLivenessError(sessionResultStatus) {
    const livenessStatus = FaceTecSDK.FaceTecSessionStatus[sessionResultStatus]

    useStore.setState({ livenessStatus })
  }

  function getSDKStatus() {
    return FaceTecSDK.getStatus()
  }

  return {
    onLivenessCheckPressed,
    onComplete,
    setLatestSessionResult,
    setIDScanResult,
    getLatestEnrollmentIdentifier,
    setLatestServerResult,
    setAppkey,
    getAppkey,
    clearLatestEnrollmentIdentifier,
    onLivenessSuccess,
    getSDKStatus,
    init,
  };
})();
