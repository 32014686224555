interface DeviceTypes {
  xs: `(min-width: 450px)`;
  sm: `(min-width: 768px)`;
  md: `(min-width: 992px)`;
  lg: `(min-width: 1024px)`;
  xl: `(min-width: 1440px)`;
}

export const device: DeviceTypes = {
  xs: `(min-width: 450px)`,
  sm: `(min-width: 768px)`,
  md: `(min-width: 992px)`,
  lg: `(min-width: 1024px)`,
  xl: `(min-width: 1440px)`,
};
