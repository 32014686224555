export const DEV_ENV = process.env.NODE_ENV === 'development';
export const PRD_ENV = process.env.NODE_ENV === 'production';

export const firebaseConfigDevelopment = {
  apiKey: 'AIzaSyBwsVuR_Gq-xxxfIBg5twJvsR-_dw6NJvw',
  authDomain: 'stg-noverde.firebaseapp.com',
  databaseURL: 'https://stg-noverde.firebaseio.com',
  projectId: 'stg-noverde',
  storageBucket: 'stg-noverde.appspot.com',
  messagingSenderId: '164448276976',
  appId: '1:164448276976:web:0e651c6a399d89de614d35',
  measurementId: 'G-C4Y3L2VS4S',
};

export const firebaseConfigProduction = {
  apiKey: 'AIzaSyCvF-9cvKExcfBbSiVYdaAz5tjmyNIsTTA',
  authDomain: 'noverde-b8874.firebaseapp.com',
  databaseURL: 'https://noverde-b8874.firebaseio.com',
  projectId: 'noverde-b8874',
  storageBucket: 'noverde-b8874.appspot.com',
  messagingSenderId: '426744293040',
  appId: '1:426744293040:web:cf0ab508baadccd10f3d75',
  measurementId: 'G-SN2CSZJ9HP',
};

export const FIREBASE_CONFIG =
  process.env.REACT_APP_FIREBASE_CONFIG === 'development'
    ? firebaseConfigDevelopment
    : firebaseConfigProduction;
