import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

type QuantityBoxProps = {
  color: string;
  textColor?: string;
};

interface BoxGridProps {
  disable?: boolean;
}
export const BoxGrid = styled.label<BoxGridProps>`
  ${({ disable }) => css`
    align-items: center;
    border-bottom: 1px solid ${colors.greyborders};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    min-width: 15rem;
    padding-bottom: 12px;
    width: 100%;

    ${!!disable && `opacity: 0.5`};
    ${!!disable && `cursor: not-allowed`};

    @media ${device.md} {
      min-width: 25rem;
    }
  `}
`;

export const BoxDescription = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  height: 100%;
  align-items: center;

  > div {
    align-items: center;
    display: flex;
    gap: 0.875rem;
    justify-content: space-between;
    @media ${device.sm} {
      gap: 2rem;
    }
  }
`;

export const QuantityBox = styled.div<QuantityBoxProps>`
  ${({ color, textColor }) => css`
    background-color: ${color ?? colors.greyborders};
    border-radius: 4px;
    color: ${textColor ?? colors.white};
    display: grid;
    height: 45px;
    place-items: center;
    width: 45px;
  `}
`;
export const Vencimento = styled.p`
  color: ${colors.text};
  font-size: 14px;
  font-weight: 400;
`;

export const Valor = styled.p`
  color: ${colors.galaxy};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  span {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    color: ${colors.noverde};
  }
`;

export const Description = styled.span`
  color: ${colors.disable};
  font-size: 0.75rem;
  font-weight: 400;
`;
