import styled from 'styled-components';
import { colors } from 'styles/colors';
import { device } from 'styles/medias';

export const TopBanner = styled.div`
  width: 100%;
  background-color: ${colors.noverde};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;

  p {
    color: ${colors.white};
    font-size: 16px;
  }
`;

export const OfferModal = styled.div`
  padding: 40px 24px;

  h2 {
    color: ${colors.galaxy};
    font-weight: normal;
  }
`;

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ApprovedValue = styled.h1`
  color: ${colors.white};
  font-size: 36px;
  font-weight: 600;

  @media ${device.sm} {
    font-size: 48px;
  }
`;

export const Money = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ContainerSlider = styled.div`
  min-width: 100%;
  max-width: 532px;
`;

export const FooterHolder = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ModalContent = styled.div`
  margin: 0 15px;
  white-space: pre-wrap;
  > button {
    margin: 0 auto;

    &.secondary {
      margin-top: 16px;
    }
  }
`;

export const InstallmentsDataHolder = styled.div`
  padding: 16px 8px;

  border-top: 1px solid ${colors.greyborders};
`;

export const LinkNoverde = styled.a`
  color: ${colors.noverde};
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  text-align: right;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: ${colors.galaxy};

  strong {
    font-size: 20px;
  }
`;
