/* eslint-disable import/no-duplicates */

import { TextWithTooltip } from 'components/TextWithTooltip';
import { addDays, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { OffersProps } from 'pages/OfferApproved';
import { IOfferProps } from 'services/Loans/Remote/V1/PartnerOfferLoans/types';
import { toCurrency, toCurrencyBrl } from 'utils/masks';

import {
  OfferApprovedContainer,
  ContentHolder,
  DetailsHolder,
  ApprovedValue,
  Bold,
  Value,
  DetailHolder,
} from './style';

type ProposalProps = {
  proposal: OffersProps | IOfferProps;
};

export function NewProposalDetail({ proposal }: ProposalProps) {
  const dueDate = addDays(new Date(proposal.first_due_date), 1);

  return (
    <OfferApprovedContainer>
      <ContentHolder>
        <p>Valor liberado</p>
        <ApprovedValue>
          {toCurrencyBrl(proposal.net_value?.toFixed(2))}
        </ApprovedValue>
        <p>
          1ª parcela{' '}
          {format(dueDate, 'dd MMMM yyyy', {
            locale: pt,
          })}{' '}
          -{' '}
          {format(dueDate, 'EEEE', {
            locale: pt,
          })}
        </p>
      </ContentHolder>

      <DetailHolder>
        <div>
          <p>Para pagar em</p>
          <Bold>
            {proposal.period}x{' '}
            {toCurrencyBrl(proposal.installment_value?.toFixed(2))}
          </Bold>
          <p>
            Com vencimento todo dia{' '}
            {format(dueDate, 'dd', {
              locale: pt,
            })}{' '}
            até{' '}
            {format(
              addDays(new Date(proposal.last_due_date), 1),
              "MMMM 'de' yyyy",
              {
                locale: pt,
              },
            )}
          </p>
        </div>
      </DetailHolder>
      <DetailsHolder>
        <div>
          <Bold>Taxa de juros</Bold>
          <TextWithTooltip
            titleTooltip="Tarifa de Cadastro"
            contentTooltip="Tarifa de cadastro é uma taxa prevista e regulamentada pelo Banco Central"
          >
            <Bold>Tarifa de cadastro</Bold>
          </TextWithTooltip>
          <TextWithTooltip
            titleTooltip="IOF"
            contentTooltip="IOF é a sigla de Imposto sobre Operações Financeiras de Crédito, Câmbio e Seguros. É um imposto federal do Brasil."
          >
            <Bold>IOF</Bold>
          </TextWithTooltip>
          <Bold>Custo efetivo total</Bold>
          <Bold>Valor financiado</Bold>
        </div>
        <div>
          <Value>
            {toCurrency(proposal.interest_rate?.toFixed(2))}% ao mês
          </Value>
          <Value>{toCurrencyBrl(proposal.partner_fee?.toFixed(2))}</Value>
          <Value>{toCurrencyBrl(proposal.iof_fee?.toFixed(2))}</Value>
          <Value>
            {toCurrency(proposal.annual_percentage_rate?.toFixed(2))}% ao ano
          </Value>
          <Value>{toCurrencyBrl(proposal.contract_value?.toFixed(2))}</Value>
        </div>
      </DetailsHolder>
    </OfferApprovedContainer>
  );
}
