import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';

class PendenciesService extends HttpClient {
  public constructor() {
    super(Services.PlatformLoans);
  }

  public getPendencies = () => {
    return this.instance.get(
      `${storage.getItem(storageKeys.loanUid)}/pendencies`,
    );
  };
}

export default new PendenciesService();
