import { FaEdit, FaCreditCard } from 'react-icons/fa';

import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 0.5rem #e8e8e8;
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  padding: 1.1rem 2rem;
  position: relative;
  width: fit-content;

  &:hover {
    box-shadow: 0 0 1rem #e8e8e8;
    cursor: pointer;
  }
`;

export const IconEdit = styled(FaEdit)`
  cursor: pointer;
  height: 18px;
  position: absolute;
  margin: 8px;
  right: 0;
  top: 0;
  width: 18px;
  z-index: 1;
`;

export const IconLeft = styled(FaCreditCard)`
  height: 24px;
  width: 24px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.5rem;
`;

export const CardName = styled.p`
  color: ${colors.black};
  font-size: 1.1rem;
  font-weight: 700;
`;

export const CardNumber = styled.p`
  color: ${colors.text};
  font-size: 1rem;
  font-weight: 400;
`;

export const MenuModal = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  span {
    border-top: 1px solid ${colors.greyborders};
    color: ${colors.text};
    cursor: pointer;
    padding: 1rem 0;
    text-align: center;
    width: 100%;
  }

  .detail {
    color: ${colors.noverde};
    font-weight: 700;
  }
  .delete {
    color: ${colors.errors};
    font-weight: 700;
  }
`;
