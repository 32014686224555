import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';

import { errorHandler } from './error-handler';

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this.initializeRequestInterceptor();

    this.initializeResponseInterceptor();
  }

  private initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError,
    );
  };

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  };

  private handleRequest = (res: AxiosRequestConfig) => {
    const accessToken = storage.getItem(storageKeys.accessToken);

    if (accessToken && res.headers) {
      res.headers.Authorization = accessToken;
    }

    return res;
  };

  private handleResponse = (res: AxiosResponse) => res;

  protected handleError = async (error: AxiosError) => {
    await errorHandler(error);

    return Promise.reject(error);
  };
}
