import { Button as BaseButton } from 'components/Button';
import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

const colors = getThemeColors();

type QuantityBoxProps = {
  color: string;
  textColor?: string;
};

export const Box = styled.div`
  display: grid;
  row-gap: 12px;
  margin-bottom: 1.2rem;
`;

interface BoxGridProps {
  disable?: boolean;
}
export const BoxGrid = styled.label<BoxGridProps>`
  ${({ disable }) => css`
    padding-bottom: 12px;
    border-bottom: 1px solid ${colors.greyborders};
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    min-width: 15rem;
    width: 100%;

    ${!!disable && `opacity: 0.5`};
    ${!!disable && `cursor: not-allowed`};

    @media ${device.md} {
      min-width: 25rem;
    }
  `}
`;

export const BoxDescription = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Title = styled.p`
  color: ${colors.galaxy};
  font-size: 14px;
  font-weight: 600;
`;

export const QuantityBox = styled.div<QuantityBoxProps>`
  ${({ color, textColor }) => css`
    background-color: ${color ?? colors.greyborders};
    border-radius: 4px;
    color: ${textColor ?? colors.white};
    display: grid;
    height: 45px;
    place-items: center;
    width: 45px;
  `}
`;
export const Vencimento = styled.p`
  color: ${colors.text};
  font-size: 14px;
  font-weight: 400;
`;

export const Valor = styled.p`
  color: ${colors.galaxy};
  font-size: 14px;
  font-weight: 600;
`;

export const ModalContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ModalImage = styled.img``;

export const ModalTitle = styled.div`
  color: ${colors.text};
  font-size: 0.875rem;
  font-weight: 700;
  margin: 1rem 0;
  display: block;
  text-indent: 1rem;
  text-align: center;
`;

export const ModalButton = styled(BaseButton)`
  margin-top: 1rem;
`;

export const ModalDescription = styled.p`
  color: ${colors.text};
  font-size: 0.875rem;
  font-weight: 400;
  text-align: justify;
`;
