import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { colors } from './colors';
import { device } from './medias';

export const FillFormButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  font-size: 22px;

  border: none;
  border-radius: 50%;

  color: ${colors.noverde};
  background-color: ${colors.white};
  box-shadow: rgb(170 171 182 / 50%) 0px 2px 5px;

  width: 60px;
  height: 60px;
  padding: 8px;

  position: fixed;
  bottom: 15px;
  right: 15px;

  z-index: 99999;
`;

export const MessageContainer = styled.div`
  text-align: left;
  > div {
    margin: 16px 0;
  }
`;

export const ErrorMessageStyled = styled.span`
  font-size: 12px;
  color: ${colors.errors};
`;

export const TooltipTitle = styled.h2`
  font-size: 14px;
  color: ${colors.white};
  margin: 10px auto;

  @media ${device.sm} {
    max-width: 280px;
  }
`;

export const TooltipContent = styled.p`
  color: ${colors.white};
  margin: 0 auto;
  margin-bottom: 15px;

  @media ${device.lg} {
    max-width: 280px;
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;

  padding: 32px 16px;

  @media ${device.md} {
    min-height: 100vh;

    padding: 0 16px;
  }
`;

export const Card = styled.div`
  background: ${colors.white};
  box-shadow: 0px 2px 5px rgba(170, 171, 182, 0.5);
  border-radius: 5px;

  overflow: hidden;
  width: 100%;
`;

export const PartnerBox = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  max-width: 660px;
  line-height: 24px;

  > div > button {
    max-width: 328px;
    margin: 0 auto;
  }
`;

export const EmptyButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 5px;

  font-size: 0;
  line-height: 100%;
`;

interface MessageHolderProps {
  margin?: string;
}

export const MessageHolder = styled.div<MessageHolderProps>`
  ${({ margin }) => css`
    max-width: 380px;
    margin: ${margin || '0 auto'};
  `}
`;

export const SideLayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 16px;
  width: 100%;

  @media ${device.sm} {
    height: 100vh;
    max-width: calc(532px + 32px);
  }

  @media ${device.lg} {
    padding: 72px 16px 16px;
  }
`;

export const SideContent = styled(SideLayoutContent)`
  padding-top: 0rem;

  @media ${device.sm} {
    height: calc(100vh - 72px);
  }
`;

interface SideLayoutProps {
  hasCallout?: boolean;
}

export const SideLayout = styled.div<SideLayoutProps>`
  ${({ hasCallout }) => css`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 32px;

    @media ${device.lg} {
      ${hasCallout ? `margin-top:16px` : `margin-top:40px`};
    }

    ${hasCallout && `margin-top:16px; gap: 16px;`};
  `}
`;

export const RelativeBlock = styled.div`
  position: relative;
  width: 100%;
`;

export const AbsoluteBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
`;
