import styled, { css } from 'styled-components';

import { getThemeColors } from './helpers/getThemeColors';

interface LoaderIconProps {
  width: number;
  height: number;
}

const colors = getThemeColors();

export const LoaderIconStyled = styled.span<LoaderIconProps>`
  ${({ width, height }) => css`
    display: block;

    border: ${width / 10}px solid ${colors.noverde};
    border-radius: 50%;
    border-left-color: transparent;

    width: ${width}px;
    height: ${height}px;

    animation: spin 0.7s linear infinite;

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `}
`;
