import {
  FinancialFlowsTypes,
  InfoContractTypes,
  InstallmentsTypes,
  AvailableForPaymentTypes,
} from 'types/InfoContract/InfoContractTypes';
import { setState, StoreSlice } from 'zstore';

export interface IContractInfoSlice {
  financialFlows: FinancialFlowsTypes;
  allInstallments: InstallmentsTypes[];
  availableForPayment: AvailableForPaymentTypes;
  setContractInfo: (data: any) => void;
}

export const ContractInfoSlice: StoreSlice<IContractInfoSlice> = () => ({
  allInstallments: [],
  financialFlows: {} as FinancialFlowsTypes,
  availableForPayment: {} as AvailableForPaymentTypes,
  setContractInfo: (data: InfoContractTypes) => {
    setState((state) => {
      state.allInstallments =
        data?.installments || ([] as unknown as InstallmentsTypes);

      state.financialFlows =
        data?.financialFlows?.[0] || ({} as FinancialFlowsTypes);

      state.availableForPayment =
        data?.availableForPayment || ({} as AvailableForPaymentTypes);
    });
  },
});
