import { LoansSliceTypes } from 'types/Store/LoansSliceTypes';
import { setState, StoreSlice } from 'zstore';

export const loansSlice: StoreSlice<LoansSliceTypes> = () => ({
  loansData: {},
  loansFgtsData: {},
  loanDetails: {},
  setLoansData: (data) => {
    setState((state) => {
      state.loansData = data;
    });
  },
  setLoanDetails: (data) => {
    setState((state) => {
      state.loanDetails = data;
    });
  },
});
