import { useEffect, useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';

import { Button } from 'components/Button';
import { useModal } from 'hooks/useModal';
import { useRouter } from 'hooks/useRouter';
import { AccordionLoanStatement } from 'pages/Dashboard/components/AccordionLoanStatement';
import { ContentLoading } from 'pages/Dashboard/components/ContentLoading';
import { InstallmentLoanStatementDashboard } from 'pages/Dashboard/components/InstallmentLoanStatement';
import InfoContractService from 'services/InfoContract/InfoContractService';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { toBrl } from 'utils/formatters/to-brl';
import { formatInCamelCase } from 'utils/formatters/to-formated-camalCase';
import { isEmpty } from 'utils/helpers/is-empty';
import { useStore } from 'zstore';

import {
  Container,
  ContainerDetails,
  Detail,
  Box,
  Title,
  InstallmentsContainer,
  MaisParcelas,
  ActionsPagination,
  ModalContent,
} from './style';

export const LoanStatementDashboard = () => {
  const colors = getThemeColors();

  const { history } = useRouter();
  const { openModal, closeModal } = useModal();
  const { loanDetails, allInstallments, setContractInfo } = useStore();

  useEffect(() => {
    if (
      isEmpty(allInstallments) &&
      !isEmpty(loanDetails) &&
      ['EP', 'CDC'].includes(loanDetails.product_category)
    ) {
      fetchInstallments();
    }
  }, [loanDetails]);

  const fetchInstallments = async () => {
    try {
      const { data } = await InfoContractService.getInfoContract({
        uid: loanDetails.uid,
      });

      setContractInfo(formatInCamelCase(data));
    } catch (err) {
      console.warn({
        title: 'Erro ao carregar installments',
        err,
      });
    }
  };

  const paidInstallments = allInstallments.filter(
    (installment) => installment.status === 'paid',
  );

  const [pagination, setPagination] = useState({
    currentPage: 1,
    prevCurrentPage: 0,
    start: 0,
    prevStart: 0,
    end: 10,
    prevEnd: 0,
    pages: 0,
  });

  function beforeInstallments() {
    setPagination((prev) => {
      return {
        ...prev,
        start: prev.prevStart,
        end: prev.prevEnd,
        currentPage: prev.prevCurrentPage,
        prevCurrentPage: prev.prevCurrentPage - 1,
        prevStart: prev.prevStart - 10,
        prevEnd: prev.prevStart,
      };
    });
  }

  function moreInstallments() {
    setPagination((prev) => ({
      ...prev,
      currentPage: prev.currentPage + 1,
      end: prev.end + 10,
      start: prev.end,
      prevCurrentPage: prev.currentPage,
      prevEnd: prev.end,
      prevStart: prev.start,
    }));
  }

  function handleIof() {
    openModal({
      title: 'O que é IOF?',
      content: (
        <ModalContent>
          <p>
            IOF é a sigla de Imposto sobre Operações de Crédito, Câmbio e
            Seguros, e é um imposto federal no Brasil.
          </p>
          <Button onClick={closeModal}>entendi</Button>
        </ModalContent>
      ),
      size: 232,
    });
  }

  function handleTarifaCadastro() {
    openModal({
      title: 'O que é tarifa de empréstimo?',
      content: (
        <ModalContent>
          <p>
            Tarifa de cadastro, é uma taxa prevista e regulamentada pelo Banco
            Central.
          </p>
          <Button onClick={closeModal}>entendi</Button>
        </ModalContent>
      ),
      size: 232,
    });
  }

  const goToInstallments = () => history.push('/dashboard/pay-installments');

  useEffect(() => {
    if (allInstallments.length > 0) {
      setPagination((prev) => ({
        ...prev,
        pages: Math.ceil(allInstallments.length / 10),
      }));
    }
  }, [allInstallments.length]);

  const itemsPerPage = allInstallments.slice(pagination.start, pagination.end);
  const from = itemsPerPage?.[0]?.number;
  const to = itemsPerPage?.[itemsPerPage.length - 1]?.number;

  return (
    <Container>
      <AccordionLoanStatement title="Ver detalhes">
        <ContainerDetails>
          <Detail>
            <p>Total do empréstimo</p>
            <span>{toBrl(Number(loanDetails?.proposal?.contract_value))}</span>
          </Detail>
          <Detail>
            <p>
              Tarifa de cadastro{' '}
              <FiHelpCircle
                onClick={handleTarifaCadastro}
                color={colors.noverde}
              />
            </p>
            <span>{toBrl(Number(loanDetails?.proposal?.partner_fee))}</span>
          </Detail>
          <Detail>
            <p>
              IOF <FiHelpCircle onClick={handleIof} color={colors.noverde} />
            </p>
            <span>{toBrl(Number(loanDetails?.proposal?.iof_fee))}</span>
          </Detail>
          <Detail>
            <p>Parcelas pagas</p>
            <span>
              {paidInstallments.length} de {allInstallments.length}
            </span>
          </Detail>
        </ContainerDetails>
      </AccordionLoanStatement>
      {allInstallments.length <= 0 ? (
        <ContentLoading />
      ) : (
        <>
          <Title>
            <p>
              Parcelas - {from} à {to}
            </p>
            <span className="total">
              Total de parcelas {allInstallments.length}
            </span>
          </Title>
          <InstallmentsContainer>
            <Box key="parcelas-pendentes">
              {allInstallments.length > 0 &&
                allInstallments
                  .slice(pagination.start, pagination.end)
                  .map((item, key) => (
                    <InstallmentLoanStatementDashboard
                      key={String(key as number)}
                      item={item}
                    />
                  ))}
            </Box>
          </InstallmentsContainer>
          <ActionsPagination>
            {pagination.currentPage !== 1 && (
              <span onClick={beforeInstallments}>
                {pagination.prevStart + 1} - {pagination.prevEnd}
              </span>
            )}
            {pagination.currentPage !== pagination.pages && (
              <MaisParcelas onClick={moreInstallments}>
                mais parcelas
              </MaisParcelas>
            )}
          </ActionsPagination>
        </>
      )}
      <Button onClick={goToInstallments}>Pagar as parcelas</Button>
    </Container>
  );
};
