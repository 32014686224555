import styled, { css } from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';
import { device } from 'styles/medias';

import { RenegotiationPanelProps } from '.';

const renderBgContainerValue = (
  type: RenegotiationPanelProps['headerOptions']['containerValueType'],
) => {
  switch (type) {
    case 'current_debt':
      return colorsV2['support-baker-pink'];
    default:
      return colorsV2['support-coral'];
  }
};

export const Wrapper = styled.section`
  height: fit-content;
  margin-top: 2rem;
  width: 100%;
`;

// faixa com descricao e valor
export const ContainerValue = styled.div<{
  containerValueType: RenegotiationPanelProps['headerOptions']['containerValueType'];
}>`
  ${({ containerValueType }) => css`
    align-items: center;
    background-color: ${renderBgContainerValue(containerValueType)};
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    padding: 1rem;
    row-gap: 8px;
    width: 100%;
  `}
`;

export const ContainerValueUpperText = styled.p`
  color: ${colorsV2['neutral-dark']};
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
`;

export const ContainerValuePrice = styled.p`
  color: ${colorsV2['neutral-ultra-dark']};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
`;

// container que agroupa todos os items do painel
export const ContainerOptions = styled.div`
  background-color: ${colorsV2['neutral-light']};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: fit-content;
  gap: 8rem;
  padding: 1.5rem;
  row-gap: 4rem;
  width: 100%;

  @media ${device.sm} {
    padding: 1.5rem 9.5rem;
  }
`;

// container do item
export const ContainerOptionsCard = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;

  @media ${device.md} {
    max-width: 687px;
    width: fit-content;
  }
`;

export const ContainerOptionsCardTitleGroup = styled.div`
  align-items: center;
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
`;

export const ContainerOptionsCardTitle = styled.h3`
  color: ${colorsV2['neutral-ultra-dark']};
  font-size: 1.5rem;
  font-weight: 700;
`;

export const ContainerOptionsCardTag = styled.span`
  background-color: ${colorsV2['brand-secondary']};
  color: ${colorsV2['neutral-ultra-light']};
  border-radius: 8px;
  font-size: 9px;
  font-weight: 800;
  height: fit-content;
  padding: 2px 8px;
  width: fit-content;
`;

export const ContainerOptionsCardDescription = styled.p`
  color: ${colorsV2['brand-secondary']};
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 1rem;
`;

// actions
export const ContainerActions = styled.div`
  background-color: ${colorsV2['neutral-ultra-light']};
  display: flex;
  justify-content: flex-start;
  height: fit-content;
  padding: 1.5rem;
  width: 100%;

  @media ${device.sm} {
    padding: 1.5rem 9.5rem;
  }
`;

export const CTAInformation = styled.p`
  color: ${colorsV2['neutral-medium']};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 8px;
  max-width: 19rem;
`;

export const ContainerOptions2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
  width: 100%;
`;

export const ContainerTerms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
