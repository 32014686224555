import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingPage } from 'components/LoadingPage';
import { ResetPasswordBanner } from 'components/ResetPasswordBanner';
import { SideBySideLayout } from 'styles/grids';

export function ResetPasswordSuccess() {
  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push('/login');
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <SideBySideLayout>
      <ResetPasswordBanner />

      <LoadingPage
        title={
          <>
            Senha <b>alterada</b>
          </>
        }
        text={
          <>
            Sucesso! você será redirecionado
            <br /> para o início
          </>
        }
      />
    </SideBySideLayout>
  );
}
