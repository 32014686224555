import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Button } from 'components/Button';
import {
  ContentDescription,
  ButtonsContainer,
  Content,
  ContentContainer,
  StatusContainer,
  FadingContent,
  ContentDescriptionLimited,
  SkeletonContainer,
  ContentDescriptionOriginalMargin,
} from 'styles/tab-documents';

type AgreementContentProps = {
  agreementsData?: {
    name: string;
    content: {
      __html: string;
    };
  }[];
};

type ModalAcceptanceProps = {
  agreementContent: AgreementContentProps | undefined;
  isLoading: boolean;
};

export function ModalAcceptance({
  agreementContent,
  isLoading,
}: ModalAcceptanceProps) {
  const [tabActive, setTabActive] = useState(0);

  const transition = {
    transform: `translate3d( calc(-118%*${tabActive}), 0, 0 )`,
  };

  if (isLoading) {
    return (
      <SkeletonContainer style={{ paddingBottom: 48 }}>
        <Skeleton height={40} style={{ marginBottom: 32 }} />
        <Skeleton height={16} style={{ marginBottom: 16 }} />
        <Skeleton height={16} style={{ marginBottom: 8 }} />
        <Skeleton height={16} style={{ marginBottom: 8 }} />
        <Skeleton height={16} style={{ marginBottom: 8 }} />
        <Skeleton height={16} style={{ marginBottom: 24 }} />
        <Skeleton height={16} style={{ marginBottom: 8 }} />
        <Skeleton height={16} style={{ marginBottom: 8 }} />
        <Skeleton height={16} style={{ marginBottom: 8 }} />
      </SkeletonContainer>
    );
  }

  return agreementContent?.agreementsData?.length === 2 ? (
    <Content>
      <ContentDescriptionLimited>
        <p>
          A análise de crédito depende de informações como dados pessoais, do
          seu dispositivo, de navegação, entre outras. Precisamos dessas
          informações para uma análise inteligente
        </p>
      </ContentDescriptionLimited>
      <ButtonsContainer>
        <Button
          className={tabActive === 0 ? 'documents active' : 'documents'}
          onClick={() => setTabActive(0)}
        >
          {agreementContent?.agreementsData?.[0] &&
            agreementContent.agreementsData[0].name}
        </Button>
        <Button
          className={tabActive === 1 ? 'documents active' : 'documents'}
          onClick={() => setTabActive(1)}
        >
          {agreementContent?.agreementsData?.[1].name}
        </Button>
      </ButtonsContainer>
      <StatusContainer>
        <ContentContainer style={transition}>
          <ContentDescriptionLimited>
            <ContentDescription>
              <ContentDescriptionOriginalMargin
                dangerouslySetInnerHTML={
                  agreementContent?.agreementsData?.[0].content
                }
              />
            </ContentDescription>
          </ContentDescriptionLimited>
          <FadingContent />
        </ContentContainer>

        <ContentContainer style={transition}>
          <ContentDescriptionLimited>
            <ContentDescription>
              <ContentDescriptionOriginalMargin
                dangerouslySetInnerHTML={
                  agreementContent?.agreementsData?.[1].content
                }
              />
            </ContentDescription>
          </ContentDescriptionLimited>
          <FadingContent />
        </ContentContainer>
      </StatusContainer>
    </Content>
  ) : null;
}
