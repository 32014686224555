import { Suspense, useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import { useTracking } from 'hooks/useTracking';
import { ContinueSimulation } from 'pages/ContinueSimulation';
import { CreatePassword } from 'pages/CreatePassword';
import { CreateSimulation } from 'pages/CreateSimulation';
import { PageBoletoPayment } from 'pages/Dashboard/boleto-payment';
import { CheckoutSummaryDashboard } from 'pages/Dashboard/checkout-summary';
import { Contracts } from 'pages/Dashboard/contracts';
import { PageCreditCardPayment } from 'pages/Dashboard/creditcard-payment';
import { LoanStatement } from 'pages/Dashboard/loan-statement';
import { PayInstallments } from 'pages/Dashboard/pay-installments';
import { PagePaymentMethodsDashboard } from 'pages/Dashboard/payment-methods';
import { PagePaymentPooling } from 'pages/Dashboard/payment-pooling';
import { PagePixPayment } from 'pages/Dashboard/pix-payment';
import { PageProfileDashboard } from 'pages/Dashboard/profile';
import { PageRenegotiation } from 'pages/Dashboard/renegotiation';
import { PageRenegotiationPayNow } from 'pages/Dashboard/renegotiation-pay-now';
import { PageRenegotiationPayment } from 'pages/Dashboard/renegotiation-payment';
import { PageRenegotiationRegularize } from 'pages/Dashboard/renegotiation-regularize';
import { PageRenegotiationRepay } from 'pages/Dashboard/renegotiation-repay';
import { Wallets } from 'pages/Dashboard/wallet';
import { WalletRegister } from 'pages/Dashboard/wallet-register';
import { DotzRedirect } from 'pages/DotzRedirect';
import { Liveness } from 'pages/Liveness';
import { NotFound } from 'pages/NotFound';
import PartnerOffer from 'pages/PartnerOffer';
import { ResetPasswordSuccess } from 'pages/ResetPasswordSuccess';
import { Unavailable } from 'pages/Unavailable';
import { UpdatePhoneSuccess } from 'pages/UpdatePhoneSuccess';

import {
  PublicRoute,
  AnimatedRoute,
  ProtectedRoute,
  SimulationRoute,
} from './route-container';
import { RouteList } from './routes';

function Routes() {
  const location = useLocation();
  const { trackEvent } = useTracking();

  const trackRoutes = (route) => {
    const { path, title } = route;

    useEffect(() => {
      if (location.pathname === path) {
        document.title = title;
      }
    }, [location.pathname, trackEvent]);
  };

  const TrackedRoutes = Object.entries(RouteList).map((entries) => {
    const route = entries[1];
    const { path, component, trackName } = route;

    trackRoutes(route);

    return (
      <route.wrapper exact path={path} component={component} key={trackName} />
    );
  });

  return (
    <Suspense fallback={<></>}>
      <AnimatePresence exitBeforeEnter key="Routes">
        <Switch location={location} key={location.pathname}>
          {TrackedRoutes}
          <AnimatedRoute
            exact
            path="/create-password/:type?"
            component={CreatePassword}
          />
          <AnimatedRoute exact path="/dotz-redirect" component={DotzRedirect} />
          <PublicRoute
            exact
            path="/reset-password-success"
            component={ResetPasswordSuccess}
          />
          <ProtectedRoute
            exact
            path="/update-success"
            component={UpdatePhoneSuccess}
          />
          <ProtectedRoute
            exact
            path="/dashboard/payment-methods"
            component={PagePaymentMethodsDashboard}
          />
          <ProtectedRoute
            exact
            path="/dashboard/checkout-summary"
            component={CheckoutSummaryDashboard}
          />
          <ProtectedRoute
            exact
            path="/dashboard/pay-installments"
            component={PayInstallments}
          />
          <ProtectedRoute
            exact
            path="/dashboard/profile"
            component={PageProfileDashboard}
          />
          <ProtectedRoute
            exact
            path="/dashboard/pix-payment"
            component={PagePixPayment}
          />
          <ProtectedRoute
            exact
            path="/dashboard/boleto-payment"
            component={PageBoletoPayment}
          />
          <ProtectedRoute
            exact
            path="/dashboard/creditcard-payment"
            component={PageCreditCardPayment}
          />
          <ProtectedRoute
            exact
            path="/dashboard/wallet-register/:id?"
            component={WalletRegister}
          />
          <ProtectedRoute
            exact
            path="/dashboard/contracts"
            component={Contracts}
          />
          <ProtectedRoute exact path="/dashboard/wallet" component={Wallets} />
          <ProtectedRoute
            exact
            path="/dashboard/loan-statement"
            component={LoanStatement}
          />
          <ProtectedRoute
            exact
            path="/create-simulation/:uuid?"
            component={CreateSimulation}
          />
          <ProtectedRoute
            exact
            path="/continue-simulation/:loanuid?"
            component={ContinueSimulation}
          />
          <ProtectedRoute
            exact
            path="/dashboard/renegociacao"
            component={PageRenegotiation}
          />
          <ProtectedRoute
            exact
            path="/dashboard/renegociacao/pague-agora"
            component={PageRenegotiationPayNow}
          />
          <ProtectedRoute
            exact
            path="/dashboard/renegociacao/reparcele"
            component={PageRenegotiationRepay}
          />
          <ProtectedRoute
            exact
            path="/dashboard/renegociacao/regularize"
            component={PageRenegotiationRegularize}
          />
          <ProtectedRoute
            exact
            path="/dashboard/payment-polling/:id"
            component={PagePaymentPooling}
          />
          <ProtectedRoute
            exact
            path="/dashboard/renegociacao/pagamento"
            component={PageRenegotiationPayment}
          />

          <SimulationRoute exact path="/liveness" component={Liveness} />

          <AnimatedRoute exact path="/unavailable" component={Unavailable} />

          <AnimatedRoute exact path="/partner-offer" component={PartnerOffer} />

          <AnimatedRoute path="*" component={NotFound} />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}

export default Routes;
