import { UpdatePhone } from 'pages/UpdatePhone';
import { lazyWithRetry } from 'utils/formatters/lazy-with-retry';

import {
  AnimatedRoute,
  ProtectedRoute,
  PublicRoute,
  SimulationRoute,
} from './route-container';

const Approved = lazyWithRetry(() => import('pages/Approved'));
const BankAccount = lazyWithRetry(() => import('pages/BankAccount'));
const PageHomeDashboard = lazyWithRetry(() => import('pages/Dashboard'));
const WaitingApproval = lazyWithRetry(() => import('pages/WaitingApproval'));
const Login = lazyWithRetry(() => import('pages/Login'));
const CreateAccount = lazyWithRetry(() => import('pages/CreateAccount'));
const ResetPassword = lazyWithRetry(() => import('pages/ResetPassword'));
const ProceedByCell = lazyWithRetry(() => import('pages/ProceedByCell'));
const OfferAnalysis = lazyWithRetry(() => import('pages/OfferAnalysis'));
const Documents = lazyWithRetry(() => import('pages/Documents'));
const Selfie = lazyWithRetry(() => import('pages/Selfie'));
const LandingPage = lazyWithRetry(() => import('pages/LandingPage'));
const ProposalAcceptance = lazyWithRetry(
  () => import('pages/ProposalAcceptance'),
);
const Intro = lazyWithRetry(() => import('pages/Intro'));
const ContractSign = lazyWithRetry(() => import('pages/ContractSign'));
const ListAccounts = lazyWithRetry(() => import('pages/ListAccounts'));
const OfferApproved = lazyWithRetry(() => import('pages/OfferApproved'));
const OfferApprovedFgts = lazyWithRetry(
  () => import('pages/OfferApprovedFgts'),
);
const FgtsDetails = lazyWithRetry(() => import('pages/FgtsDetails'));
const FullFgtsPage = lazyWithRetry(() => import('pages/FullPageFgts'));
const OfferDenied = lazyWithRetry(() => import('pages/OfferDenied'));
const Offers = lazyWithRetry(() => import('pages/Offers'));
const ConfirmCode = lazyWithRetry(() => import('pages/ConfirmCode'));
const RequestedAmount = lazyWithRetry(() => import('pages/RequestedAmount'));
const RequestedConfig = lazyWithRetry(() => import('pages/RequestedConfig'));
const EmploymentStatus = lazyWithRetry(() => import('pages/EmploymentStatus'));
const Income = lazyWithRetry(() => import('pages/Income'));
const Address = lazyWithRetry(() => import('pages/Address'));
const PendenciesList = lazyWithRetry(() => import('pages/PendenciesList'));
const SelfieDisclaimer = lazyWithRetry(() => import('pages/SelfieDisclaimer'));
const LifeProofDisclaimer = lazyWithRetry(
  () => import('pages/LifeProofDisclaimer'),
);
const FirstAccess = lazyWithRetry(() => import('pages/FirstAccess'));
const LifeProof = lazyWithRetry(() => import('pages/LifeProof'));
const ReceivingForms = lazyWithRetry(() => import('pages/ReceivingForms'));
const InformPixKey = lazyWithRetry(() => import('pages/InformPixKey'));
const ChooseKeyType = lazyWithRetry(() => import('pages/ChooseKeyType'));
const ConfirmPixFill = lazyWithRetry(() => import('pages/ConfirmPixFill'));
const PixError = lazyWithRetry(() => import('pages/PixError'));
const MaritalStatus = lazyWithRetry(() => import('pages/MaritalStatusFgts'));
const Gender = lazyWithRetry(() => import('pages/GenderFgts'));
const MotherName = lazyWithRetry(() => import('pages/MotherName'));
const IdentificationDocument = lazyWithRetry(
  () => import('pages/IdentificationDocument'),
);
const ConfirmFirstAccessCode = lazyWithRetry(
  () => import('pages/ConfirmFirstAccessCode'),
);
const Finish = lazyWithRetry(() => import('pages/Finish'));
const ExchangeAndEarn = lazyWithRetry(
  () => import('pages/ExchangeAndEarn/NewTeG'),
);

const ExchangeAndEarnDisclaimer = lazyWithRetry(
  () => import('pages/ExchangeAndEarnDisclaimer'),
);

const LivenessDisclaimer = lazyWithRetry(
  () => import('pages/LivenessDisclaimer'),
);

const RedirectSuperSim = lazyWithRetry(() => import('pages/RedirectSuperSim'));

export const RouteList = {
  INTRODUCTION: {
    wrapper: PublicRoute,
    path: '/',
    trackName: 'intro',
    title: 'Intro',
    component: Intro,
  },
  LOGIN: {
    wrapper: PublicRoute,
    path: '/login',
    trackName: 'login',
    title: 'Login',
    component: Login,
  },
  LANDINGPAGE: {
    wrapper: AnimatedRoute,
    path: '/credito/:id',
    trackName: 'landing page',
    title: 'Landing Page',
    component: LandingPage,
  },
  CREATE_ACCOUNT: {
    wrapper: PublicRoute,
    path: '/create-account',
    trackName: 'criar_conta',
    title: 'Criar conta',
    component: CreateAccount,
  },
  confirm_code: {
    wrapper: AnimatedRoute,
    path: '/confirm-code',
    trackName: 'confirmar_celular',
    title: 'Confirmar celular',
    component: ConfirmCode,
  },
  RESET_PASSWORD: {
    wrapper: PublicRoute,
    path: '/reset-password',
    trackName: 'esqueci_senha_identificacao',
    title: 'Esqueci a senha - Identificação',
    component: ResetPassword,
  },
  PROCEED: {
    wrapper: ProtectedRoute,
    path: '/proceed',
    trackName: 'documentos_qr',
    title: 'Documentos QR Code',
    component: ProceedByCell,
  },
  pendencies_list: {
    wrapper: SimulationRoute,
    path: '/pendencies-list',
    trackName: 'pendências',
    title: 'Pendências',
    component: PendenciesList,
  },
  OFFER_ANALYSIS: {
    wrapper: SimulationRoute,
    path: '/offer-analysis',
    trackName: 'analise_de_credito',
    title: 'Analise de credito',
    component: OfferAnalysis,
  },
  DOCUMENTS: {
    wrapper: SimulationRoute,
    path: '/documents',
    trackName: 'documentos',
    title: 'Documentos',
    component: Documents,
  },
  selfie: {
    wrapper: SimulationRoute,
    path: '/selfie',
    trackName: 'documento_selfie',
    title: 'Documento - Selfie',
    component: Selfie,
  },
  selfie_disclaimer: {
    wrapper: SimulationRoute,
    path: '/selfie-disclaimer',
    trackName: 'aviso_selfie',
    title: 'Selfie - Aviso',
    component: SelfieDisclaimer,
  },
  life_proof: {
    wrapper: SimulationRoute,
    path: '/proof',
    trackName: 'documento_prova_de_vida',
    title: 'Documento - Prova de vida',
    component: LifeProof,
  },
  life_proof_disclaimer: {
    wrapper: SimulationRoute,
    path: '/proof-disclaimer',
    trackName: 'aviso_prova_de_vida',
    title: 'Prova de vida - Aviso',
    component: LifeProofDisclaimer,
  },
  liveness_disclaimer: {
    wrapper: SimulationRoute,
    path: '/liveness-disclaimer',
    trackName: 'liveness',
    title: 'Liveness',
    component: LivenessDisclaimer,
  },
  WAITING_APPROVAL: {
    wrapper: SimulationRoute,
    path: '/waiting',
    trackName: 'finalizar_aplicação',
    title: 'Finalizar aplicação',
    component: WaitingApproval,
  },
  APPROVED: {
    wrapper: SimulationRoute,
    path: '/approved',
    trackName: 'aceite_emprestimo',
    title: 'Aceite do emprestimo',
    component: Approved,
  },
  DASHBOARD: {
    wrapper: ProtectedRoute,
    path: '/dashboard',
    trackName: 'dashboard',
    title: 'Dashboard',
    component: PageHomeDashboard,
  },
  PHONE: {
    wrapper: ProtectedRoute,
    path: '/update-phone',
    trackName: 'phone',
    title: 'Atualizar dados - Telefone',
    component: UpdatePhone,
  },
  requested_amount: {
    wrapper: SimulationRoute,
    path: '/requested-amount',
    trackName: 'simulação_valor',
    title: 'Simulação - Valor',
    component: RequestedAmount,
  },
  requested_config: {
    wrapper: SimulationRoute,
    path: '/requested-config',
    trackName: 'simulação_parcelas_e_vencimento',
    title: 'Simulação - Parcelas e vencimento',
    component: RequestedConfig,
  },
  employment_status: {
    wrapper: SimulationRoute,
    path: '/employment-status',
    trackName: 'simulação_situação_empregatícia',
    title: 'Simulação - Situação empregatícia',
    component: EmploymentStatus,
  },
  income: {
    wrapper: SimulationRoute,
    path: '/income',
    trackName: 'simulação_renda',
    title: 'Simulação - Renda',
    component: Income,
  },
  address: {
    wrapper: SimulationRoute,
    path: '/address',
    trackName: 'simulação_endereço',
    title: 'Simulação - Endereço',
    component: Address,
  },
  bank_data: {
    wrapper: SimulationRoute,
    path: '/bank-account',
    trackName: 'simulação_conta_bancaria',
    title: 'Simulação - Conta bancaria',
    component: BankAccount,
  },
  proposal_acceptance: {
    wrapper: SimulationRoute,
    path: '/proposal-acceptance',
    trackName: 'parceiros_aceite_proposta',
    title: 'Aceite da proposta',
    component: ProposalAcceptance,
  },
  contract_sign: {
    wrapper: SimulationRoute,
    path: '/contract-sign',
    trackName: 'contratos',
    title: 'Contratos',
    component: ContractSign,
  },
  list_accounts: {
    wrapper: SimulationRoute,
    path: '/list-accounts',
    trackName: '',
    title: 'Listar - Contas',
    component: ListAccounts,
  },
  offer_approved: {
    wrapper: SimulationRoute,
    path: '/offer-approved',
    trackName: '',
    title: 'Proposta',
    component: OfferApproved,
  },
  offer_denied: {
    wrapper: SimulationRoute,
    path: '/offer-denied',
    trackName: 'empréstimo_cancelado',
    title: 'Empréstimo cancelado',
    component: OfferDenied,
  },
  apply_pre_approved: {
    wrapper: ProtectedRoute,
    path: '/offers',
    trackName: '',
    title: 'Oferta',
    component: Offers,
  },
  receiving_forms: {
    wrapper: SimulationRoute,
    path: '/receiving-forms',
    trackName: 'desembolso_tipo',
    title: 'Desembolso - Tipo',
    component: ReceivingForms,
  },
  choose_key_type: {
    wrapper: SimulationRoute,
    path: '/choose-key-type',
    trackName: 'desembolso_tipo_de_chave_pix',
    title: 'Desembolso - Tipo de chave pix',
    component: ChooseKeyType,
  },
  inform_pix_key: {
    wrapper: SimulationRoute,
    path: '/inform-pix-key',
    trackName: 'desembolso_chave_pix',
    title: 'Desembolso - Chave pix',
    component: InformPixKey,
  },
  confirm_pix_fill: {
    wrapper: SimulationRoute,
    path: '/confirm-pix-fill',
    trackName: 'desembolso_Confirmar_chave_pix',
    title: 'Desembolso - Confirmar chave pix',
    component: ConfirmPixFill,
  },
  pix_error: {
    wrapper: SimulationRoute,
    path: '/pix-error',
    trackName: 'desembolso_erro_validacao_chave_pix',
    title: 'Desembolso - Erro validacao chave pix',
    component: PixError,
  },
  confirm_code_first_access: {
    wrapper: AnimatedRoute,
    path: '/confirm-first-access-code',
    trackName: 'primeiro_acesso_pin',
    title: 'Primeiro acesso - Pin',
    component: ConfirmFirstAccessCode,
  },
  first_access: {
    wrapper: AnimatedRoute,
    path: '/first-access',
    trackName: 'primeiro_acesso',
    title: 'Primeiro acesso',
    component: FirstAccess,
  },
  finish_application: {
    wrapper: SimulationRoute,
    path: '/finish',
    trackName: 'Finalizar aplicação',
    title: 'Finalizar aplicação',
    component: Finish,
  },
  refinancing: {
    wrapper: ProtectedRoute,
    path: '/refinancing',
    trackName: 'oferta_troca_e_ganha',
    title: 'Oferta - Troca e ganha',
    component: ExchangeAndEarn,
  },
  refinance_disclaimer: {
    wrapper: ProtectedRoute,
    path: '/refinance-disclaimer',
    trackName: 'oferta_troca_e_ganha_aviso',
    title: 'Troca e Ganha - Aviso',
    component: ExchangeAndEarnDisclaimer,
  },
  offerApprovedFGTS: {
    wrapper: SimulationRoute,
    path: '/offer-approved-fgts',
    trackName: 'FGTS - Proposta',
    title: 'FGTS - Proposta',
    component: OfferApprovedFgts,
  },
  fgtsDetails: {
    wrapper: ProtectedRoute,
    path: '/fgts-details',
    trackName: 'FGTS - Detalhes do emprestimo',
    title: 'FGTS - Detalhes do emprestimo',
    component: FgtsDetails,
  },
  fgtsFullPage: {
    wrapper: ProtectedRoute,
    path: '/fgts',
    trackName: 'FGTS - Intro',
    title: 'FGTS - Intro',
    component: FullFgtsPage,
  },
  identificationDocument: {
    wrapper: SimulationRoute,
    path: '/identification-document',
    trackName: 'Informacoes do documento',
    title: 'Informacoes do documento',
    component: IdentificationDocument,
  },
  maritalStatus: {
    wrapper: SimulationRoute,
    path: '/marital-status',
    trackName: 'Estado Civil',
    title: 'Estado Civil',
    component: MaritalStatus,
  },
  gender: {
    wrapper: SimulationRoute,
    path: '/gender',
    trackName: 'Genero',
    title: 'Genero',
    component: Gender,
  },
  motherName: {
    wrapper: SimulationRoute,
    path: '/mother-name',
    trackName: 'Nome da mae',
    title: 'Nome da mae',
    component: MotherName,
  },
  redirectSuperSim: {
    wrapper: ProtectedRoute,
    path: '/redirect-supersim',
    trackName: 'Redirecionar para SuperSim',
    title: 'Redirecionar para SuperSim',
    component: RedirectSuperSim,
  },
};
