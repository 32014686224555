import { createContext, useState } from 'react';

import { unMask } from 'remask';
import AccountFormService from 'services/Authorizer/AccountFormService';
import { AccountFormType } from 'types/Authorizer/AccountFormType';
import { dateFormatter } from 'utils/formatters/date-formatter';

interface CreateAccountContextProps {
  authToken: string;
  saveAccountData: (data: any) => Promise<any>;
  resendAccount: () => Promise<any>;
  setAuthToken: (token: string) => void;
}

export const CreateAccountContext = createContext<CreateAccountContextProps>({
  authToken: '',
  saveAccountData: async () => null,
  resendAccount: async () => null,
  setAuthToken: () => null,
});

interface CreateAccountProviderProps {
  children?: React.ReactNode;
}

export function CreateAccountProvider(props: CreateAccountProviderProps) {
  const { children } = props;
  const [accountFormData, setAccountFormData] = useState<AccountFormType>();
  const [authToken, setStateAuthToken] = useState<string>('');

  function saveAccountData(data) {
    setAccountFormData(data);

    return createAccount(data);
  }

  function resendAccount() {
    return createAccount(accountFormData);
  }

  function createAccount(data) {
    return AccountFormService.createAccount({
      birthdate: dateFormatter(data.birthdate),
      document: unMask(data.document),
      email: data.email,
      mobileNumber: unMask(data.mobileNumber),
      name: data.name,
    });
  }

  const setAuthToken = (token) => setStateAuthToken(token);

  const value = {
    authToken,
    saveAccountData,
    resendAccount,
    setAuthToken,
  };

  return (
    <CreateAccountContext.Provider value={value}>
      {children}
    </CreateAccountContext.Provider>
  );
}

CreateAccountProvider.defaultProps = {
  children: null,
};
