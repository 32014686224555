import { setState, StoreSlice } from 'zstore';

export interface IPaymentsSlice {
  creditCardId: string;
  setCreditCardId: (data: any) => void;
}

export const createPaymentsSlice: StoreSlice<IPaymentsSlice> = () => ({
  creditCardId: '',
  setCreditCardId: (data) => {
    setState((state) => {
      state.creditCardId = data;
    });
  },
});
