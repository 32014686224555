import 'rc-slider/assets/index.css';
import { useState } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

import Slider from 'rc-slider';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { dotzTheme } from 'styles/themes/dotz';

import { Container, Content, ContentSlider } from './style';

import { MinMaxSliderProps } from '..';

interface CustomSliderProps {
  minMaxSlider: MinMaxSliderProps[];
  setSliderValue: (value: number) => void;
  currentSliderValue: number;
  stepSlider: number;
  fgts?: boolean;
}

const slideStyles = {
  marginTop: '-40px',
  cursor: 'pointer',
};

export const CustomSlider = ({
  setSliderValue,
  minMaxSlider,
  currentSliderValue,
  stepSlider,
  fgts = false,
}: CustomSliderProps) => {
  const colors = getThemeColors({
    dotz: {
      white: dotzTheme.colorNeutralDarkPure,
    },
  });

  const [value, setValue] = useState<number | number[]>(currentSliderValue);

  const changeSlideValue = (currValue) => {
    setValue(currValue);
    setSliderValue(currValue);
  };

  const handleChangeActionSlider = (action: 'minus' | 'add') => {
    if (
      action === 'minus' &&
      Number(value) - stepSlider >= minMaxSlider[0].step
    ) {
      setValue(Number(value) - stepSlider);
      setSliderValue(Number(value) - stepSlider);
    } else if (
      action === 'add' &&
      Number(value) + stepSlider <= minMaxSlider[1].step
    ) {
      setValue(Number(value) + stepSlider);
      setSliderValue(Number(value) + stepSlider);
    }
  };

  return (
    <Container>
      <FiMinus
        color={colors.white}
        size="32px"
        style={slideStyles}
        onClick={() => handleChangeActionSlider('minus')}
      />
      <Content>
        <Slider
          railStyle={{ background: colors.galaxy, height: '2px' }}
          trackStyle={{ background: colors.galaxy, height: '2px' }}
          min={minMaxSlider[0].step}
          max={minMaxSlider[1].step}
          step={stepSlider}
          handleStyle={{
            background: colors.white,
            width: '30px',
            height: '30px',
            marginTop: '-14px',
            opacity: 1,
            border: `1px solid ${colors.white}`,
            boxShadow: 'none',
          }}
          value={value}
          onChange={(_currentValue) => changeSlideValue(_currentValue)}
        />
        <ContentSlider>
          {fgts ? (
            <p>
              {minMaxSlider[0].value}{' '}
              {minMaxSlider[0].value > 1 ? 'anos' : 'ano'}
            </p>
          ) : (
            <p>R$ {minMaxSlider[0].value}</p>
          )}
          {fgts ? (
            <p>{minMaxSlider[1].value} anos</p>
          ) : (
            <p>R$ {minMaxSlider[1].value}</p>
          )}
        </ContentSlider>
      </Content>
      <FiPlus
        onClick={() => handleChangeActionSlider('add')}
        color={colors.white}
        size="32px"
        style={slideStyles}
      />
    </Container>
  );
};
