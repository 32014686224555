import { LoanStatementDashboard } from 'pages/Dashboard/components/LoanStatementDashboard';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

export const LoanStatement = () => {
  return (
    <DashBoardTemplate headerTitle="Extrato do Empréstimo">
      <LoanStatementDashboard />
    </DashBoardTemplate>
  );
};
