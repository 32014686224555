import { colors } from 'styles/colors';

import { ButtonStyled } from './style';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'secondary' | 'tertiary' | 'documents' | 'rounded';
  mainColor?: keyof typeof colors;
};

export function Button(props: ButtonProps) {
  const { children, variant, className, mainColor, color, ...rest } = props;

  return (
    <ButtonStyled
      {...rest}
      className={variant || className}
      color={mainColor ? String(colors[mainColor]) : color}
    >
      {children}
    </ButtonStyled>
  );
}

Button.defaultProps = {
  variant: '',
  mainColor: '',
};
