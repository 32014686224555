import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { PasswordFormType } from 'types/Authorizer/PasswordFormType';

class PasswordFormService extends HttpClient {
  public constructor() {
    super(Services.Authorizer);
  }

  public createPassword = (body: PasswordFormType) => {
    const requestBody = {
      password: body.password,
      password_confirmation: body.passwordConfirmation,
    };

    return this.instance.post('/password/reset', requestBody);
  };
}

export default new PasswordFormService();
