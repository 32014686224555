import { storage } from 'utils/storage';

import { storageKeys } from './storage-keys';

export function cleanLocalStorage() {
  Object.keys(storage.getStorage()).forEach((key) => {
    if (!key.includes('persist@noverde-app-web')) {
      storage.removeItem(key);
    }
  });
}

export function clearSessionDataInStorage() {
  storage.removeItem(storageKeys.accessToken);
}
