import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const Container = styled.header`
  align-items: center;
  background: ${colors.white};
  border-bottom: 1px dashed ${colors.noverde};
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 1rem;
  width: 100%;
`;

export const Text = styled.p`
  color: ${colors.galaxy};
  font-size: 20px;
  font-family: 400;
  line-height: 24px;
  text-align: center;
`;

type Props = {
  open: boolean;
};
export const ContainerButton = styled.div<Props>`
  ${({ open }) => css`
    ${!!open && `z-index: 1`};

    svg {
      cursor: pointer;
      color: ${colors.noverde};
      height: 32px;
      width: 32px;
    }
  `}
`;
