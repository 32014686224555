import { LoaderIconStyled } from 'styles/loader';

import { ScreenLoaderContainer } from './style';

export const ScreenLoader = () => {
  return (
    <ScreenLoaderContainer>
      <LoaderIconStyled width={46} height={46} color="noverde" />
    </ScreenLoaderContainer>
  );
};
