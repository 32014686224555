import styled from 'styled-components';
import { device } from 'styles/medias';

export const ContainerOptionsCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  width: 100%;

  @media ${device.sm} {
    width: fit-content;
  }
`;
