import history from 'routes/history';
import { cleanLocalStorage } from 'store/clean';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';
import { useStore } from 'zstore';

import AuthenticationService from './Authorizer/AuthenticationService';

export async function errorHandler(error) {
  const { response } = error;
  const refreshToken = storage.getItem(storageKeys.refreshToken) || '';
  const statusCode = response?.status;
  const requestUrl = response?.config?.baseURL + response?.config?.url || '';

  const isPlatformIr = !!requestUrl?.includes('/ir/');
  const isZipCodeService = !!requestUrl?.includes('zipcode');
  const isLoginService = !!requestUrl?.includes('login');
  const isRefreshService = !!requestUrl?.includes('refresh');
  const isPinValidate = !!requestUrl?.includes('/pin/login');
  const isCertiface = !!requestUrl?.includes('certiface');

  if (typeof error.response === 'undefined') {
    cleanLocalStorage();

    history.push('/');

    useStore.setState({
      toastMessage: 'Sessão expirada, faça login novamente!',
      toastType: 'alert',
      isToastVisible: true,
    });

    return null;
  }

  const hideToast = [
    isPlatformIr,
    isZipCodeService,
    !statusCode,
    isPinValidate,
    isCertiface,
  ];

  if (hideToast.includes(true)) return null;

  if (
    [401, 403].includes(statusCode) &&
    !isLoginService &&
    !isRefreshService &&
    !isCertiface
  ) {
    try {
      const data = await AuthenticationService.refreshToken(refreshToken);

      storage.setItem(storageKeys.accessToken, data.data.access_token);
      storage.setItem(storageKeys.expiresAt, data.data.expires_at);

      window.location.reload();
    } catch {
      cleanLocalStorage();

      history.push('/');

      useStore.setState({
        toastMessage: 'Sessão expirada, faça login novamente!',
        toastType: 'alert',
        isToastVisible: true,
      });
    }

    return null;
  }

  if (statusCode === 500) {
    return history.push('/unavailable');
  }

  const defaultMessages = {
    400: {
      message: 'Houve um problema ao processar a requisição!',
      type: 'error',
    },
    401: {
      message: 'Acesso negado!',
      type: 'alert',
    },
    403: {
      message: 'Acesso não autorizado!',
      type: 'alert',
    },
    404: {
      message: 'Não encontramos o caminho para a requisição.',
      type: 'alert',
    },
    500: {
      message: 'Houve um problema interno em nosso sistema.',
      type: 'error',
    },
    503: {
      message: 'Serviço indisponível.',
      type: 'error',
    },
    default: {
      message: 'Tivemos um problema desconhecido.',
      type: 'error',
    },
  };

  if (!useStore.getState().isToastVisible) {
    const toastMessage =
      defaultMessages[statusCode]?.message || defaultMessages.default.message;
    const toastType =
      defaultMessages[statusCode]?.type || defaultMessages.default.type;

    return useStore.setState({
      toastMessage,
      toastType,
      isToastVisible: true,
    });
  }

  return null;
}
