import { motion } from 'framer-motion';
import styled, { css, keyframes } from 'styled-components';

interface IToasterContainer {
  variant?: 'success' | 'error' | 'alert' | 'info' | 'disclaimer' | 'tip';
  showTime: number;
}

const toasterColors = {
  success: { border: '#6fcf97', background: '#f3fff9' },
  error: { border: '#efada5', background: '#fff7f6' },
  alert: { border: '#ff9900', background: '#fffbf5' },
  info: { border: '#2196f3', background: '#eff8ff' },
  disclaimer: { border: '#e2e2e2', background: '#fdfdfd' },
  tip: { border: '#2196f3', background: '#eff8ff' },
};

export const ToasterContainer = styled(motion.div)<IToasterContainer>`
  ${({ variant, showTime }) => css`
    width: 100%;
    max-width: 320px;

    background: ${toasterColors[variant || 'error'].background};
    border: 1px solid ${toasterColors[variant || 'error'].border};
    box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.1);

    border-radius: 4px;
    padding: 0 0 0 16px;

    position: fixed;
    left: 50%;

    z-index: 99999;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-between;

    ${MessageHolder} {
      > span {
        color: ${toasterColors[variant || 'error'].border};
        font-size: 20px;
        line-height: 15px;
      }

      > p {
        font-size: 14px;
      }
    }

    ${ToasterClose} {
      color: ${toasterColors[variant || 'error'].border};
    }

    &:after {
      content: '';
      width: 0;
      height: 100%;

      background-color: ${toasterColors[variant || 'error'].border};
      opacity: 0.1;

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;

      animation-name: ${LoaderAnimation};
      animation-duration: ${showTime}ms;
      animation-timing-function: linear;
    }
  `}
`;

const LoaderAnimation = keyframes`  
  from { width: 100%; }
  to { width: 0; }
`;

export const ToasterClose = styled.button`
  background: transparent;
  border: none;

  font-size: 22px;
  font-weight: 300;

  padding: 8px 16px 11px;
`;

export const MessageHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 8px 0 11px;
`;
