import { createContext, useState } from 'react';

import { ExpiredStorage } from 'utils/expiredStorage';

export type MultiThemeContextData = {
  theme: string | null;
};

export type MultiThemeProviderTypes = {
  children?: React.ReactNode;
};

export const MultiThemeContext = createContext({} as MultiThemeContextData);

export const MultiThemeProvider = ({ children }: MultiThemeProviderTypes) => {
  const [theme] = useState<string | null>(() =>
    ExpiredStorage.getItemWithExpiry('multitheme'),
  );

  const DOTZ_SUFFIX = process.env.REACT_APP_DOTZ_DOMAIN;

  if (DOTZ_SUFFIX) {
    const parsedUrl = new URL(window.location.href);
    const pathWithoutParams = parsedUrl.pathname.split('/')[1];
    let urlWithoutParams = `${parsedUrl.origin}/${pathWithoutParams}`;

    if (urlWithoutParams.endsWith('/')) {
      urlWithoutParams = urlWithoutParams.replace(/(^\w+:|^)\/\//, '');
      urlWithoutParams = urlWithoutParams.slice(0, urlWithoutParams.length - 1); // remove trailing slash
    }

    const storagedItem = ExpiredStorage.getItemWithExpiry('multitheme');

    if (
      urlWithoutParams.endsWith(`.${DOTZ_SUFFIX}`) ||
      urlWithoutParams === DOTZ_SUFFIX ||
      urlWithoutParams.includes(DOTZ_SUFFIX)
    ) {
      if (storagedItem === null) {
        ExpiredStorage.setItemWithExpiry('multitheme', 'dotz', 0);
        window.location.reload();
      }
    }
  }

  return (
    <MultiThemeContext.Provider value={{ theme }}>
      {children}
    </MultiThemeContext.Provider>
  );
};
