import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';

class AuthenticationService extends HttpClient {
  public constructor() {
    super(Services.Authorizer);
  }

  public requestLogin = (
    username: string,
    password: string,
    deviceId: string,
  ) => {
    const requestBody = {
      username,
      password,
      device_id: deviceId,
    };
    return this.instance.post('/login', requestBody);
  };

  public refreshToken = (refreshToken: string) => {
    const requestBody = {
      refresh_token: refreshToken,
    };
    return this.instance.post('/refresh', requestBody);
  };
}

export default new AuthenticationService();
