import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { IoCheckmarkCircle } from 'react-icons/io5';

import { getThemeColors } from 'styles/helpers/getThemeColors';

import { RadioContainer, RadioLabel } from './style';

interface RadioItemProps {
  content: any;
  selected: boolean;
  checked?: boolean;
  onClick?: (e?) => void;
}

const colors = getThemeColors();

export function RadioItem(prop: RadioItemProps) {
  const { content, selected, checked, onClick } = prop;

  return (
    <RadioContainer onClick={onClick}>
      {selected ? (
        checked ? (
          <IoCheckmarkCircle fill={colors.noverde} />
        ) : (
          <IoMdRadioButtonOn fill={colors.noverde} />
        )
      ) : (
        <IoMdRadioButtonOff />
      )}
      {content && <RadioLabel>{content}</RadioLabel>}
    </RadioContainer>
  );
}

RadioItem.defaultProps = {
  checked: false,
};
