import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  svg {
    color: ${colors.placeholders};
    height: 24px;
    width: 24px;
  }
`;

export const Text = styled.span`
  color: ${colors.placeholders};
  font-size: 1rem;
  margin-left: 1rem;
  padding: 1rem;
  text-decoration: none;
  line-height: 1;
`;
