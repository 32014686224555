import { useEffect } from 'react';
import {
  FiAlertTriangle,
  FiSlash,
  FiInfo,
  FiCheckCircle,
  FiStar,
} from 'react-icons/fi';

import { useStore } from 'zstore';

import {
  MessageContainer,
  HeaderContainer,
  IconContainer,
  TitleCallout,
  ContentCallout,
} from './style';

interface CalloutProps {
  type:
    | 'success'
    | 'error'
    | 'alert'
    | 'info'
    | 'info2'
    | 'disclaimer'
    | 'tip'
    | 'login';
  title: React.ReactNode;
  message: React.ReactNode;
}

export function MessageCallout(props: CalloutProps) {
  const { type, title, message } = props;
  const { setToggleEnableToast } = useStore();

  useEffect(() => {
    setToggleEnableToast();

    return () => setToggleEnableToast();
  }, []);

  const CalloutIcons = {
    success: <FiCheckCircle />,
    error: <FiSlash />,
    alert: <FiAlertTriangle />,
    info: <FiInfo />,
    info2: <FiInfo />,
    disclaimer: <FiInfo />,
    tip: <FiStar />,
  };

  return (
    <MessageContainer className={type}>
      <HeaderContainer>
        <IconContainer className={type}>{CalloutIcons[type]}</IconContainer>
        <TitleCallout className={type}>{title}</TitleCallout>
      </HeaderContainer>
      <ContentCallout className={type}>{message}</ContentCallout>
    </MessageContainer>
  );
}
