import { isBefore } from 'date-fns';
import { isEqual } from 'date-fns/esm';

import { extractPartsOfDate } from '../helpers/extract-parts-of-date-by-string';

const oneYearInMilliseconds = 31557600000;

type AllowAge = {
  minAge: number;
  maxAge: number;
};
export const allowAge =
  ({ minAge = 1, maxAge = 115 }: AllowAge) =>
  (rawDate: string) => {
    const MONTH_OF_YEAR = 12;
    const date = extractPartsOfDate(rawDate);

    if (!date) {
      return false;
    }

    const { day, month, year } = date;

    const daysInMonth = new Date(Number(year), Number(month), 0).getDate();
    const isGreaterThanDayOfMonth = Number(day) > daysInMonth;
    const isGreaterMonthThan = Number(month) > MONTH_OF_YEAR;
    const inputtedDate = new Date(Number(year), Number(month) - 1, Number(day));
    const withSubYears = new Date().getFullYear() - maxAge;
    const currentDate = new Date();
    const withCurrentDate = new Date(
      withSubYears,
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const itsLive =
      isBefore(inputtedDate, withCurrentDate) ||
      isEqual(inputtedDate, withCurrentDate);
    const futureYear = Number(year) > new Date().getFullYear();
    const wrongYear = year.length !== 4;
    const isInvalidYear = wrongYear || itsLive || futureYear;

    if (isGreaterThanDayOfMonth || isGreaterMonthThan || isInvalidYear) {
      return false;
    }

    const timestampInputted = new Date(`${month}/${day}/${year}`).getTime();

    const age = Math.floor(
      (new Date().getTime() - timestampInputted) / oneYearInMilliseconds,
    );

    const ageNotAllowed = !(age >= minAge && age <= maxAge);

    if (ageNotAllowed) {
      return false;
    }

    return true;
  };
