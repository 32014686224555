export function PayIcon() {
  return (
    <svg
      width="132"
      height="104"
      viewBox="0 0 132 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90 24H10C4.485 24 0 28.485 0 34V44H100V34C100 28.485 95.515 24 90 24ZM0 94C0 99.515 4.485 104 10 104H90C95.515 104 100 99.515 100 94V64H0V94ZM15 79H45V89H15V79Z"
        fill="#0CD15B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.58 2.00722C120.872 0.726072 122.617 0.00504724 124.437 2.63976e-05C126.257 -0.00499445 128.005 0.706392 129.305 1.98039C130.604 3.25439 131.35 4.98863 131.381 6.80818C131.412 8.62773 130.726 10.3864 129.471 11.7039L92.6047 57.7863C91.9711 58.4688 91.2063 59.0164 90.3562 59.3966C89.5061 59.7767 88.5881 59.9816 87.657 59.9988C86.726 60.0161 85.801 59.8454 84.9374 59.4969C84.0738 59.1485 83.2893 58.6296 82.6309 57.971L58.2045 33.5353C57.524 32.9013 56.9782 32.1366 56.5996 31.287C56.221 30.4374 56.0175 29.5202 56.0011 28.5902C55.9847 27.6603 56.1557 26.7365 56.5041 25.8741C56.8524 25.0116 57.3709 24.2282 58.0286 23.5705C58.6863 22.9128 59.4698 22.3943 60.3322 22.0459C61.1947 21.6976 62.1184 21.5265 63.0484 21.5429C63.9784 21.5593 64.8955 21.7629 65.7452 22.1414C66.5948 22.52 67.3594 23.0658 67.9935 23.7463L87.3315 43.0751L119.404 2.21039C119.462 2.13886 119.523 2.07099 119.589 2.00722H119.58Z"
        fill="#0C0F25"
      />
    </svg>
  );
}
