import { useEffect } from 'react';
import { AiOutlineInfoCircle, AiOutlineStar } from 'react-icons/ai';
import { FiAlertTriangle } from 'react-icons/fi';
import { GoCircleSlash } from 'react-icons/go';
import { HiOutlineCheckCircle } from 'react-icons/hi';

import { AnimatePresence } from 'framer-motion';
import { useStore } from 'zstore';

import { MessageHolder, ToasterClose, ToasterContainer } from './style';

export function Toaster() {
  const {
    toastMessage,
    toastType,
    isToastVisible,
    isEnableToast,
    setToastHidden,
  } = useStore();
  const showTime = 3500;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setToastHidden();
    }, showTime);

    return () => clearTimeout(timeout);
  }, [isToastVisible]);

  const CalloutIcons = {
    success: <HiOutlineCheckCircle />,
    error: <GoCircleSlash />,
    alert: <FiAlertTriangle />,
    info: <AiOutlineInfoCircle />,
    disclaimer: <AiOutlineInfoCircle />,
    tip: <AiOutlineStar />,
  };

  return (
    <AnimatePresence>
      {isToastVisible && isEnableToast && (
        <ToasterContainer
          variant={toastType}
          showTime={showTime}
          initial={{ y: '100vh', x: '-50%', maxWidth: 310, opacity: 0 }}
          animate={{ y: '90vh', maxWidth: 320, opacity: 1 }}
          exit={{ y: '100vh', maxWidth: 310, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <MessageHolder>
            <span>{CalloutIcons[toastType || 'error']}</span>
            <p>{toastMessage}</p>
          </MessageHolder>
          <ToasterClose
            type="button"
            onClick={setToastHidden}
            aria-label="Close Toaster"
          >
            &times;
          </ToasterClose>
        </ToasterContainer>
      )}
    </AnimatePresence>
  );
}
