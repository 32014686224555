import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useNavigation } from 'contexts/NavigationContext';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';

export const ContinueSimulation = () => {
  const { goNextScreen } = useNavigation();
  const { loanuid } = useParams<{ loanuid: string }>();

  useEffect(() => {
    storage.setItem(storageKeys.loanUid, loanuid);

    goNextScreen();
  }, []);

  return null;
};
