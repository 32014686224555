import { PresentationVariants } from 'components/Presentation';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { colorsV2 } from 'styles/colors-v2';
import { device } from 'styles/medias';

interface PresentationStyledProps {
  variant: keyof typeof PresentationVariants;
}

export const Headline = styled.h2`
  font-size: 20px;
  font-weight: 400;
  line-height: 46px;

  @media ${device.lg} {
    font-size: 36px;
    line-height: 48px;
  }
`;

export const Subheading = styled.p`
  font-size: 14px;
  line-height: 28px;
  color: ${colors.text};

  @media ${device.lg} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PresentationStyled = styled.div<PresentationStyledProps>`
  ${({ variant }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${Headline} {
      color: ${colors[PresentationVariants[variant]?.headline]};
      ${variant === 'primary-v2' &&
      `
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;

        @media ${device.lg} {
          font-size: 28px;
        }

        letter-spacing: 0.5px;
        color: ${colorsV2['neutral-ultra-dark']};
      `}
    }

    ${Subheading} {
      color: ${colors[PresentationVariants[variant]?.subheading]};
      ${variant === 'primary-v2' &&
      `
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.16px;
      color: ${colorsV2['neutral-ultra-dark']};
      `}
    }
  `}
`;
