import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingPage } from 'components/LoadingPage';
import { UpdateDataBanner } from 'components/UpdateDataBanner';
import { useBorrower } from 'hooks/useBorrower';
import { SideBySideLayout } from 'styles/grids';

export function UpdatePhoneSuccess() {
  const history = useHistory();
  const { resetBorrowerData } = useBorrower();

  useEffect(() => {
    resetBorrowerData();

    const timeout = setTimeout(() => {
      history.push('/');
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <SideBySideLayout>
      <UpdateDataBanner />

      <LoadingPage
        title={
          <>
            Dados <b>atualizados</b>
          </>
        }
        text={
          <>
            Sucesso! você será redirecionado
            <br /> para o início
          </>
        }
      />
    </SideBySideLayout>
  );
}
