import { FaIdCard } from 'react-icons/fa';
import { FiHome, FiUser, FiHelpCircle, FiX } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import { BlackLogoNoverdePorDotz } from 'assets/img/BlackLogoNoverdePorDotz';
import { useAuth } from 'contexts/AuthContext';
import { MenuItemDashboard } from 'pages/Dashboard/components/MenuItemDashboard';
import { storageKeys } from 'store/storage-keys';
import { storage } from 'utils/storage';
import { useStore } from 'zstore';

import {
  MenuContainer,
  MenuContent,
  MenuItems,
  Background,
  Overlay,
} from './style';

export type SideMenuProfileProps = {
  openMenu: boolean;
  toggleSideMenu: () => void;
};

export const SideMenuDashboard = ({
  openMenu,
  toggleSideMenu,
}: SideMenuProfileProps) => {
  const { loansData } = useStore();
  const loanSelected = loansData?.ep?.list?.[0];
  const isLent = loanSelected?.status === 'lent';
  const { requestLogout } = useAuth();
  const history = useHistory();

  const goToWallet = () => {
    loanSelected?.uid &&
      storage.setItem(storageKeys.loanUid, loanSelected?.uid);

    history.push('/dashboard/wallet');
  };

  return (
    <Background open={openMenu}>
      <MenuContainer open={openMenu}>
        <MenuContent>
          <BlackLogoNoverdePorDotz width={152} />
          <MenuItems>
            <Link to="/dashboard">
              <MenuItemDashboard text="início" icon={<FiHome />} />
            </Link>
            <Link to="/dashboard/profile">
              <MenuItemDashboard text="meu perfil" icon={<FiUser />} />
            </Link>
            {isLent && (
              <MenuItemDashboard
                onClick={goToWallet}
                text="carteira"
                icon={<FaIdCard />}
              />
            )}

            <a
              href="https://noverde.com.br/duvidas"
              target="_blank"
              rel="noreferrer"
            >
              <MenuItemDashboard text="ajuda" icon={<FiHelpCircle />} />
            </a>
            <MenuItemDashboard
              text="sair"
              icon={<FiX />}
              onClick={requestLogout}
            />
          </MenuItems>
        </MenuContent>
      </MenuContainer>
      <Overlay open={openMenu} onClick={toggleSideMenu} />
    </Background>
  );
};
