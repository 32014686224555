import { addDays, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { toCurrencyBrl } from 'utils/masks';

import * as S from './style';

export type ModalViewAllInstallmentsData = {
  date: string;
  value: string;
  order: number;
};

export type ModalViewAllInstallmentsProps = {
  installments: any;
};

export function ModalViewAllInstallments({
  installments,
}: ModalViewAllInstallmentsProps) {
  const setOrder = (first, second) =>
    first.order < second.order ? -1 : first.order > second.order ? 1 : 0;

  const orderInstallments = installments.sort(setOrder);

  return (
    installments && (
      <S.Wrapper>
        <S.Header>
          <p>parcelas e vencimento</p>
          <p>valor</p>
        </S.Header>

        <S.BoxInstallments>
          {orderInstallments.map(
            (installment: ModalViewAllInstallmentsData, key: string) => (
              <S.InstallmentGroup key={installment.date}>
                <S.GroupOrderDate>
                  <S.Order>{key + 1}ª</S.Order>
                  <S.Date>
                    {format(
                      addDays(new Date(installment.date), 1),
                      "dd 'de' MMM 'de' yyyy",
                      {
                        locale: pt,
                      },
                    )}
                  </S.Date>
                </S.GroupOrderDate>
                <S.Value>
                  {toCurrencyBrl(Number(installment.value).toFixed(2) || 0)}
                </S.Value>
              </S.InstallmentGroup>
            ),
          )}
        </S.BoxInstallments>
      </S.Wrapper>
    )
  );
}
