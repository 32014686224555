/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState } from 'react';
import { FaCreditCard } from 'react-icons/fa';

import { MessageCallout } from 'components/MessageCallout';
import { useModal } from 'hooks/useModal';
import { useRouter } from 'hooks/useRouter';
import { EmptyWalletDashboard } from 'pages/Dashboard/components/EmptyWalletDashboard';
import { ScreenLoader } from 'pages/Dashboard/components/ScreenLoader';
import PaymtService from 'services/Paymt/PaymtService';

import { WalletCardDashboard } from '../WalletCardDashboard';

import { WalletsContainer, ModalExclusion, ContainerCallout } from './style';

export const WalletsDashboard = () => {
  const { history, location } = useRouter();
  const { closeModal, openModal } = useModal();
  const [callout, setCallout] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [creditCards, setCreditCards] = useState<any>([]);

  const getWallets = async () => {
    try {
      const { data } = await PaymtService.getCreditCards();

      setCreditCards(data);
    } catch {
      setCallout('Não foi possível buscar o seu cartão');
    } finally {
      setIsLoading(false);
    }
  };

  async function deleteWallet(creditCardId: string) {
    try {
      closeModal();

      setIsLoading(true);

      await PaymtService.deleteCreditCard(creditCardId);

      const { data } = await PaymtService.getCreditCards();

      setCreditCards(data);
    } catch (err: any) {
      if (err?.response?.status === 409) {
        setCallout(
          'Estamos processando seu pagamento, que pode demorar alguns minutos. Você poderá realizar esta ação assim que concluído.',
        );
      } else {
        setCallout('Não foi possível excluir o cartão');
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleDetails(cardId) {
    closeModal();

    const state = {
      origin: location.pathname,
    };

    history.push({
      pathname: `/dashboard/wallet-register/${cardId}`,
      state,
    });
  }

  function handleDelete(card) {
    openModal({
      title: '',
      content: (
        <ModalExclusion>
          <header>
            <h2>Tem certeza que deseja excluir o cartão?</h2>
          </header>
          <article>
            <FaCreditCard />
            <p>Cartão de crédito {card?.brand}</p>
            <span>{card?.display_number}</span>
          </article>
          <footer>
            <p className="delete" onClick={() => deleteWallet(card.id)}>
              Excluir
            </p>
            <p onClick={closeModal}>Cancelar</p>
          </footer>
        </ModalExclusion>
      ),
      size: 272,
    });
  }

  useEffect(() => {
    getWallets();
  }, []);

  return (
    <WalletsContainer>
      {isLoading ? (
        <ScreenLoader />
      ) : creditCards.length === 0 ? (
        <EmptyWalletDashboard />
      ) : (
        <>
          {creditCards.map((card) => (
            <WalletCardDashboard
              key={card?.id}
              title={`Cartão de crédito ${card?.brand}`}
              description={card?.display_number}
              handleDetails={() => handleDetails(card?.id)}
              handleDelete={() => handleDelete(card)}
            />
          ))}
        </>
      )}
      {callout && (
        <ContainerCallout>
          <MessageCallout
            title="Não foi possível excluir o cartão"
            message={callout}
            type="error"
          />
        </ContainerCallout>
      )}
    </WalletsContainer>
  );
};
