import styled from 'styled-components';
import { colorsV2 } from 'styles/colors-v2';

export const ContainerPaymentMethods = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const PaymentMethodsTitle = styled.p`
  color: ${colorsV2['neutral-dark']};
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const WrapperLoading = styled.div`
  display: flex;
  align-items: center;
`;
