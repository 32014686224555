import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface SideBannerContainerProps {
  bgImage: string;
}

export const SideBannerContainer = styled.div<SideBannerContainerProps>`
  ${({ bgImage }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    height: 100vh;
    min-height: 100%;
    width: 100%;
    padding: 0 30px;

    background-image: url(${bgImage});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
  `}
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 442px;
`;

export const Title = styled.h1`
  font-size: 86px;
  font-weight: 300;
  line-height: 81px;

  color: ${colors.white};
`;
