import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDBTracking } from 'contexts/DBTrackingContext';
import { useRouter } from 'hooks/useRouter';
import { useTracking } from 'hooks/useTracking';
import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';
import { storageKeys } from 'store/storage-keys';
import { RenegotiationTypes } from 'types/Renegotiation/Renegotiation';
import { isEmpty } from 'utils/helpers/is-empty';
import { toCurrency, toCurrencyBrl } from 'utils/masks';
import { storage } from 'utils/storage';

import {
  RenegotiationPanel,
  RenegotiationPanelProps,
} from './components/RenegotiationPanel';

type OfferTypes = 'settlement' | 'renegotiation' | 'regularization';

export const renegotiationLinks = {
  pagueAgora: '/dashboard/renegociacao/pague-agora',
  reparcele: '/dashboard/renegociacao/reparcele',
  regularize: '/dashboard/renegociacao/regularize',
};

export function PageRenegotiation() {
  const { history } = useRouter();
  const { location } = useHistory<RenegotiationTypes>();
  const { setTrackingEvent } = useDBTracking();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offer] = useState<RenegotiationTypes>(
    location.state || ({} as RenegotiationTypes),
  );

  const [settlementAndRenegotiationData, setSettlementAndRenegotiationData] =
    useState<RenegotiationPanelProps>({} as RenegotiationPanelProps);

  const [regularizationData, setRegularizationData] =
    useState<RenegotiationPanelProps>({} as RenegotiationPanelProps);

  const { trackEvent } = useTracking();
  const borrowerId = storage.getItem(storageKeys.borrowerId);

  useEffect(() => {
    document.title = 'Renegociacao - Lista';

    if (isEmpty(offer)) history.replace('/dashboard');
  }, []);

  useEffect(() => {
    if (offer?.offers) {
      setSettlementAndRenegotiationData(getRenegotiationOffer());

      setRegularizationData(getRegularizationOffer());
    }
  }, [offer]);

  const setEventTracking = (offerType: OfferTypes) => {
    trackEvent('select_item', {
      item_name: 'quero_essa',
      item_variant: offerType,
      borrower_id: borrowerId,
    });

    setTrackingEvent({
      category: 'negotiation',
      action: 'offer_selected',
      details: {
        type: offerType,
        offfer_id: offer.offers?.uuid,
      },
      loanUuid: true,
    });
  };

  const getRenegotiationOffer = () => {
    if (offer?.offers?.debt_amount) {
      const currentPanelData = {} as RenegotiationPanelProps;
      currentPanelData.headerOptions = {
        description: 'sua dívida atual é',
        value: toCurrencyBrl(offer?.offers?.debt_amount.toFixed(2) || 0),
        containerValueType: 'current_debt',
      };

      currentPanelData.panelItems = [];

      if (offer.offers?.settlement?.value) {
        currentPanelData.panelItems.push({
          title: 'Pague tudo agora!',
          titleTag: 'Maior desconto',
          description: (
            <>
              Você paga o valor total da sua dívida e recebe{' '}
              <strong>
                {offer?.offers?.settlement?.percent_discount.toFixed(2)}% de
                desconto.
              </strong>
            </>
          ),
          offers: [
            {
              description: `De ${toCurrencyBrl(
                offer?.offers?.debt_amount.toFixed(2) || 0,
              )} por: `,
              value: toCurrency(
                offer?.offers?.settlement?.value.toFixed(2) || 0,
              ),
              button: {
                onClick: () => {
                  setEventTracking('settlement');

                  history.push(renegotiationLinks.pagueAgora, {
                    ...offer,
                  });
                },
                text: 'quero essa',
              },
            },
          ],
        });
      }

      if (
        offer?.offers?.renegotiation &&
        offer?.offers?.renegotiation?.length > 0
      ) {
        currentPanelData.panelItems.push({
          title: 'Reparcele',
          titleTag: 'Mais flexibilidade',
          description:
            'Ganhe mais flexibilidade e escolha em quantas parcelas quer pagar!',
          offers: [
            {
              description: `Pague em até ${offer?.offers.renegotiation[0].period}x de`,
              value: `${toCurrency(
                offer?.offers.renegotiation[0].installment_value.toFixed(2),
              )}`,
              button: {
                onClick: () => {
                  setEventTracking('renegotiation');

                  history.push(renegotiationLinks.reparcele, {
                    ...offer,
                  });
                },
                text: 'quero essa',
              },
            },
          ],
        });
      }

      return currentPanelData;
    }
    return {} as RenegotiationPanelProps;
  };

  const getRegularizationOffer = () => {
    if (offer?.offers?.late_debt_amount) {
      const currentPanelData = {} as RenegotiationPanelProps;
      currentPanelData.headerOptions = {
        description: 'valor total em atraso',
        value: toCurrencyBrl(offer?.offers?.late_debt_amount.toFixed(2) || 0),
        containerValueType: 'late',
      };

      currentPanelData.panelItems = [];

      if (offer.offers?.regularization?.value) {
        currentPanelData.panelItems.push({
          title: 'Regularize',
          description:
            'Você paga todo o valor em atraso e fica em dia com a gente!',
          offers: [
            {
              description: 'Fique livre da multa',
              value: toCurrency(
                offer?.offers?.regularization?.value.toFixed(2) || 0,
              ),
              button: {
                onClick: () => {
                  setEventTracking('regularization');

                  history.push(renegotiationLinks.regularize, { ...offer });
                },
                text: 'quero essa',
              },
            },
          ],
        });
      }

      return currentPanelData;
    }
    return {} as RenegotiationPanelProps;
  };

  const expires_at = offer?.offers?.expires_at;
  const offerUuid = offer?.offers?.uuid;

  return (
    <DashBoardTemplate headerTitle="Renegociação" contentDirection="baseline">
      {settlementAndRenegotiationData?.panelItems?.length > 0 && (
        <RenegotiationPanel
          headerOptions={settlementAndRenegotiationData.headerOptions}
          panelItems={settlementAndRenegotiationData.panelItems}
          expires_at={expires_at}
          offerUuid={offerUuid}
        />
      )}

      {regularizationData?.panelItems?.length > 0 && (
        <RenegotiationPanel
          headerOptions={regularizationData.headerOptions}
          panelItems={regularizationData.panelItems}
          expires_at={expires_at}
          offerUuid={offerUuid}
        />
      )}
    </DashBoardTemplate>
  );
}
