import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonLoader } from 'components/ButtonLoader';
import { Presentation } from 'components/Presentation';
import { useModal } from 'hooks/useModal';
import { useRouter } from 'hooks/useRouter';
import PaymtService from 'services/Paymt/PaymtService';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { CheckoutTypes } from 'types/Checkout/CheckoutTypes';
import { isEmpty } from 'utils/helpers/is-empty';
import { useStore } from 'zstore';

import { Stack } from './style';

type ModalCreditCardConfirmationProps = {
  setErr: (string) => void;
};

export function ModalCreditCardConfirmation({
  setErr,
}: ModalCreditCardConfirmationProps) {
  const colors = getThemeColors();
  const { history } = useRouter();
  const { closeModal } = useModal();
  const { location } = useHistory<CheckoutTypes>();
  const { setCreditCardId } = useStore();
  const [loading, setLoading] = useState(false);

  const handlePayByCreditCard = async () => {
    try {
      setLoading(true);

      const { data } = await PaymtService.getCreditCards();

      const newState = {
        ...location.state,
        paymentType: 'creditcard',
      };

      if (isEmpty(data)) {
        history.push({
          pathname: '/dashboard/wallet-register',
          state: {
            origin: location.pathname,
            state: newState,
          },
        });

        return;
      }

      setCreditCardId(data[0].id);

      history.push({
        pathname: '/dashboard/checkout-summary',
        state: newState,
      });
    } catch {
      setErr('Não foi possível selecionar esse meio de pagamento');
    } finally {
      closeModal();

      setLoading(false);
    }
  };

  return (
    <Stack>
      <Presentation
        headline="Ao pagar com cartão de crédito, o valor é debitado no mesmo dia."
        subheading="A data que você escolheu anteriormente será anulada e o valor será descontado na hora do pagamento. Quer continuar com cartão de crédito?"
      />
      <Stack>
        <ButtonLoader isLoading={loading} onClick={handlePayByCreditCard}>
          Pagar com cartão de crédito
        </ButtonLoader>

        <ButtonLoader
          isLoading={false}
          className="secondary"
          color={colors.noverde}
          onClick={closeModal}
        >
          Escolher outra forma de pagamento
        </ButtonLoader>
      </Stack>
    </Stack>
  );
}
