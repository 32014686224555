import { InputHTMLAttributes } from 'react';

import { Input } from './style';

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement>;

const Checkbox = ({ ...props }: CheckboxProps) => (
  <Input type="checkbox" {...props} />
);

export default Checkbox;
