import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useRouter } from 'hooks/useRouter';
import { OfferPaymentTypes } from 'types/Renegotiation/Renegotiation';
import { isEmpty } from 'utils/helpers/is-empty';
import { toCurrencyBrl } from 'utils/masks';

import {
  RenegotiationPanel,
  RenegotiationPanelProps,
} from '../RenegotiationPanel';

export function RenegotiationPayment() {
  const [confirmation] = useState(true);
  const { location } = useHistory<OfferPaymentTypes>();
  const { history } = useRouter();

  const [offer] = useState<OfferPaymentTypes>(
    location.state || ({} as OfferPaymentTypes),
  );

  const [panelData] = useState({
    headerOptions: {},
    panelItems: [
      {
        title: 'Quase lá!',
        description:
          offer.type === 'renegotiation'
            ? 'Para concluir a contratação dessa oferta, você precisa efetuar o pagamento da primeira parcela!'
            : 'Para concluir a contratação dessa oferta, você precisa efetuar o pagamento!',
        offers: [
          {
            description: 'Valor',
            value: toCurrencyBrl(offer?.value ? offer?.value.toFixed(2) : ''),
          },
        ],
      },
    ],
  } as RenegotiationPanelProps);

  useEffect(() => {
    document.title = 'Renegociacao - Pagamento';

    if (isEmpty(offer)) history.replace('/dashboard');
  }, []);

  return (
    <RenegotiationPanel
      headerOptions={panelData.headerOptions}
      panelItems={panelData.panelItems}
      confirmation={confirmation}
      hasPaymentMethods={confirmation}
      actionsWith={{
        buttonWithInfo: {
          text: '',
        },
      }}
      expires_at={offer.expires_at}
      offerUuid={offer.uuid}
      hidePanel
      offer={offer}
    />
  );
}
