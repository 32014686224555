import { createContext, useState } from 'react';

interface MockContextProps {
  fillForm?: boolean;
  setFillForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MockContext = createContext<MockContextProps>({
  setFillForm: () => null,
});

interface MockProviderProps {
  children?: React.ReactNode;
}

export const MockProvider = ({ children }: MockProviderProps) => {
  const [fillForm, setFillForm] = useState(false);

  const value = {
    fillForm,
    setFillForm,
  };

  return <MockContext.Provider value={value}>{children}</MockContext.Provider>;
};

MockProvider.defaultProps = {
  children: null,
};
