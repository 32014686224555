import { DashBoardTemplate } from 'pages/Dashboard/templates/Dashboard';

import { RenegotiationRegularize } from './components/RenegotiationRegularize';

export function PageRenegotiationRegularize() {
  return (
    <DashBoardTemplate headerTitle="Renegociação" contentDirection="baseline">
      <RenegotiationRegularize />
    </DashBoardTemplate>
  );
}
