import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useDBTracking } from 'contexts/DBTrackingContext';
import { useModal } from 'hooks/useModal';
import { useTracking } from 'hooks/useTracking';
import RenegotiationService from 'services/Offers/Renegotiation';
import { storageKeys } from 'store/storage-keys';
import {
  RenegotiationInternalTypes,
  RenegotiationTypes,
} from 'types/Renegotiation/Renegotiation';
import { isEmpty } from 'utils/helpers/is-empty';
import { toCurrency, toCurrencyBrl } from 'utils/masks';
import { storage } from 'utils/storage';

import {
  RenegotiationPanel,
  RenegotiationPanelProps,
} from '../RenegotiationPanel';
import { OfferItemType } from '../RenegotiationPanel/OfferItem';

export function RenegotiationRepay() {
  const { closeModal } = useModal();
  const history = useHistory();

  const [confirmation] = useState(false);

  const { location } = useHistory<RenegotiationTypes>();
  const [offer] = useState<RenegotiationTypes>(
    location.state || ({} as RenegotiationInternalTypes),
  );

  const [panelData, setPanelData] = useState<RenegotiationPanelProps>(
    {} as RenegotiationPanelProps,
  );

  const [selectedOffer, setSelectedOffer] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const { setTrackingEvent } = useDBTracking();
  const { trackEvent } = useTracking();
  const borrowerId = storage.getItem(storageKeys.borrowerId);

  useEffect(() => {
    document.title = 'Renegociacao - Detalhe';

    if (isEmpty(offer)) history.replace('/dashboard');
  }, []);

  useEffect(() => {
    if (
      offer?.offers?.renegotiation &&
      offer?.offers?.renegotiation.length > 0
    ) {
      const currentData: RenegotiationPanelProps = {
        headerOptions: {
          description: 'sua dívida atual é',
          value: toCurrencyBrl(offer?.offers?.debt_amount.toFixed(2) || 0),
          containerValueType: 'current_debt',
        },
        panelItems: [
          {
            title: 'Reparcele',
            description:
              'Ganhe mais flexibilidade e escolha em quantas parcelas quer pagar!',
            offers: getCurrentOffer(offer?.offers?.renegotiation[0] || []),
          },
        ],
      };

      setPanelData(currentData);
    }
  }, [offer]);

  const getInstallments = (currentInstallments) =>
    currentInstallments.map((installment) => ({
      date: installment.due_date,
      value: installment.value,
      order: installment.number,
    }));

  const getCurrentOffer = (currOffer = {} as RenegotiationInternalTypes) => [
    {
      description: `Pague em até ${currOffer.period}x`,
      value: toCurrency(currOffer.installment_value.toFixed(2)),
    },
  ];

  const getMoreOffers = () => {
    if (offer?.offers?.renegotiation) {
      return offer?.offers?.renegotiation?.map((_offer, index) => ({
        description: 'Parcelas',
        value: `${toCurrency(_offer.installment_value.toFixed(2))}`,
        period: _offer.period,
        button: {
          onClick: () => null,
          text: 'quero essa',
        },
        originalValue: _offer.value.toFixed(2),
        modalData: getInstallments(_offer.installments),
        selectedOffer: index,
      }));
    }

    return [];
  };

  const confirmOffer = async () => {
    if (
      offer?.offers?.renegotiation &&
      offer?.offers?.renegotiation.length > 0
    ) {
      const currentOffer = offer?.offers?.renegotiation[selectedOffer];

      setIsLoading(true);

      try {
        trackEvent('select_item', {
          item_name: 'quero_essa',
          borrower_id: borrowerId,
        });

        setTrackingEvent({
          category: 'negotiation',
          action: 'offer_accepted',
          details: {
            type: 'renegotiation',
            offfer_id: offer.offers?.uuid,
          },
          loanUuid: true,
        });

        const { data } = await RenegotiationService.AcceptOffer({
          type: 'renegotiation',
          period: currentOffer.period,
          debt_amount: offer.offers.debt_amount || 0,
          uuidOffer: offer.offers.uuid,
          //  agreement_uuid: agreementUuid,
        });
        history.push('/dashboard/renegociacao/pagamento', {
          uuid: data.uuid,
          type: 'renegotiation',
          value: currentOffer.installment_value,
          expires_at: offer.offers.expires_at,
        });
      } catch (err) {
        setIsLoading(false);
      }
    }
  };

  const changeOffer = (
    _offer: Pick<
      OfferItemType,
      'value' | 'modalData' | 'originalValue' | 'selectedOffer' | 'period'
    >,
  ) => {
    setPanelData({
      ...panelData,
      panelItems: [
        {
          title: 'Reparcele',
          description:
            'Ganhe mais flexibilidade e escolha em quantas parcelas quer pagar!',
          offers: [
            {
              description: `Pague em até ${_offer.period}x`,
              value: String(_offer.value),
              period: _offer.period,
            },
          ],
        },
      ],
    });

    setSelectedOffer(Number(_offer.selectedOffer));
    closeModal();
  };

  return (
    <RenegotiationPanel
      headerOptions={panelData.headerOptions}
      panelItems={panelData.panelItems}
      confirmation={confirmation}
      confirmOrder={confirmOffer}
      hasPaymentMethods={confirmation}
      actionsWith={{
        terms: true,
      }}
      moreOffers={getMoreOffers().map((_offer) => ({
        description: _offer.description,
        value: _offer.value,
        button: {
          text: _offer.button.text,
          onClick: () =>
            changeOffer({
              value: _offer.value,
              modalData: _offer.modalData,
              originalValue: Number(_offer.originalValue),
              selectedOffer: _offer.selectedOffer,
              period: _offer.period,
            }),
        },
        modalData: _offer.modalData,
        originalValue: _offer.originalValue,
        period: _offer.period,
      }))}
      expires_at={offer?.offers?.expires_at}
      offerUuid={offer?.offers?.uuid}
      selectedOffer={selectedOffer}
      isLoading={isLoading}
    />
  );
}
