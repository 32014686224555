import styled from 'styled-components';
import { device } from 'styles/medias';

export const Box = styled.div`
  display: grid;
  border-radius: 12px;
  padding: 2rem;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  @media ${device.sm} {
    box-shadow: 0 0 0.5rem #e8e8e8;
    margin: 2rem 0;
    max-width: 65rem;
  }
`;

export const InputGrid = styled.div`
  align-items: baseline;
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  @media ${device.sm} {
    grid-auto-flow: column;
  }
`;

export const InputGridItem = styled.div`
  display: grid;
  row-gap: 1rem;
`;

export const InputGridSubItem = styled.div`
  display: grid;
  row-gap: 1rem;
`;
