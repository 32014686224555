import { Typography } from 'components/Typography';
import { useBorrower } from 'hooks/useBorrower';
import { ContentLoading } from 'pages/Dashboard/components/ContentLoading';
import { toFormatedPhone } from 'utils/formatters/to-formated-phone';

import { ProfileLineGroupDashboard } from '../ProfileLineGroupDashboard';

import { Container, Content } from './style';

export const ProfileDashboard = () => {
  const { borrowerData } = useBorrower();

  const data = [
    {
      title: 'Nome',
      value: borrowerData.data?.attributes?.name || '',
    },
    {
      title: 'E-mail',
      value: borrowerData.data?.attributes?.email || '',
    },
    {
      title: 'Telefone',
      value: toFormatedPhone(
        borrowerData.data?.attributes?.['mobile-number'] || '',
      ),
    },
  ];

  return (
    <Container>
      {!borrowerData ? (
        <ContentLoading />
      ) : (
        <Content>
          {data.map((item) => (
            <ProfileLineGroupDashboard
              key={item.title}
              title={item.title}
              value={item.value}
            />
          ))}

          <Typography
            _style={{
              default: 'body',
              xs: 'body-desktop',
            }}
            as="p"
          >
            Caso queira exercer os direitos relacionados aos seus dados
            pessoais, realize uma solicitação clicando{' '}
            <a
              target="_blank"
              href="https://titulares.becompliance.com/webforms/742514d2-b32f-4800-b1cc-749946321d55/ece0d862-696f-43c9-abef-d954be718fc1"
              rel="noreferrer"
            >
              <Typography
                _style={{
                  default: 'body',
                  xs: 'body-desktop',
                  color: 'brand-primary',
                }}
                as="span"
              >
                aqui.
              </Typography>
            </a>
          </Typography>
        </Content>
      )}
    </Container>
  );
};
