import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { TrackingUtmType } from 'types/Tracker/TrackingType';

class TrackEventsService extends HttpClient {
  public constructor() {
    super(Services.Tracker);
  }

  public setEvent = (body: TrackingUtmType) => {
    const requestBody = {
      category: body.category,
      action: body.action,
      device_id: body.device_id,
      loan_uuid: body.loan_uuid,
      details: body.details,
    };

    return this.instance.post('/event', requestBody);
  };
}

export default new TrackEventsService();
