import { UpdateDataBanner } from 'components/UpdateDataBanner';
import { UpdatePhoneForm } from 'pages/UpdatePhone/UpdatePhoneForm';
import { SideBySideLayout } from 'styles/grids';

export function UpdatePhone() {
  return (
    <SideBySideLayout>
      <UpdateDataBanner />

      <UpdatePhoneForm />
    </SideBySideLayout>
  );
}
