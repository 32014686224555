import { HttpClient } from 'services/http-client';
import { Services } from 'services/services';
import { storageKeys } from 'store/storage-keys';
import { AttachmentsType } from 'types/Loans/DocumentsType';
import { storage } from 'utils/storage';

class SimulationService extends HttpClient {
  public constructor() {
    super(Services.PlatformLoans);
  }

  public sendAttachments = (body: AttachmentsType) => {
    const requestBody = {
      document_type: body.type,
      mime_type: body.mimeType,
      file_name: body.fileName,
      data: body.data,
    };

    return this.instance.post(
      `${storage.getItem(storageKeys.loanUid)}/attachments`,
      requestBody,
    );
  };

  public sendDocumentInfos = (body) => {
    const requestBody = {
      document_infos: body,
    };

    return this.instance.patch(
      `/${storage.getItem(storageKeys.loanUid)}`,
      requestBody,
    );
  };

  public getRequirements = () => {
    return this.instance.get(
      `${storage.getItem(storageKeys.loanUid)}/requirements`,
    );
  };

  public getRequirementsByLoanUid = (uid: string) => {
    return this.instance.get(`${uid}/requirements`);
  };
}

export default new SimulationService();
