import styled from 'styled-components';
import { colors } from 'styles/colors';

export const MessageWithButtonContainer = styled.div`
  margin: 0 auto;
  height: 100vh;
  width: 100%;
  max-width: 535px;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > div {
    margin-bottom: 40px;

    > p {
      margin: 16px 0;

      > b {
        color: ${colors.galaxy};
      }
    }
  }

  > button {
    margin-bottom: 16px;
  }
`;

export const Title = styled.p`
  font-size: 24px;
  color: ${colors.galaxy};
`;
