import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';

const colors = getThemeColors();

export const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 15px 20px;
  width: 100%;
  min-height: 52px;
  text-transform: lowercase;
  display: block;
  font-weight: 700;
  letter-spacing: 0.16px;
  border: none;
  border-radius: 4px;
  transition: 0.3s all;

  &::first-letter {
    text-transform: uppercase;
  }

  > a {
    width: 100%;

    text-transform: lowercase;
    display: inline-block;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  > span {
    margin: 0 auto;
  }

  &.secondary {
    ${({ color }) => css`
      color: ${color || colors.noverde};
      background-color: transparent;

      &:hover {
        background-color: ${transparentize(
          0.95,
          String(color || colors.noverde),
        )};
      }

      &:active {
        background-color: ${transparentize(
          0.85,
          String(color || colors.noverde),
        )};
      }

      &:disabled {
        color: ${transparentize(0.35, String(color || colors.noverde))};
        background-color: ${transparentize(
          0.95,
          String(color || colors.noverde),
        )};
        pointer-events: none;
        cursor: initial;
      }
    `}
  }

  &.tertiary {
    ${({ color }) => css`
      background-color: #fff;
      border: 1px solid ${color || colors.noverde};
      color: ${color || colors.noverde};

      &:hover {
        background-color: ${transparentize(
          0.95,
          String(color || colors.noverde),
        )};
      }

      &:active {
        background-color: ${transparentize(
          0.85,
          String(color || colors.noverde),
        )};
      }

      &:disabled {
        color: ${transparentize(0.35, String(color || colors.noverde))};
        background-color: ${transparentize(
          0.95,
          String(color || colors.noverde),
        )};
        pointer-events: none;
        cursor: initial;
      }
    `}
  }

  &.tertiary-v2 {
    ${({ color }) => css`
      background-color: #fff;
      color: ${colors.noverde};

      &:hover {
        background-color: ${colors.white};
        color: ${colors['brand-secondary']};
      }

      &:active {
        background-color: ${transparentize(
          0.85,
          String(color || colors.noverde),
        )};
      }

      &:disabled {
        color: ${transparentize(0.35, String(color || colors.noverde))};
        background-color: ${transparentize(
          0.95,
          String(color || colors.noverde),
        )};
        pointer-events: none;
        cursor: initial;
      }
    `}
  }

  &.rounded {
    border-radius: 40px;
    min-height: 37px;
    height: 37px;
  }

  &.documents {
    color: ${colors.text};
    background-color: transparent;
    font-weight: 500;
    border: 1px solid ${colors.greyborders};
    text-transform: none;
    padding: 10px;
    line-height: 16px;

    &.active {
      background-color: transparent;
      color: ${colors.galaxy};
      border: 1px solid ${colors.galaxy};
      text-shadow: 0 0 1px ${colors.galaxy};
    }

    &.ccb {
      font-size: 18px;
      max-width: 180px;
    }

    &:hover {
      background-color: transparent;
      border: 1px solid ${colors.text};
    }

    &:disabled {
      background-color: ${transparentize(0.75, colors.galaxy)};
      pointer-events: none;
      cursor: initial;
    }
  }

  &.primary-v2 {
    &:disabled {
      background-color: ${colors['action-navigation-disabled']};
      pointer-events: none;
      cursor: initial;
    }
  }

  ${({ color }) => css`
    &:hover {
      background-color: ${darken(0.025, String(color || colors.noverde))};
    }

    &:active {
      background-color: ${darken(0.055, String(color || colors.noverde))};
    }

    &:disabled {
      background-color: ${transparentize(
        0.75,
        String(color || colors.noverde),
      )};
      pointer-events: none;
      cursor: initial;
    }
  `}

  ${({ color }) => css`
    color: ${colors.white};
    background-color: ${color || colors.noverde};

    &:hover {
      background-color: ${darken(0.025, String(color || colors.noverde))};
    }

    &:active {
      background-color: ${darken(0.055, String(color || colors.noverde))};
    }

    &:disabled {
      background-color: ${transparentize(
        0.75,
        String(color || colors.noverde),
      )};
      pointer-events: none;
      cursor: initial;
    }
  `}
`;
