import { ReactNode } from 'react';

import { Button } from 'components/Button';

import * as S from './style';

interface MessageWithButtonProps {
  title: string | ReactNode;
  text: string | ReactNode;
  primaryButton: {
    action: () => void;
    label: string;
  };
  secondaryButton?: {
    action: () => void;
    label: string;
  };
}

export function MessageWithButton({
  title,
  text,
  primaryButton,
  secondaryButton,
}: MessageWithButtonProps) {
  return (
    <S.MessageWithButtonContainer>
      <div>
        <S.Title>{title}</S.Title>
        <p>{text}</p>
      </div>
      <Button onClick={primaryButton.action}>{primaryButton.label}</Button>
      {secondaryButton && (
        <Button onClick={secondaryButton.action} variant="secondary">
          {secondaryButton.label}
        </Button>
      )}
    </S.MessageWithButtonContainer>
  );
}
