import styled from 'styled-components';
import { getThemeColors } from 'styles/helpers/getThemeColors';
import { device } from 'styles/medias';

const colors = getThemeColors();

export const PasswordFormContainer = styled.div`
  width: 100%;
`;

export const FormHolder = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  form {
    flex: 1;

    > div {
      margin-top: 13px;

      @media ${device.lg} {
        margin-top: 29px;
      }
    }

    > div:first-child {
      margin-top: 30px;

      @media ${device.lg} {
        margin-top: 40px;
      }
    }
  }

  button {
    margin-bottom: 16px;
  }

  @media ${device.lg} {
    min-height: unset;

    button {
      margin-bottom: 0;
    }
  }
`;

export const TermsText = styled.p`
  position: absolute;
  bottom: calc(87px + 40px);
  padding: 0 16px;
  left: 0;
  right: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.32px;
  color: ${colors.galaxy};

  span {
    color: ${colors.noverde};
    text-decoration: underline;
    cursor: pointer;
  }

  @media ${device.sm} {
    left: calc(50vw - (584px / 2));
  }
`;
