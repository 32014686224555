import { useEffect, useState } from 'react';

import PaymtService from 'services/Paymt/PaymtService';

declare global {
  interface Window {
    Iugu: any;
  }

  interface Document {
    getDocumentById: any;
  }
}

const Iugu = {
  isLoaded() {
    return !!window.Iugu;
  },
  impl() {
    if (!window.Iugu) {
      throw new Error('Iugu script has not been loaded yet.');
    }

    return window.Iugu;
  },
  setAccountToken(token) {
    this.impl().setAccountID(token);
  },
  setTestMode(mode) {
    this.impl().setTestMode(mode);
  },
  createPaymentToken(form, throwOnError = false) {
    return new Promise((resolve, reject) => {
      this.impl().createPaymentToken(form, (response) => {
        if (response.errors && throwOnError) {
          return reject(response);
        }

        resolve(response);

        return true;
      });
    });
  },
  createCreditCard(data: {
    cardNumber: string;
    expirationMonth: string;
    expirationYear: string;
    firstName: string;
    lastName: string;
    verificationValue: string;
  }) {
    return this.impl().CreditCard(
      data.cardNumber,
      data.expirationMonth,
      data.expirationYear,
      data.firstName,
      data.lastName,
      data.verificationValue,
    );
  },
};

export default function useIugu(viewMode) {
  const [iuguStatus, setIuguStatus] = useState({
    loading: false,
    error: false,
    success: false,
  });

  const initialConfig = async () => {
    try {
      setIuguStatus({ loading: true, error: false, success: false });

      if (!viewMode) {
        const { data } = await PaymtService.getAccountId();

        if (document.getElementById('iugu-script')) {
          Iugu.setAccountToken(data.account_id);
        } else {
          const script = document.createElement('script');
          script.src = 'https://js.iugu.com/v2';
          script.id = 'iugu-script';

          script.onload = () => {
            Iugu.setAccountToken(data.account_id);

            Iugu.setTestMode(process.env.REACT_APP_IUGU_MODE === 'sandbox');
          };

          document.head.appendChild(script);
        }
      }

      setIuguStatus({ loading: false, error: false, success: true });
    } catch {
      setIuguStatus({ loading: false, error: true, success: false });
    }
  };

  useEffect(() => {
    initialConfig();

    () => document.getDocumentById('iugu-script').remove();
  }, []);

  return { Iugu, iuguStatus };
}
