import { setState, StoreSlice } from 'zstore';

interface ISetToast {
  toastMessage?: string;
  toastType?: 'success' | 'error' | 'alert' | 'info' | 'disclaimer' | 'tip';
}

export interface IToastSlice extends ISetToast {
  isToastVisible: boolean;
  isEnableToast: boolean;
  setToast: (data?: ISetToast) => void;
  setToastHidden: () => void;
  setToggleEnableToast: () => void;
}

export const toastSlice: StoreSlice<IToastSlice> = () => ({
  isEnableToast: true,
  isToastVisible: false,
  toastMessage: 'Default toast message!',
  toastType: 'error',
  setToggleEnableToast: () => {
    setState((state: IToastSlice) => {
      state.isEnableToast = !state.isEnableToast;
    });
  },
  setToast: (data?: ISetToast) => {
    setState((state: IToastSlice) => {
      state.isToastVisible = true;
      state.toastMessage = data?.toastMessage || state.toastMessage;
      state.toastType = data?.toastType || state.toastType;
    });
  },
  setToastHidden: () => {
    setState((state: IToastSlice) => {
      state.isToastVisible = false;
    });
  },
});
